import React, { Fragment } from 'react'
import EmployeeHiring from './EmployeeHiring'

const EmployeeHiringPage = () => {
  return (
    <Fragment>
        <EmployeeHiring/>
    </Fragment>
  )
}
export default EmployeeHiringPage
