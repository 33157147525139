import React from "react";
import abimg1 from '../../images/OurMission.png'
import abimg2 from '../../images/OurVision.png'
import abimg3 from '../../images/CoreValue.png'


const MissionVission = () => {
    return (
        <section className="mission-vision-section section-padding pb-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-xl-8 col-lg-10">
                        <div className="title-area">
                            <h2>Transform Yourself From <b>"VYAPARI TO CEO"</b></h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="mission-vision-grids clearfix">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={abimg1} alt="" />
                                </div>
                                <h3>Our mission</h3>
                                <p>Our mission is clear and transformative: Transforming every "Vyapari to CEO" through vision, knowledge, strategy, and innovation.</p>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={abimg2} alt="" />
                                </div>
                                <h3>Our vision</h3>
                                <p>Our vision is to make India the "Sone Ki Chidiya" again—flourishing, innovative, globally admired, and with an unstoppable entrepreneurial spirit.</p>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={abimg3} alt="" />
                                </div>
                                <h3>Our core values</h3>
                                <p>Our core values are integrity, Innovation, Empowerment, a Growth-focused mindset, and relentless commitment to transforming Vyapari 2 CEO.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MissionVission;