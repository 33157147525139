import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import BlogList from "../../components/BlogList/BlogList.js";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/logo.png";
import { Helmet } from "react-helmet";
const BlogPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Blogs</title>
      </Helmet>
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      <PageTitle pageTitle={"Blogs"} pagesub={"Blog"} />
      <BlogList />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPage;
