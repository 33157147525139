import React from 'react'
import abimg from '../../images/about.jpg'


const About = (props) => {
    return (
        <section className="about-section-s2 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 order-lg-1 order-2 col-12">
                        <div className="img-holder">
                            <img src={abimg} alt="" />
                            <div className="experience-text">
                                15+ Years of <span>Experience</span>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-6 order-lg-2 order-1 col-12">
                        <div className="section-title-s2">
                            <span>Who am I?</span>
                            <h2>On a Mission to Transform <br />Vyapari's to CEO's</h2>
                        </div>
                        <div className="about-content">
                            <h4>Hi, I’m CA Rahul Malodia, Business Coach on a big mission—to help India’s small businessmen grow from being Vyapari's to CEO's.</h4>
                            <p>I started just like everyone else, working a regular job as a management consultant for top companies like Voltas and Raymond. But I noticed something - big companies had experts and resources, while small businesses struggled to survive. It didn’t feel fair.
                            So, I quit my high-paying job and taught small business owners how to succeed. My first batch had only 30 students. Today, I’ve trained over 3,00,000 businessmen, hosted 1,000+ webinars, and won awards.</p>
                            <p>Through my courses, like Vyapari to CEO, I want to make India again <b>“Sone ki Chidiya.”</b></p>
                            <a class="theme-btn-s2" href="/about">Know More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;