import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import MissionVission from "../../components/MissionVission/MissionVission";
import Skill from "../../components/Skill/Skill";
import Skill2 from "../../components/Skill/Skill2";
import Skill3 from "../../components/Skill/Skill3";
import PartnerSectionS2 from "../../components/PartnerSection2/PartnerSectionS2";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";


const AboutPage = () => {
  const schemaData = {
    "@context": "http://schema.org/",
    "@type": "Person",
    name: "Rahul Malodia",
    alternateName: "Rahul Malodia",
    familyName: "Malodia",
    email: "ca.rahul.malodia@gmail.com",
    birthDate: "1989-24-03",
    brand: "Malodia Business Coaching",
    jobTitle: "CEO",
    knowsAbout: "Business Coach or Consultant",
    nationality: "Indian",
    worksFor: "Malodia Business Coaching",
    sameAs: [
      "https://www.facebook.com/RahulMalodiaOfficial/",
      "https://www.youtube.com/@rahulmalodiaofficial",
      "https://www.instagram.com/rahulmalodiaofficial/",
      "https://www.linkedin.com/in/rahul-malodia-5ab2138a/",
      "https://x.com/carahulmalodia",
      "https://www.youtube.com/@TheRahulMalodiaPodcast",
      "https://t.me/CArahulmalodiaofficial",
    ],
  };

  return (
    <Fragment>
      <Helmet>
        <title>About Me</title>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      <PageTitle pageTitle={"About Me"} pagesub={"About"} />
      <Skill />
      <MissionVission />
      <Skill2 />
      <Skill3 />
      <PartnerSectionS2 />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
