import React from 'react';
import { Link } from 'react-router-dom'
import blogsMain from '../../api/blogs-main.js';
import { useParams } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar/BlogSidebar.js'
import pimg1 from '../../images/social-icon-img/Facebook.png'
import pimg2 from '../../images/social-icon-img/Instagram.png'
import pimg3 from '../../images/social-icon-img/Linkdin.png'
import pimg4 from '../../images/social-icon-img/Telegram.png'
import pimg6 from '../../images/social-icon-img/x.png'


const BlogSingle = (props) => {

    const { slug } = useParams()

    const BlogDetails = blogsMain.find(item => item.slug === slug)

    const submitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <section className="blog-single-section section-padding pt-0">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-9 col-12 ${props.blRight}`}>
                        <div className="blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={BlogDetails.blogSingleImg} alt="" />
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="">{BlogDetails.author}</Link></li>
                                    <li><Link to="">{BlogDetails.create_at}</Link></li>
                                  
                                </ul>
                                <h2>{BlogDetails.title}</h2>
                                <p>{BlogDetails.description}</p>
                                <blockquote>
                                    A collection of textile samples lay spread out on the table Samsa was a travelling salesman and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur
                                    <span className="quoter">- Jhone dow</span>
                                </blockquote> 
                                <h3>Whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather</h3>
                                <p>Transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked.</p>
                            </div>

                            <div className="tag-share">
                                <div className="tag">
                                    Tags: &nbsp;
                                    <ul>
                                        <li><Link to="">Business</Link></li>
                                        <li><Link to="">Consutling</Link></li>
                                        <li><Link to="">Maket research</Link></li>
                                        <li><Link to="">Marketing</Link></li>
                                    </ul>
                                </div>
                                <div className="d-flex mt-4 gap-3">
                                        <span>Share:</span> 
                                    <div class="social-icons d-block">
                                        <ul class="mb-0 gap-4">
                                           
                                            <li>
                                                <a target="_blank" href="https://www.facebook.com">
                                                <img src={pimg1} />
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="https://www.instagram.com">
                                                <img src={pimg2} />
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="https://www.linkedin.com">
                                                <img src={pimg3} />
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="https://www.telegram.com">
                                                <img src={pimg4} />
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="https://www.x.com">
                                                <img src={pimg6} />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                   
                                </div>
                            </div>
                            {/* <div className="author-box">
                                <div className="author-avatar">
                                    <Link to="" target="_blank"><img src={blog6} alt="" /></Link>
                                </div>
                                <div className="author-content">
                                    <Link to="" className="author-name">{BlogDetails.author}</Link>
                                    <p>CA Rahul Malodia is the best business coach in India who has trained over 300000+ businessmen and transformed them from <b>“VYAPARI to CEO”</b>, working on a mission to make India again <b></b><b>“Sone ki Chidiya”</b>.</p>
                                    <div className="socials">
                                        <ul className="social-link">
                                            <li><Link to=""><i className="ti-facebook"></i></Link></li>
                                            <li><Link to=""><i className="ti-twitter-alt"></i></Link></li>
                                            <li><Link to=""><i className="ti-linkedin"></i></Link></li>
                                            <li><Link to=""><i className="ti-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            <div className="more-posts mt-3">
                                <div className="previous-post">
                                    <Link to="">
                                        <span className="post-control-link"><i className="ti-arrow-circle-left"> </i>Previous post</span>
                                    </Link>
                                </div>
                                <div className="next-post">
                                    <Link to="">
                                        <span className="post-control-link">Next post <i className="ti-arrow-circle-right"></i></span>
                                    </Link>
                                </div>
                            </div>

                            {/* <div className="comments-area">
                                <div className="comments-section">
                                    <h3 className="comments-title">Comments</h3>
                                    <ol className="comments">
                                        <li className="comment even thread-even depth-1" id="comment-1">
                                            <div id="div-comment-1">
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt="" /></div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>John Abraham <span className="comments-date">January 12,2022
                                                                At 9.00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>I will give you a complete account of the system, and
                                                                expound the actual teachings of the great explorer of
                                                                the truth, </p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link" to=""><span>Reply</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="children">
                                                <li className="comment">
                                                    <div>
                                                        <div className="comment-theme">
                                                            <div className="comment-image"><img src={blog4} alt="" /></div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                    <h4>Lily Watson <span className="comments-date">January
                                                                        12,2022 At 9.00am</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>I will give you a complete account of the system,
                                                                        and expound the actual teachings of the great
                                                                        explorer of the truth, </p>
                                                                    <div className="comments-reply">
                                                                        <Link className="comment-reply-link" to=""><span>Reply</span></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li className="comment">
                                                            <div>
                                                                <div className="comment-theme">
                                                                    <div className="comment-image"><img src={blog5} alt="" /></div>
                                                                </div>
                                                                <div className="comment-main-area">
                                                                    <div className="comment-wrapper">
                                                                        <div className="comments-meta">
                                                                            <h4>John Abraham <span className="comments-date">January
                                                                                12,2022 At 9.00am</span></h4>
                                                                        </div>
                                                                        <div className="comment-area">
                                                                            <p>I will give you a complete account of the
                                                                                system, and expound the actual teachings
                                                                                of the great explorer of the truth, </p>
                                                                            <div className="comments-reply">
                                                                                <Link className="comment-reply-link" to=""><span>Reply</span></Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="comment">
                                            <div>
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt="" /></div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>John Abraham <span className="comments-date">January 12,2022
                                                                At 9.00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>I will give you a complete account of the system, and
                                                                expound the actual teachings of the great explorer of
                                                                the truth, </p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link" to=""><span>Reply</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                                <div className="comment-respond">
                                    <h3 className="comment-reply-title">Post Comments</h3>
                                    <form onSubmit={submitHandler} id="commentform" className="comment-form">
                                        <div className="form-textarea">
                                            <textarea id="comment" placeholder="Write Your Comments..."></textarea>
                                        </div>
                                        <div className="form-inputs">
                                            <input placeholder="Website" type="url" />
                                            <input placeholder="Name" type="text" />
                                            <input placeholder="Email" type="email" />
                                        </div>
                                        <div className="form-submit">
                                            <input id="submit" value="Post Comment" type="submit" />
                                        </div>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft} />
                </div>
            </div>
        </section>
    )

}

export default BlogSingle;
