/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";
import img from "../../images/thankyou.png";
import img2 from "../../images/check.png";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GoogleAdThank } from "../../components/common";
const SubmitHandler = (e) => {
  e.preventDefault();
};

const Thankyou = () => {
  console.log('log1')
  const location = useLocation();
  console.log('location: ', location);
  const queryParams = new URLSearchParams(location.search);  // Extract query parameters
  console.log('queryParams: ', queryParams);
  
  // Get the value of the "name" parameter
  const name = (queryParams.get('name')).split('?')[0];
  console.log('name: ', name);
  return (
    <Fragment>
       <Helmet>
        <title>Thank You</title>
        <script>
          {GoogleAdThank}
        </script>
      </Helmet>
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      <section className="thankyou">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <img src={img} className="" />
            </div>
            <div className="col-12 col-md-6">
                <div className="success-card" >
              <img src={img2} className="check-img" />
                <h2>Payment Successful!</h2>
                <p>Thank you for your purchase. Your transaction has been completed, and a confirmation email has been sent to you.</p>
                <a href="/courses" className="">Okay</a> 
                </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default Thankyou;
