import React from "react";
// import ContactForm from '../ContactFrom/ContactForm'
// import cimg1 from '../../images/icon/Call.png'
// import cimg2 from '../../images/icon/Email.png'
// import cimg3 from '../../images/icon/Location.png'
import blogs from "../../api/blogs";

const EventPage = () => {
  return (
    <section className="blog-section" style={{ padding: "60px 0" }}>
      <div className="container">
        <div className="row mb-3">
          <div className="col col-lg-6 col-12">
            <div className="section-title-s2 ">
              <span>Events</span>
              <h2>Upcoming & Past Events</h2>
            </div>
          </div>
          {/* <div className="col col-lg-6 col-12">
                        <div className="section-title-text">
                            <p>Join us for transformative business events across Pan-India! Network, learn, and grow with industry leaders. Check the events below and register now to transform your business.</p>
                        </div>
                    </div> */}
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <div className="blog-grids clearfix">
              {blogs.map((blog, bl) => (
                <div className="grid" key={bl}>
                  <div className="entry-media">
                    <img src={blog.screens} alt="" />
                  </div>
                  <div className="entry-date">
                    <h4>{blog.create_day}</h4>
                    <span>
                      {blog.create_month} <br />
                      {blog.create_year}
                    </span>
                  </div>
                  {/* <h3><Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>{blog.title}</Link></h3> */}
                  <h3>{blog.title}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventPage;
