import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pimg1 from '../../images/social-icon-img/Facebook.png'
import pimg2 from '../../images/social-icon-img/Instagram.png'
import pimg3 from '../../images/social-icon-img/Linkdin.png'
import pimg4 from '../../images/social-icon-img/Telegram.png'
import pimg6 from '../../images/social-icon-img/x.png'
import pimg5 from '../../images/social-icon-img/Youtube.png'
const PartnerSectionS2 = (props) => {
    return (
        <section className={`partners-section section-padding ${props.tNone}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div class="section-title-s3">
                            <span>My Presence</span>
                            <h2>My Social Media Presence</h2>
                        </div>
                        <div className="partner-grids partners-slider owl-carousel">
                            <div className="row">
                            <div className="col-6 col-md">
                                    <a href="https://www.youtube.com/@rahulmalodiaofficial" className="socia-icon-div" target="_blank">
                                        <img src={pimg5} />
                                        <p>6.18M</p>
                                        <p>Subscribers</p>
                                    </a>
                                </div>
                                <div className="col-6 col-md">
                                    <a href="https://www.facebook.com/RahulMalodiaOfficial" className="socia-icon-div" target="_blank">
                                        <img src={pimg1} />
                                        <p>5.3M</p>
                                        <p>Followers</p>
                                    </a>
                                </div>
                                <div className="col-6 col-md">
                                    <a href="https://www.instagram.com/rahulmalodiaofficial/" className="socia-icon-div" target="_blank">
                                        <img src={pimg2} />
                                        <p>1.5M</p>
                                        <p>Followers</p>
                                    </a>
                                </div>
                                <div className="col-6 col-md">
                                    <a href="https://www.linkedin.com/in/rahul-malodia-5ab2138a/" className="socia-icon-div" target="_blank">
                                        <img src={pimg3} />
                                        <p>13K</p>
                                        <p>Followers</p>
                                    </a>
                                </div>
                                <div className="col-6 col-md">
                                    <a href="https://t.me/CArahulmalodiaofficial" className="socia-icon-div" target="_blank">
                                        <img src={pimg4} />
                                        <p>12.9K</p>
                                        <p>Followers</p>
                                    </a>
                                </div>
                                <div className="col-6 col-md">
                                    <a href="https://x.com/carahulmalodia" className="socia-icon-div" target="_blank">
                                        <img src={pimg6} />
                                        <p>3.9K</p>
                                        <p>Followers</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default PartnerSectionS2;
