export const GoogleManger = `
(function(w, d, s, l, i) {
  w[l] = w[l] || []; 
  w[l].push({
    'gtm.start': new Date().getTime(), 
    event: 'gtm.js'
  }); 
  var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), 
      dl = l !== 'dataLayer' ? '&l=' + l : ''; 
  j.async = true; 
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; 
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-KX9PJMQ');
`;

export const GoogleAdCourseThank = `
 window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-10857044230');
 
  `;

export const GoogleAdThank = `
  gtag('event', 'conversion', {
      'send_to': 'AW-10857044230/-tEyCLXmtrIDEIayhbko',
      'transaction_id': ''
  });
  `;
export const startCountdown = (durationInMinutes, setCountDown) => {
  const durationInSeconds = durationInMinutes * 60;
  let remainingTime = durationInSeconds;

  const interval = setInterval(() => {
    const hours = Math.floor(remainingTime / 3600);
    console.log('hours: ', hours);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;

    // console.log(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
    // const countDown = `${hours}h ${minutes}m ${seconds < 10 ? "0" : ""}${seconds}s`;
    const countDown = {
      hours: `${hours < 10 ? "0" : ""}${hours}`,
      minutes: `${minutes < 10 ? "0" : ""}${minutes}`,
      seconds: `${seconds < 10 ? "0" : ""}${seconds}`
    }
    setCountDown(countDown);

    remainingTime -= 1;

    if (remainingTime < 0) {
      clearInterval(interval);
    }

    return interval;
  }, 1000);
};

export const employeeHiringUrl = 'https://payments.malodiabusinesscoaching.org/Employee-hiring-mastery';
export const employeeManagementUrl = 'https://payments.malodiabusinesscoaching.org/Employee-management-mastery'
export const financeFundamentalUrl = 'https://payments.malodiabusinesscoaching.org/finance-fundamentals'
export const financeManagementUrl = 'https://payments.malodiabusinesscoaching.org/Finance-management-mastery';
export const somUrl = 'https://payments.malodiabusinesscoaching.org/Secrets-of-Money';
export const v2cUrl = 'https://payments.malodiabusinesscoaching.org/Vyapari-to-CEO';