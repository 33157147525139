import React, { Fragment } from 'react'
import V2C from './V2C'
const V2CPage = () => {
  return (
    <Fragment>
        <V2C/>
    </Fragment>
  )
}
export default V2CPage
