import React from 'react'
import CountUp from 'react-countup';
import fimg1 from '../../images/icon/BusinessmenTrained.png'
import fimg2 from '../../images/icon/Cities.png'
import fimg3 from '../../images/icon/Industry.png'
import fimg4 from '../../images/icon/Views.png'
const funFact = [
    {
        title: '300000',
        subTitle: 'Businessmen Trained',
        Symbol: '+',
        icon: fimg1,
    },
    {
        title: '475',
        subTitle: 'Cities',
        Symbol: '+',
        icon: fimg2,
    },
    {
        title: '300',
        subTitle: 'Industry',
        Symbol: '+',
        icon: fimg3,
    },
   
    {
        title: '910',
        subTitle: 'Views',
        Symbol: 'M+',
        icon: fimg4,
    },


]


const FunFact = (props) => {
    return (
        <section className="fun-fact-section">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="fun-fact-grids">
                            {funFact.map((funfact, fitem) => (
                                <div className="grid" key={fitem}>
                                    <div className="icon">
                                        <img src={funfact.icon} />
                                    </div>
                                    <div className="info">
                                        <h3><span><CountUp end={funfact.title} enableScrollSpy /></span>{funfact.Symbol}</h3>
                                        <p>{funfact.subTitle}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <span id="counter" className='d-none' />
        </section>
    )
}

export default FunFact;