import blogImg1 from "../images/blog/img-1.png";
import blogImg2 from "../images/blog/img-2.png";
import blogImg3 from "../images/blog/img-3.png";
import blogSingleImg1 from "../images/blog/img-4.jpg";
import blogSingleImg3 from "../images/blog/img-6.jpg";
import blogSingleImg2 from "../images/blog/img-5.jpg";
const blogsMain = [ 
    {
        id: '1',
        title: 'Amul Case Study – The Taste of India (History & Future)',
        slug: 'amul-case-study',
        screens: blogImg1,
        description: 'The history of Amul is a trip that changed the Indian dairy industry forever. That change has affected milk and dairy goods in India.',
        author: 'Rahul Malodia',
        authorTitle:'Consultant',
        create_at: 'Nov 15, 2024',
        create_day: '15',
        create_month: 'Nov',
        create_year: '2024',
        blogSingleImg:blogSingleImg1,
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'Nike VS Adidas: Who Is Leading The Market?',
        slug: 'nike-vs-adidas',
        screens: blogImg2,
        description: 'Nike vs Adidas is a standard rivalry in the global clothing market. Both names are now associated with sports culture, high-fashion lifestyles, and new technologies.',
        author: 'Rahul Malodia',
        authorTitle:'Consultant',
        create_at: 'Nov 08, 2024',
        create_day: '08',
        create_month: 'November',
        create_year: '2024',
        blogSingleImg:blogSingleImg2, 
        blClass:'format-standard-image',
    },
    {
        id: '3',
        title: '20 Business for Ladies with Low Investment to Start In India',
        slug: '20-business-for-ladies-with-low-investment-to-start-in-india',
        screens: blogImg3,
        description: 'Starting a firm does not necessarily call for large expenditures of money. Thanks to the current state of the economy, women may launch their own companies with little or none at all.',
        author: 'Rahul Malodia',
        authorTitle:'Consultant',
        create_at: 'Oct 29, 2024',
        create_day: '29',
        create_month: 'Oct',
        create_year: '2024',
        blogSingleImg:blogSingleImg3,
        blClass:'format-video',
    },
];
export default blogsMain;