import React from 'react';
import { Link } from 'react-router-dom'
import about from '../../images/about.png'
import blogsMain from '../../api/blogs-main.js'

const SubmitHandler = (e) => {
    e.preventDefault()
}

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const BlogSidebar = (props) => {
    return (
        <div className={`col col-lg-3 col-12 ${props.blLeft}`}>
            <div className="blog-sidebar">
                <div className="widget search-widget">
                    <h3>Search Here</h3>
                    <form onSubmit={SubmitHandler}>
                        <div>
                            <input type="text" className="form-control" placeholder="Search Post.." />
                            <button type="submit"><i className="ti-search"></i></button>
                        </div>
                    </form>
                </div>
                <div className="widget about-widget">
                    <h3>About us</h3>
                    <div className="img-holder">
                        <img src={about} alt="" />
                    </div>
                    <p>CA Rahul Malodia is the best business coach in India who has trained over 300000+ businessmen and transformed them from <b>“VYAPARI to CEO”</b>, working on a mission to make India again <b/><b>“Sone ki Chidiya”</b>.</p>
                    <Link onClick={ClickHandler} to="/about">More about us</Link>
                </div>
                <div className="widget recent-post-widget">
                    <h3>Related Posts</h3>
                    <div className="posts">
                        {blogsMain.map((blog, bl) => (
                            <div className="post" key={bl}>
                                <div className="img-holder">
                                    <img src={blog.blogSingleImg} alt="" />
                                </div>
                                <div className="details">
                                    <h4><Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>{blog.title}</Link></h4>
                                    <span className="date">{blog.create_at}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="widget tag-widget">
                    <h3>Tags</h3>
                    <ul>
                        <li><Link onClick={ClickHandler} to="/blog">Consulting</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog">Planning</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog">Marketing</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog">Strategy</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog">Finance</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog">Solution</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog">Corporate</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog">Idea</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog">Market Reserch</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )

}

export default BlogSidebar;
