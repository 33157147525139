import React from "react";
import { Link } from 'react-router-dom'
import Services from '../../api/service'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const ServiceSection = () => {
    return (
        <section className="services-section-s2 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div className="section-title-s3">
                            <span>Courses</span>
                            <h2>Our Courses</h2>
                            <p>Our courses are designed to provide step-by-step learning across essential business skills. Each course is designed to transform how you approach hiring, management, branding, sales, marketing, and finance.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="services-grids clearfix">
                            {Services.map((service, srv) => (
                                <Link onClick={ClickHandler} to={`/courses/${service.slug}`} className="grid" key={srv}>
                                    <div className="icon">
                                        <img src={service.iimg} />
                                        </div>
                                    <h3><Link onClick={ClickHandler} to={`/courses/${service.slug}`}>{service.sTitle}</Link></h3>
                                    <p>{service.description}</p>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;