import React from 'react'
import iimg11 from '../../images/icon/BusinessConsulting.png' 
import iimg12 from '../../images/icon/MarketReasearch.png' 
import iimg13 from '../../images/icon/ThoughtLeadership.png' 
const Features = (props) => {
    return (
        <section className="features-section">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="feature-grids clearfix">
                            <div className="grid">
                                <div className="icon">
                                    <img src={iimg11} />

                                </div>
                                <h3>Business Consulting</h3>
                                <p>Every business faces roadblocks, but yours doesn’t have to face them alone. We align strategies with your growth aspirations, simplify complexities, and drive measurable outcomes.</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <img src={iimg12} />
                                </div>
                                <h3>Market Research</h3>
                                <p>Understanding your market isn’t guesswork—it’s essential. We provide precise insights into trends and customer behavior, empowering confident, impactful decisions through meaningful research and analysis.</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <img src={iimg13} />
                                </div>
                                <h3>Thought Leadership</h3>
                                <p>Shaping perspectives isn’t optional it’s essential. We help you craft resonating ideas, position your brand as a trusted voice, and inspire impactful conversations in your industry.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;