import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";

const ServicePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Courses</title>
      </Helmet>
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      <PageTitle pageTitle={"Courses"} pagesub={"Courses"} />
      <ServiceSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePage;
