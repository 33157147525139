import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};
const Testimonial = (prop) => {
    return (
        <section className={`testimonials-section section-padding ${prop.tClass}`} style={{marginTop:'20px'}}>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-xl-5 col-lg-5">
                        <h2 className="text-start text-white main-h">Our <span style={{color:'#ddc380'}}>Testimonials</span></h2>
                        <h4 className="text-start text-white sub-h">Stories of Impact</h4>
                    </div>
                    <div className="col-12 col-xl-7 col-lg-7 mb-4 mb-md-0">
                         <div className="testimonial-slider">
                            <Slider {...settings}>
                                <div className="testimonials-area">
                                    <iframe width="100%" height="420" src="https://www.youtube.com/embed/H8CPZRB-J8c?si=rA7vWANlwXQL1WDb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className="testimonials-area">
                                    <iframe width="100%" height="420" src="https://www.youtube.com/embed/lH6Eyqyeww0?si=tp0FX9kzj9Qjmje3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className="testimonials-area">
                                    <iframe width="100%" height="420" src="https://www.youtube.com/embed/QZ89xnA5nUc?si=Iq8eOlJX_UayMxlP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className="testimonials-area">
                                    <iframe width="100%" height="420" src="https://www.youtube.com/embed/JiarguR2dYk?si=OQ1WISEQvb11YgHX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                <div className="testimonials-area">
                                    <iframe width="100%" height="420" src="https://www.youtube.com/embed/mjWq8quQzms?si=Ar0FW324kty_y-U9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Testimonial;
