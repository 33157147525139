import React, { Fragment } from 'react'
import SecretsOfMoney from './SecretsOfMoney'
const SOMPage = () => {
  return (
    <Fragment>
        <SecretsOfMoney/>
    </Fragment>
  )
}
export default SOMPage
