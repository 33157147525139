import simg from '../images/courses-single/VyaparitoCOE.png'
import simg2 from '../images/courses-single/AdvanceCourse.jpg'
import simg3 from '../images/courses-single/SecretofMoney.png'
import simg4 from '../images/courses-single/EmployeeHiring.png'
import simg5 from '../images/courses-single/EmployeeInduction.png'
import simg6 from '../images/courses-single/EmployeeManagement.png'
import simg7 from '../images/courses-single/BrandingMastery.png'
import simg8 from '../images/courses-single/MarketingMastery.png'
import simg9 from '../images/courses-single/SalesMastery.png'
import simg10 from '../images/courses-single/FinanceFreedom.png'
import simg11 from '../images/courses-single/FinanceManagement.png'
import simg12 from '../images/courses-single/FinanceFundamental.png'
import iimg1 from '../images/service-single/V2C.png'
import iimg2 from '../images/service-single/AdvanceCourse.png'
import iimg3 from '../images/service-single/SecretofMoney.png'
import iimg4 from '../images/service-single/EmployeeHiring.png'
import iimg5 from '../images/service-single/EmployeeInduction.png'
import iimg6 from '../images/service-single/EmployeeManagement.png'
import iimg7 from '../images/service-single/Branding.png'
import iimg8 from '../images/service-single/Marketing.png'
import iimg9 from '../images/service-single/Sale.png'
import iimg10 from '../images/service-single/FinanceFreedom.png'
import iimg12 from '../images/service-single/FinanceFundamental.png'
import iimg11 from '../images/service-single/FinanceManagement.png'
const Services = [
   {
       Id: '1',
       sImg: simg,
       sTitle: 'Vyapari to CEO',
       slug: 'Vyapari-to-CEO',
       description: 'Transform yourself from “Vyapari to CEO”',
       des2: 'Vyapari 2 CEO is the flagship course introduced by the best business coach in India, CA Rahul Malodia, to help small business owners transform their businesses and become “Vyapari to CEO.”',
       des3: 'Vyapari 2 CEO is a 4-hour webinar by CA Rahul Malodia every Sunday that helps business owners understand problems in different business areas, such as Marketing, Sales, Finance, Employee Management, and more.',
       des4: 'This webinar highlights the challenges businesses face in day-to-day operations and how they can overcome them to achieve long-term success.',
       hnew: 'Why should one join the “Vyapari 2 CEO” Webinar?',
       des5: 'The four-hour webinar “Vyapari 2 CEO” is perfect for those stuck in their business and facing issues in growing it due to one of the many challenges in different areas, regardless of their industry.',
       fe1: 'Access to comprehensive business training and education.',
       fe2: 'Practical strategies that help improve business performance operations, increase profitability and scale your business.',
       fe3: 'Developing strong leadership skills to manage a team and inspire growth.',
       fe4: 'Personalized guidance from the best business coach, CA Rahul Malodia.',
       fe5: 'Focusing on cultivating a positive mindset.',
       fe6: 'Learn the art of retaining work-life balance.',
       be1: 'Entrepreneurship.',
       be2: 'Sales.',
       be3: 'Marketing.',
       be4: 'Finance.',
       be5: 'Hiring/ Team Building.',
       be6: 'Networking.',
       whojoin1: 'Small Business Owners',
       whojoin2: 'Start-up owners',
       whojoin3: 'Traders',
       whojoin4: 'Manufacturers',
       whojoin5: 'Self-Employed',
       whyjoin1: 'Expert-led courses',
       whyjoin2: '24/7 Support Services',
       whyjoin3: 'Lifetime Assistance',
       whyjoin4: 'Earn valuable credentials',
       whyjoin5: 'Risk-Free Enrollment',
       iimg: iimg1,
       price:'₹99'
   },
   {
       Id: '2',
       sImg: simg2,
       sTitle: 'Advance Courses',
       slug: 'Advanced-courses',
       description: 'Master advanced skills for business success.',
       des2: "Take Your Business to the Next Level with CA Rahul Malodia’s 11 Powerful Advanced Courses. One Life-Changing Journey. Running a business is not easy. You juggle a hundred daily things—marketing, sales, employees, and money management—but still feel stuck. ",
       des3: "What if you could learn everything you need to grow your business in one place? Welcome to CA Rahul Malodia’s Advanced Course.",
       des4: "This bundle of 11 courses is designed to help you master every part of your business, giving you the tools and knowledge to go from managing your business to excelling as a true CEO.",
       hnew: "What You’ll Learn",
       des5: "These 11 courses will show you how to handle every part of your business step by step, with simple lessons you can easily apply. It’s time to stop surviving and start thriving. Join the Advanced Course today and see the change for yourself!",
       fe1: "SECRETS OF MONEY",
       fe2: "Vyapari 2 CEO",
       fe3: "Marketing Mastery",
       fe4: "Sales Mastery",
       fe5: "Branding Mastery",
       fe6: "Employee Hiring Mastery",
       be1: 'Entrepreneurship.',
       be2: 'Sales.',
       be3: 'Marketing.',
       be4: 'Finance.',
       be5: 'Hiring/ Team Building.',
       be6: 'Networking.',
       whojoin1: 'Small Business Owners',
       whojoin2: 'Start-up owners',
       whojoin3: 'Traders',
       whojoin4: 'Manufacturers',
       whojoin5: 'Self-Employed',
       whyjoin1: "Expert-led courses",
       whyjoin2: "24/7 Support Services",
       whyjoin3: "Lifetime Assistance",
       whyjoin4: "Earn valuable credentials",
       whyjoin5: "Risk-Free Enrollment",
       iimg: iimg2,
       price:'₹19,999'
   },
   {
       Id: '663de2e3df5f527c229e1101',
       sImg: simg3,
       sTitle: 'Secrets of Money',
       slug: 'Secrets-of-Money',
       description: 'Learn smart ways to grow wealth & compound your earnings.',
       des2: "Learn to Earn, Save, and Grow Your Wealth! Money—it’s something we all want, need, and think about. But do we understand it? Welcome to the Secrets of Money course, where you’ll discover simple, practical ways to take control of your finances and secure your future.",
       des3: "This course isn’t just about money—it’s about giving you the confidence to take charge of your financial life. It’s time to start your journey toward financial freedom!",
       des4: "This course is designed for everyone, no matter how much or little you know about managing money. Whether you’re just starting your financial journey or want to multiply your wealth, these four easy-to-understand modules will guide you step by step.",
       hnew: "What You’ll Learn",
       des5: "Enroll and take a step towards financial freedom. Learn what money is and how it works. In these modules, you’ll uncover the hidden truths about wealth and how the rich think differently about money.",
       fe1: "Module 1- Money Secret",
       fe2: "Module 2- Earning Secret",
       fe3: "Module 3- Expense Management",
       fe4: "Module 4- Wealth Creation",
       fe5: "",
       fe6: "",
       be1: "Learn secrets of wealth creation",
       be2: "Learn common investing mistakes",
       be3: "Learn how to overcome a spending mindset",
       be4: "Learn how to build a new money mindset",
       be5: "Learn the difference between Rich vs. Wealthy.",
       be6: "",
       whojoin1: "Entrepreneurs",
       whojoin2: "Start-up owners",
       whojoin3: "Self-Employed",
       whojoin4: "Traders",
       whojoin5: "Manufacturers",
       whyjoin1: "Expert-led courses",
       whyjoin2: "24/7 Support Services",
       whyjoin3: "Lifetime Assistance",
       whyjoin4: "Earn valuable credentials",
       whyjoin5: "Risk-Free Enrollment",
       iimg: iimg3,
       price:'₹1,999'
   },
   {
       Id: '662dfb952b3ba40cef22a0e6',
       sImg: simg4,
       sTitle: 'Employee Hiring Mastery',
       slug: 'Employee-hiring-mastery',
       description: 'Learn smarter strategies to hire the right people effortlessly.',
       des2: "Hiring the right employees is more than just filling seats in the office. It's about finding people who will help your company grow and succeed. But let's face it—hiring isn't easy. Each step can feel tricky, from finding suitable candidates to asking the right interview questions. That's where this course comes in.",
       des3: "Welcome to Employee Hiring Mastery, your ultimate guide to building a winning team. Whether you're a business owner, HR manager, or someone just starting in recruitment, this course makes hiring simple and easy to understand.",
       des4: "This course will show you how to build a team that matches your company's values. After all, it's not just about skills—it's also about fit. By the end of this course, you'll have the confidence to hire people who bring fresh ideas, work hard, and stick around for the long haul.",
       hnew: "What You'll Learn:",
       des5: "In this course, you'll quickly gain practical insights to identify, recruit, and retain top talent. Whether dealing with high-level management positions or operational team members, you'll learn techniques that apply to every role and function. Key topics covered include:",
       fe1: "Learn how to find their qualities and skills when you interact with them.",
       fe2: "3 Types of Employees to Hire in Every Business.",
       fe3: "4 Types of Personalities to look for in employees.",
       fe4: "Identifying the issues in the existing team.",
       fe5: "Know the complete hiring process and its implementation.",
       fe6: "Learn the interview process in detail.",
       be1: "Learn smart hiring techniques to find the right people for your business.",
       be2: "Master simple ways to manage teams and daily operations.",
       be3: "Build a strong brand that people trust and remember.",
       be4: "Boost your sales with proven tactics.",
       be5: "Understand easy marketing strategies to grow faster.",
       be6: "",
       whojoin1: "Entrepreneurs",
       whojoin2: "Start-up owners",
       whojoin3: "Self-Employed",
       whojoin4: "Traders",
       whojoin5: "Manufacturers",
       whyjoin1: "Expert-led courses",
       whyjoin2: "24/7 Support Services",
       whyjoin3: "Lifetime Assistance",
       whyjoin4: "Earn valuable credentials",
       whyjoin5: "Risk-Free Enrollment",
       iimg: iimg4,
       price:'₹2,999'
   },
   {
       Id: '662e55802b3ba40cef22ad05',
       sImg: simg5,
       sTitle: 'Employee Induction Mastery',
       slug: 'Employee-induction-mastery',
       description: 'Learn onboarding processes to make employees feel welcomed instantly.',
       des2: "After hiring, the employee undergoes induction on his joining day. The induction process should be precise and cover everything necessary to help employees develop a proper understanding.",
       des3: "The induction sets the right tone for employees to feel welcomed, informed, and engaged from day one. It covers the critical elements of an induction program equipped with the proper knowledge and tools to create a seamless and impactful onboarding experience.",
       des4: "This course empowers business owners, leaders, and HR professionals to design and execute an efficient, impactful, and professional employee induction process. It’s more than just onboarding—it’s about creating a positive, lasting impression and setting the tone for long-term success. Because let’s face it: how you begin is how you’ll continue.",
       hnew: "What You’ll Learn",
       des5: "",
       fe1: "Importance & Process of Employee Induction in detail.",
       fe2: "Documents required on Joining Day.",
       fe3: "The basic information needed to be shared with employees.",
       fe4: "How do we define work and measure employee performance? (KRA/KPI)",
       fe5: "Prepare SOP to automate the work and tasks.",
       fe6: "Know the Attraction & Retention Tools",
       be1: "Learn the onboarding process and how to welcome employees.",
       be2: "Master simple ways to manage teams and daily operations.",
       be3: "Build a strong brand that people trust and remember.",
       be4: "Boost your sales with proven tactics.",
       be5: "Understand easy marketing strategies to grow faster.",
       be6: "",
       whojoin1: "Entrepreneurs",
       whojoin2: "Start-up owners",
       whojoin3: "Self-Employed",
       whojoin4: "Traders",
       whojoin5: "Manufacturers",
       whyjoin1: "Expert-led courses",
       whyjoin2: "24/7 Support Services",
       whyjoin3: "Lifetime Assistance",
       whyjoin4: "Earn valuable credentials",
       whyjoin5: "Risk-Free Enrollment",
       iimg: iimg5,
       price:'₹2,999'

   },
   {
       Id: '66332a765561125da50fe83e',
       sImg: simg6,
       sTitle: 'Employee Management Mastery',
       slug: 'Employee-management-mastery',
       description: 'Handle teams with confidence and achieve better workplace results.',
       des2: "Master the art of employee management with the expert CA Rahul Malodia. Employee management is a never-ending cycle. It means seeing and hearing employees. It means appreciating and motivating them. It means giving timely, constructive feedback.",
       des3: "The employee management course by CA Rahul Malodia aims to help you excel at work today. It will provide you with the proper knowledge, skills, and strategies.",
       des4: "This course covers eight modules. Each module offers comprehensive knowledge about employee management and how their operations work in the organization.",
       hnew: "What You'll Learn:",
       des5: 'Effective employee management is not just about delegating tasks but about cultivating an environment where employees are empowered and aligned with the organizational mission. In this course, you will learn about employee management in detail',
       fe1: '4 Myths About Employee Managemen',
       fe2: 'How to Deal with High Skill & Low Will Employees?',
       fe3: 'Mindset/Tips for Firing Employees',
       fe4: "Know a Leader's Job Role and Responsibilities.",
       fe5: 'How to Build a Great Team?',
       fe6: 'Learn How to Delegate the Work.',
       be1: 'Enhanced Leadership Skills',
       be2: 'Learn solutions tailored for Business Growth',
       be3: 'Develop Practical and Actionable Skills',
       be4: 'Workplace productivity optimization',
       be5: 'Access to ongoing support',
       be6: 'Reduced Employee turnover',
       whojoin1: 'Entrepreneurs',
       whojoin2: 'Start-up owners',
       whojoin3: 'Self-Employed',
       whojoin4: 'Traders',
       whojoin5: 'Manufacturers',
       whyjoin1: 'Expert-led courses',
       whyjoin2: '24/7 Support Services',
       whyjoin3: 'Lifetime Assistance',
       whyjoin4: 'Earn valuable credentials',
       whyjoin5: 'Risk-Free Enrollment',
       iimg: iimg6,
       price:'₹2,999'

   },
   {
       Id: '662e5e8d2b3ba40cef22b381',
       sImg: simg7,
       sTitle: 'Branding Mastery',
       slug: 'Branding-mastery',
       description: 'Craft memorable brands that connect with your audience meaningfully.',
       des2: 'The Branding Mastery Course helps businesses stand out from rivals. It creates unique brand foundations and identities. It gives entrepreneurs and professionals the skills to build a strong brand. ',
       des3: 'The course will help you create a distinctive brand voice and visual style. You will also learn the differences between branding, marketing, and sales, how to build a brand, and how branding affects customers and their buying behavior. ',
       des4: 'Branding makes your brand the “TALK OF THE TOWN,” which generates sales and revenue. When people trust your brand, referrals are easy. A word-of-mouth marketing strategy then works like magic.',
       hnew: 'Why Branding Is Essential for Business Success?',
       des5: 'Branding is more than just logos and taglines; it’s about building trust and forming connections with your target audience. A well-branded business is easier to recognize, refer, and remember. Here’s why this course will make your business stand out:',
       fe1: 'What is the difference between sales and marketing?',
       fe2: 'Learn what’s a brand and what’s not.',
       fe3: '4 Steps of Building a Brand.',
       fe4: 'Why branding is so important in business?',
       fe5: '2 Types of Customers you must avoid.',
       fe6: '2 Types of Customers you must attract.',
       be1: 'Build a strong brand that people trust and remember.',
       be2: 'Master simple ways to manage teams and daily operations.',
       be3: 'Boost your sales and revenue with proven strategies.',
       be4: 'Understand easy marketing strategies to grow faster.',
       be5: 'Gain credentials, lifetime assistance, and easy access to expert insights.',
       whojoin1: 'Entrepreneurs',
       whojoin2: 'Start-up owners',
       whojoin3: 'Self-Employed',
       whojoin4: 'Traders',
       whojoin5: 'Manufacturers',
       whyjoin1: 'Expert-led courses',
       whyjoin2: '24/7 Support Services',
       whyjoin3: 'Lifetime Assistance',
       whyjoin4: 'Earn valuable credentials',
       whyjoin5: 'Risk-Free Enrollment',
       iimg: iimg7,
       price:'₹2,999'

   },
   {
       Id: '662f78fa4c7101682e21acfb',
       sImg: simg8,
       sTitle: 'Marketing Mastery',
       slug: 'Marketing-mastery',
       description: 'Discover effective marketing techniques to grow your business consistently.',
       des2: 'Marketing isn’t limited to creating awareness or marketing products or services. It involves understanding consumer behavior and factors that drive brand awareness, customer acquisition, and sales.',
       des3: 'Marketing is an ongoing activity that requires daily effort to drive results. Marketing your product or service or locating a product market fit requires creativity, strategy, and a data-driven approach.',
       des4: 'To use this approach, one must learn the fundamentals of creating marketing plans and how to use each marketing channel to drive higher results. The Marketing Mastery Course by CA Rahul Malodia is designed to help you improve your marketing skills.',
       hnew: 'What You’ll Learn',
       des5: 'With Marketing Mastery, CA Rahul Malodia teaches you how to excel in marketing, even if you don’t have prior experience. The course provides a step-by-step guide to understanding your audience, designing a winning strategy, and using effective marketing channels to grow your business.',
       fe1: 'What is marketing, and what are its commonly used routes?',
       fe2: 'How to create a marketing plan?',
       fe3: 'How to implement a marketing plan?',
       fe4: 'How do you allocate a marketing budget?',
       fe5: '8 Popular marketing channels',
       fe6: 'Marketing Fundamentals/Tips',
       be1: 'Clear understanding of marketing basics.',
       be2: 'Learn how to create a solid marketing plan.',
       be3: 'Use the proper marketing channels.',
       be4: 'Scale your customer base and revenue.',
       be5: 'Control your marketing budget better',
       be6: '',
       whojoin1: 'Entrepreneurs',
       whojoin2: 'Start-up owners',
       whojoin3: 'Self-Employed',
       whojoin4: 'Traders',
       whojoin5: 'Manufacturers',
       whyjoin1: 'Expert-led courses',
       whyjoin2: '24/7 Support Services',
       whyjoin3: 'Lifetime Assistance',
       whyjoin4: 'Earn valuable credentials',
       whyjoin5: 'Risk-Free Enrollment',
       iimg: iimg8,
       price:'₹2,999'


   },
   {
       Id: '663366f15e7ad74c097d9c7c',
       sImg: simg9,
       sTitle: 'Sales Mastery',
       slug: 'Sales-mastery',
       description: 'Boost your sales skills and close deals with ease. ',
       des2: "Sales aren't limited to selling your product and convincing people to buy your product or service. Sales require understanding people, building customer relationships and trust, and solving customers' problems.",
       des3: "But we all know selling isn't easy and can become overwhelming. Have you ever wondered what separates a great salesperson from an average one?",
       des4: "The best salespersons are those who follow a standard sales process rather than relying on luck or charm. And now, CA Rahul Malodia, one of India’s finest business coaches, is ready to share the secrets to sales success with his Sales Mastery course.",
       hnew: "What You’ll Learn",
       des5: "The Sales Mastery Course covers eight in-depth modules to help you understand the psychological and practical aspects of selling. Each module is designed to give you the confidence and knowledge to sell like a professional.",
       fe1: "Why is the sales process important?",
       fe2: "Learn the salesman mindset.",
       fe3: "How do we build connections and win trust?",
       fe4: "How do you pitch products and services?",
       fe5: "How do we create urgency to sell products and services?",
       fe6: "How do you handle objections?",
       be1: "Master the process of sales",
       be2: "Learn how to boost revenue consistently",
       be3: "Build strong relationships with customers",
       be4: "Master negotiation skills",
       be5: "Proven techniques",
       be6: "",
       whojoin1: "Entrepreneurs",
       whojoin2: "Start-up owners",
       whojoin3: "Self-Employed",
       whojoin4: "Traders",
       whojoin5: "Manufacturers",
       whyjoin1: "Expert-led courses",
       whyjoin2: "24/7 Support Services",
       whyjoin3: "Lifetime Assistance",
       whyjoin4: "Earn valuable credentials",
       whyjoin5: "Risk-Free Enrollment",
       iimg: iimg9,
       price:'₹2,999'

   },
   {
       Id: '663220646cbc26a4585a3f8f',
       sImg: simg10,
       sTitle: 'Finance Freedom Mastery',
       slug: 'Finance-freedom-mastery',
       description: 'Achieve financial independence by learning proven wealth-building strategies.',
       des2: "Doing business isn’t enough to achieve financial freedom; it’s more than that. It means understanding the finances, maintaining the cash reserve, allocating the budget, and learning to read financial statements, which helps understand the business's current situation.",
       des3: "This is where Finance Freedom Mastery by CA Rahul Malodia steps in. It’s not just another business course; it’s a practical guide to achieving financial freedom. Whether you’re a seasoned entrepreneur, a small business owner, or just starting, this course offers the tools to take control of your finances. With simple steps and in-depth insights, this program can forever transform how you see and manage your business finances.",
       des4: "The course simplifies complex concepts into step-by-step strategies so you can finally feel confident about your business numbers. If you don’t truly understand your finances, you’re flying blind. But with Finance Freedom Mastery, you’ll finally take the wheel.",
       hnew: "What You’ll Learn",
       des5: "The Finance Freedom Mastery course comprises six power-packed modules that will give you total control over your business finances. Here's what’s included:",
       fe1: "What is the wealth cycle?",
       fe2: "What is a Balance Sheet, & Why should you review it?",
       fe3: "What is a Profit & Loss Statement? What should you check in it?",
       fe4: "What is a Cash flow statement?",
       fe5: "What is the role of the Turnover ratio in business?",
       fe6: "What are the basics of working capital?",
       be1: "Learn how to build wealth.",
       be2: "Master simple ways to manage teams and daily operations.",
       be3: "Build a strong brand that people trust and remember.",
       be4: "Understand Financial Statements",
       be5: "Know how to earn money through multiple sources.",
       be6: "",
       whojoin1: "Entrepreneurs",
       whojoin2: "Start-up owners",
       whojoin3: "Self-Employed",
       whojoin4: "Traders",
       whojoin5: "Manufacturers",
       whyjoin1: "Expert-led courses",
       whyjoin2: "24/7 Support Services",
       whyjoin3: "Lifetime Assistance",
       whyjoin4: "Earn valuable credentials",
       whyjoin5: "Risk-Free Enrollment",
       iimg: iimg10,
       price:'₹2,999'

   },
   {
       Id: '663386835e7ad74c097dac42',
       sImg: simg11,
       sTitle: 'Finance Management Mastery',
       slug: 'Finance-management-mastery',
       description: 'Learn to handle finances smartly for stability and growth.',
       des2: "Finance Management Mastery is the second part of Rahul Malodia’s Financial Freedom Mastery series. This course is designed to simplify complex financial concepts and provide clear, actionable strategies for controlling one's financial future.",
       des3: "Consider it your guide to understanding your company’s financial position and mastering the skills to make informed decisions. Financial management mastery is designed to help you control your finances and build a brighter future. ",
       des4: "It provides the tools, insights, and strategies to make smarter financial decisions—whether you're an entrepreneur, a professional, or someone looking to achieve personal financial freedom. This course breaks down critical financial techniques from managing debts to building wealth into easy, doable steps.",
       hnew: "What You’ll Learn",
       des5: "The course covers 7 essential modules, each focusing on important finance and wealth creation aspects. Here’s what you can expect:",
       fe1: "Need of Working Capital",
       fe2: "3 Tips for Stock Management",
       fe3: "3 Tips for Debtors Management (Recovery)",
       fe4: "3 Tips for Creditors Management (Purchase)",
       fe5: "3 Steps of Wealth Creation",
       fe6: "3 Mistakes That Destroy Wealth",
       be1: "Learn practical financial skills.",
       be2: "Gain confidence in making financial decisions.",
       be3: "Avoid costly mistakes",
       be4: "Become a wealth creator.",
       be5: "The course is for everyone, not just experts.",
       be6: "",
       whojoin1: "Entrepreneurs",
       whojoin2: "Start-up owners",
       whojoin3: "Self-Employed",
       whojoin4: "Traders",
       whojoin5: "Manufacturers",
       whyjoin1: "Expert-led courses",
       whyjoin2: "24/7 Support Services",
       whyjoin3: "Lifetime Assistance",
       whyjoin4: "Earn valuable credentials",
       whyjoin5: "Risk-Free Enrollment",
       iimg: iimg11,
       price:'₹2,999'

   },
   {
       Id: '663382b55e7ad74c097daa23',
       sImg: simg12,
       sTitle: 'Finance Fundamentals',
       slug: 'finance-fundamentals',
       description: 'Understand basic money concepts to build a solid financial foundation.',
       des2: 'When you have solid financial fundamentals, it becomes easy to secure your future. The course will help you understand the basics of finances, which is essential in making informed decisions and achieving economic stability in the business world.',
       des3: 'Finance Fundamentals by CA Rahul Malodia is the gateway to learning and mastering financial principles and budgeting, which helps lay the proper foundation for business success.',
       des4: 'CA Rahul Malodia isn’t just a Chartered Accountant—he’s a business coach with years of experience helping businesses grow. His course is designed for beginners and non-finance people who want to build strong economic foundations without complicated jargon.',
       des5: 'What You’ll Learn',
       fe1: 'Importance of Finance in Business.',
       fe2: '4 Partners of Sales.',
       fe3: '2 Golden Financial Rules.',
       fe4: 'What is Credibility Trap?',
       fe5: 'Should you take loans or not?',
       fe6: 'How do I take loans?',
       be1: 'Learn finance in the simplest language.',
       be2: 'Practical lessons that you can implement right away.',
       be3: 'Designed for entrepreneurs and small business owners.',
       be4: 'It helps you build financial confidence.',
       be5: 'Avoid costly mistakes that can drain your business.',
       be6: 'Gain Lifetime Value.',
       whojoin1: 'Entrepreneurs',
       whojoin2: 'Start-up owners',
       whojoin3: 'Self-Employed',
       whojoin4: 'Traders',
       whojoin5: 'Manufacturers',
       whyjoin1: 'Expert-led courses',
       whyjoin2: '24/7 Support Services',
       whyjoin3: 'Lifetime Assistance',
       whyjoin4: 'Earn valuable credentials',
       whyjoin5: 'Risk-Free Enrollment',
       iimg: iimg12,
       price:'₹2,999'

   }
]


export default Services;
