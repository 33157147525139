import React from 'react'
import iimg1 from '../../images/icon/UnderstandingtheClient.png' 
import iimg2 from '../../images/icon/DiscussingAboutIt.png' 
import iimg3 from '../../images/icon/Implimentation.png' 


const WorkProcess = (props) => {
    return (
        <section className="work-process-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div className="section-title">
                            <span>Our work process</span>
                            <h3>Every great result begins with understanding. We listen to your issues, plan intelligent solutions, and make things happen—simple ideas, real solutions, and meaningful results.</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col col-lg-10 offset-lg-1">
                        <div className="work-process-grids clearfix">
                            <div className="grid">
                                <div className="icon">
                                    {/* <i className="fi flaticon-idea"></i> */}
                                    <img src={iimg1} />
                                </div>
                                <h3>01. Understanding the Client</h3>
                                <p>We dig deep to understand your business its strengths, challenges,
                                    </p><p>and potential. </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    {/* <i className="fi flaticon-diamond"></i> */}
                                    <img src={iimg2} />
                                </div>
                                <h3>02. Discussing About It</h3>
                                <p>Clear communication bridges the gap between ideas and action and leads to better solutions. </p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    {/* <i className="fi flaticon-sheriff"></i> */}
                                    <img src={iimg3} />
                                </div>
                                <h3>03. Implementation</h3>
                                <p>We work closely with you to adapt, adjust, and deliver tangible, lasting results.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WorkProcess;