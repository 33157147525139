import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";
import AdvertiseForm from "./AdvertiseForm.js";
import PartnerSectionS2 from "../../components/PartnerSection2/PartnerSectionS2";
import { Helmet } from "react-helmet";
const AdvertiseWith = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Advertise with Us</title>
      </Helmet>
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      <PageTitle
        pageTitle={"Advertise With Us"}
        pagesub={"Advertise With Us"}
      />
      <section className="wpo-terms-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="wpo-terms-wrap">
                <div className="wpo-terms-img">
                  {/* <img src={tImg1} alt=""/> */}
                </div>
                <div className="wpo-terms-text">
                  <h3>Advertise With Us – Let’s Grow Together!</h3>
                  <p>
                    Welcome to Rahul Malodia’s World, where businesses and
                    people connect, learn, and grow together. With a massive
                    community of 1.35 crore loyal followers, we’re proud to be
                    one of India’s most prominent business and finance channels.
                  </p>
                  <p>
                    Across platforms like YouTube, Facebook, and Instagram,
                    we’ve built a space where your brand can shine and connect
                    with millions of engaged viewers. Whether you're a startup,
                    MSME, or a global brand, this is where your story can reach
                    the right audience.
                  </p>
                  <p>
                    We’ve worked with some of the biggest and most trusted
                    brands, bringing them closer to their target audience
                    through meaningful content that resonates. With our proven
                    expertise in simplifying business concepts, we transform
                    challenging ideas into easy-to-understand and inspiring
                    stories.
                  </p>
                  <p>
                    Through case studies, videos, reels, and more, we help you
                    create a connection beyond just a promotion. It’s about
                    building trust.
                  </p>
                </div>
                <div>
                  <h4 class="mb-3 mt-5">Why Advertise With Us?</h4>
                  <p>
                    Here’s what makes us the best place for your brand to grow:
                  </p>
                  <ol class="why-us">
                    <li>
                      <h5>A Community That Cares:</h5>
                      <ul>
                        <li>
                          We’re not just about numbers (but yes, they’re BIG!).
                        </li>
                        <li>6.19 million on YouTube.</li>
                        <li>5.3 million on Facebook.</li>
                        <li>
                          1.5 million on Instagram—that’s 1.4 crore followers
                          who are entrepreneurs, business owners, and motivated
                          learners.
                        </li>
                      </ul>
                      <p>
                        This audience actively seeks ways to grow, improve, and
                        invest in solutions.
                      </p>
                    </li>
                    <li>
                      <h5>Monthly Reach You Can Count On:</h5>
                      <ul>
                        <li>
                          With 100 million views monthly, your brand will be in
                          front of an attentive, action-driven audience ready to
                          explore what you offer.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <h5>Multiple Promotion Options:</h5>
                      <ul>
                        <li>
                          Case Studies: We dive deep into your business, its
                          growth story, and strategies, presenting them as
                          inspiring lessons for audiences.
                        </li>
                        <li>
                          Integrated Videos: Your brand can seamlessly fit into
                          our regular content, reaching users naturally and
                          memorably.
                        </li>
                        <li>
                          Dedicated Videos: Want to tell your brand’s full
                          story? We create a video all about you.
                        </li>
                        <li>
                          Reels and Shorts: Short, impactful videos for
                          real-time engagement and fast sharing.
                        </li>
                        <li>
                          Static Posts: Perfect for brand visibility that keeps
                          your name in the spotlight.
                        </li>
                      </ul>
                    </li>
                  </ol>
                  <p>
                    From storytelling to quick bites of content—whatever your
                    business needs, we have a workable format.
                  </p>
                </div>
                <div className="why-us">
                  <h4 class="mb-3 mt-5">
                    Some Great Brands We’ve Partnered With
                  </h4>
                  <p>
                    Working with amazing brands is something we’re proud of.
                    We’ve collaborated with companies across e-commerce, tech,
                    finance, and more industries. Here are a few of them:
                  </p>
                  <ul class="tc-list">
                    <li>Zoho</li>
                    <li>Adani</li>
                    <li>Policy bazaar</li>
                    <li>Vi</li>
                    <li>Paisa bazaar</li>
                    <li>Oddo</li>
                  </ul>
                  <p>
                    With every partnership, our aim stays the same: creating
                    meaningful content that benefits both the brand and the
                    audience. When you advertise with us, it’s not just about
                    putting up an ad—it’s about creating an impact.
                  </p>
                </div>
                <div>
                  <h4 class="mb-3 mt-5">What Makes Us Different?</h4>
                  <p>
                    At Team Rahul Malodia, we don’t just promote businesses—we
                    tell stories. Our audience trusts us because we create
                    honest, relatable, and useful content. We know what clicks
                    with people, so your brand will feel like a natural part of
                    their journey.
                  </p>
                  <p className="mb-0">
                    With us, your message will reach viewers, be understood,
                    remembered, and acted upon.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PartnerSectionS2 />
      <section className="mb-5">
      <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
        <div>
          <h4 class="mb-3 mt-5">How Can You Start?</h4>
          <p>
            It’s as simple as saying, “Let’s do this!” Drop us a message, and
            we’ll help you find the best way to promote your brand on one of
            India’s biggest business platforms.
          </p>
          <p className="fw-bold text-black">
            📧 Email: <a href="mailto:collabs@rahulmalodia.com" className="text-black">collabs@rahulmalodia.com</a>
          </p>
          <p className="fw-bold text-black">
            📞 Phone: <a href="tel:9257029352" className="text-black">+91 92570-29352</a>
          </p>
          <p>
            Bring your brand to life with us. Let’s create something incredible
            together.
          </p>
          <AdvertiseForm />
        </div>
        </div>
        </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AdvertiseWith;
