/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import "../_css/style.css";
import Logo from "../../../images/logo.png";
import DainikBhaskar from "../_img/featuredin/DainikBhaskar.png";
import TimesofIndia from "../_img/featuredin/TimesofIndia.png";
import CNBC from "../_img/featuredin/CNBC.png";
import DNA from "../_img/featuredin/DNA.png";
import EconimocTimes from "../_img/featuredin/EconimocTimes.png";
import HindustanTimes from "../_img/featuredin/HindustanTimes.png";
import IndiaToday from "../_img/featuredin/IndiaToday.png";
import Outlook from "../_img/featuredin/Outlook.png";
import Patrika01 from "../_img/featuredin/Patrika01.png";
import A1TV from "../_img/featuredin/A1TV.png";
import AsiaOne from "../_img/featuredin/AsiaOne.png";
import DainikNavjyoiti from "../_img/featuredin/DainikNavjyoiti.png";
import JanTV from "../_img/featuredin/JanTV.png";
import SamacharJagat from "../_img/featuredin/SamacharJagat.png";
import Patrika from "../_img/featuredin/Patrika.png";
import ZeeRajasthan from "../_img/featuredin/ZeeRajasthan.png";
import WillfredCollegsGroup from "../_img/featuredin/St.WillfredCollegsGroup.png";
import som1 from "../_img/som-1.png";
import som2 from "../_img/som-2.png";
import som3 from "../_img/som-3.png";
import icon1 from "../_img/icons/1.png";
import icon2 from "../_img/icons/2.png";
import icon3 from "../_img/icons/3.png";
import icons11 from "../_img/icons/11.png";
import icons22 from "../_img/icons/22.png";
import icons33 from "../_img/icons/33.png";
import icons44 from "../_img/icons/44.png";
import cta from "../_img/cta-bg.jpg";
import about from "../_img/about.png";
import testimonials1 from "../_img/testimonials/testimonials-1.jpeg";
import testimonials2 from "../_img/testimonials/testimonials-2.jpeg";
import testimonials3 from "../_img/testimonials/testimonials-3.jpg";
import testimonials4 from "../_img/testimonials/testimonials-4.png";
import testimonials5 from "../_img/testimonials/testimonials-5.jpg";
import Slider from "react-slick";
import {
  Check2Circle,
  Exclamation,
  XCircle,
  StarFill,
  Linkedin,
  Telegram,
  TwitterX,
  Instagram,
  Facebook,
  Youtube,
  QuestionCircle,
} from "react-bootstrap-icons";
import { somUrl, startCountdown } from "../../../components/common";

const settings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024, // Screen width of 1024px or smaller
      settings: {
        slidesToShow: 5, // Show 5 slides
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // Screen width of 768px or smaller
      settings: {
        slidesToShow: 4, // Show 4 slides
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480, // Screen width of 480px or smaller
      settings: {
        slidesToShow: 3, // Show 3 slides
        slidesToScroll: 1,
      },
    },
  ],
};
const testimonialsettings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};
const SecretsOfMoney = () => {
  const [countDown, setCountDown] = useState("");

  useEffect(() => {
    const interval = startCountdown(120, setCountDown);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="index-page">
      <header id="header" class="header d-flex align-items-center fixed-top">
        <div class="container-fluid container-xl position-relative d-flex align-items-center header-menus">
          <a
            href="https://rahulmalodia.com"
            class="logo d-flex align-items-center me-md-auto"
          >
            <img src={Logo} alt="Logo" />
          </a>
          <div
            class="hero p-0 d-block"
            style={{
              minHeight: "fit-content",
              minWidth: "fit-content",
              width: "fit-content",
            }}
          >
            <a
              href={somUrl}
              class="btn btn-get-started access-form"
              id="access-form"
            >
              Access Now @Just ₹1,999
            </a>
          </div>
          <nav id="navmenu" class="navmenu">
            <i class="mobile-nav-toggle d-none bi bi-list"></i>
          </nav>
        </div>
      </header>
      <main class="main">
        <section id="hero" class="hero section dark-background">
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-12 order-2 order-lg-1 d-flex flex-column justify-content-center text-center"
                data-aos="zoom-out"
              >
                <h5 class="mb-3 ">
                  <em>
                    Attention: People who are stuck in the cycle of endless
                    Loans, EMIs and mediocre lives
                  </em>
                </h5>
                <h2 class="mb-3 fw-bold">
                  Uncover the Hidden Gems of Personal Finance & Wealth Creation
                  that Schools, Colleges & Society Failed to Teach You WITHOUT
                  <em>"spending a fortune or wasting precious years"</em> on
                  books or writing exams.
                </h2>
                {/* <!-- <p> Time: 10:00 AM – 01:00 PM | Sunday, 08<sup>th</sup> December</p> --> */}
                <div class="d-flex  justify-content-center">
                  <a
                    href={somUrl}
                    id="access-form"
                    class="btn-get-started access-form"
                  >
                    Access Now @Just ₹1,999
                  </a>
                </div>
              </div>
              <div
                class="col-lg-12  hero-img "
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                {/* <!-- <img src="assets/img/hero-img.png" class="img-fluid animated" alt=""> --> */}
                <iframe
                  src="https://player.vimeo.com/video/834371509?color&autopause=0&dnt=true&loop=1&muted=0&title=1&portrait=1&byline=1&h=c24de7cf11#t="
                  frameborder="0"
                  width="100%"
                  height="359px"
                  //   style="border-radius: 10px;"
                  style={{ borderRadius: "10px" }}
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section id="clients" class="clients section light-background">
          <div class="container" data-aos="zoom-in">
            <div class="swiper init-swiper">
              <Slider {...settings}>
                <div className="swiper-slide">
                  <img
                    src={DainikBhaskar}
                    className="img-fluid"
                    alt="Dainik Bhaskar"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={TimesofIndia}
                    className="img-fluid"
                    alt="Times of India"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={CNBC} className="img-fluid" alt="CNBC" />
                </div>
                <div className="swiper-slide">
                  <img src={DNA} className="img-fluid" alt="DNA" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={EconimocTimes}
                    className="img-fluid"
                    alt="Economic Times"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={HindustanTimes}
                    className="img-fluid"
                    alt="Hindustan Times"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={IndiaToday}
                    className="img-fluid"
                    alt="India Today"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={Outlook} className="img-fluid" alt="Outlook" />
                </div>
                <div className="swiper-slide">
                  <img src={Patrika01} className="img-fluid" alt="Patrika 01" />
                </div>
                <div className="swiper-slide">
                  <img src={A1TV} className="img-fluid" alt="A1 TV" />
                </div>
                <div className="swiper-slide">
                  <img src={AsiaOne} className="img-fluid" alt="Asia One" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={DainikNavjyoiti}
                    className="img-fluid"
                    alt="Dainik Navjyoiti"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={JanTV} className="img-fluid" alt="Jan TV" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={SamacharJagat}
                    className="img-fluid"
                    alt="Samachar Jagat"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={Patrika} className="img-fluid" alt="Patrika" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={ZeeRajasthan}
                    className="img-fluid"
                    alt="Zee Rajasthan"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={WillfredCollegsGroup}
                    className="img-fluid"
                    alt="Wilfred College Group"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        <section id="about" class="about section ">
          <div class="container section-title" data-aos="fade-up">
            <h2>
              They are exactly like you, who are also working for a life which
              has greater financial stability & prosperity, but now here is the
              problem…
            </h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-8 mx-auto content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ul>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      You are HOPING that your next generation will give you a
                      return on investment.
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      You have realised working harder, trying to save more or
                      “that next promotion will solve my problems” is not the
                      way to get out of the trap
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      You have always been taught that talking about money is
                      considered bad and taboo
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      You have no idea how to improve your LOVE-HATE
                      relationship with money as NOBODY in your circle is
                      WEALTHY
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section light-background">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="fst-italic">AND BECAUSE OF THIS,</p>
                <h3>
                  You are STUCK in a race which does not seem to end in this
                  lifetime.
                </h3>
                <p class="fw-bold fs-5">
                  This is exactly why the Rich get Richer & the Poor get Poorer.
                </p>
                <p class="">
                  Consider this, an average person would work for less than 42
                  Years (22-64 years).
                </p>
                <p>
                  However, their expenses can tally up to at least 60 years
                  (with the average lifespan being 75 years)
                </p>
                <p>
                  That is a difference of at least 25 years where you will not
                  EARN a single rupee but your expenses will still exist .
                </p>
                <p>
                  And let’s not forget, your “expenses” will be for not one but
                  at least 3 people. (5 to 6 if you have to take care of your
                  parents, siblings, spouse & your kids)
                </p>
                <h5>That’s over 160 in expense years.</h5>
                <p>
                  <i>Is there really a way out of this madness?</i>
                </p>
                <div class="d-flex">
                  <a
                    class="btn btn-theme align-middle access-form"
                    href={somUrl}
                    id="access-form"
                  >
                    Access Now @Just ₹1,999
                  </a>
                </div>
              </div>
              <div class="col-lg-5 d-flex align-items-center">
                <img src={som1} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section id="services" class="services section">
          <div class="container section-title" data-aos="fade-up">
            <h2>What if there was a proven way to</h2>
          </div>
          <div class="container">
            <div class="row gy-4 justify-content-center">
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icon1} alt="" />
                  </div>
                  <h4>
                    <a
                      href={somUrl}
                      class="stretched-link"
                    >
                      Be financially Free
                    </a>
                  </h4>
                  <p>By no longer selling your time for money</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icon2} alt="" />
                  </div>
                  <h4>
                    <a
                      href={somUrl}
                      class="stretched-link"
                    >
                      Get Respect
                    </a>
                  </h4>
                  <p>From your family and friends that you deserve</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icon3} alt="" />
                  </div>
                  <h4>
                    <a
                      href={somUrl}
                      class="stretched-link"
                    >
                      Live a Dream Life
                    </a>
                  </h4>
                  <p>
                    Where you are the architect & not your boss or the bank.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Now I want you to Imagine a life</h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-8 mx-auto content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Where prosperity is your second nature and you are
                      attracting wealth all the time
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      You are no longer a slave to money, in fact, it is just a
                      tool for your needs & desires
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Your relationship with money & people has healed & your
                      systematic wealth plan is working for you.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      You define the meaning of success for yourself & are
                      detached from the standards set by society
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      You no longer are stuck in endless debt or a minimal
                      saving cycle of money.
                    </span>
                  </li>
                </ul>
                <div class="d-flex justify-content-center">
                  <a
                    class="btn btn-theme align-middle access-form"
                    id="access-form"
                    href={somUrl}
                  >
                    Access Now @Just ₹1,999
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-5 d-flex align-items-center">
                <img src={som3} class="img-fluid" alt="" />
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="fst-italic">
                  Now after a lot of research, trial & experience, I have made
                  it possible for you...
                </p>
                <h3>Introducing: Secrets of Money</h3>
                <p class="fw-bold fs-5">
                  The Most Practical & Simplest Course On How You Can Fix Your
                  Finances, Create Wealth And Retire Early
                </p>
                <p class="">
                  Without worrying about endless inflation, or unaccounted
                  expenses.
                </p>
                <p class="">We will learn about:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>Myths & Traps around Money</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Increasing Earning Potential & Wealth</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Practical Calculations & Strategies</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Investing that grows</span>
                  </li>
                </ul>
                <div class="d-flex">
                  <a
                    class="btn btn-theme align-middle access-form"
                    href={somUrl}
                    id="access-form"
                  >
                    Access Now @Just ₹1,999
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          class="call-to-action  section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Offer Expires In</h3>
                <ul class="offer-time">
                  <li>
                    <span id="hours">{countDown.hours}</span>Hours
                  </li>
                  <li>
                    <span id="minutes">{countDown.minutes}</span>Minutes
                  </li>
                  <li>
                    <span id="seconds">{countDown.seconds}</span>Seconds
                  </li>
                </ul>
                {/* <!-- <p> Time: 10:00 AM – 01:00 PM | Sunday, 08<sup>th</sup> December</p> -->
            <!-- <p>Limited Seats, Filling Fast...</p> --> */}
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={somUrl}
                >
                  Access Now @Just ₹1,999
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="services" class="services section">
          <div class="container section-title" data-aos="fade-up">
            <h2>What if there was a proven way to</h2>
          </div>
          <div class="container">
            <div class="row gy-4 justify-content-center">
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="service-item position-relative">
                  <div class="landing-icon">
                    <img src={icons11} alt="" />
                  </div>
                  <h4>
                    <a
                      href={somUrl}
                      class="stretched-link"
                    >
                      10+ Hours
                    </a>
                  </h4>
                  <p>Premium Content</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="service-item position-relative">
                  <div class="landing-icon">
                    <img src={icons22} alt="" />
                  </div>
                  <h4>
                    <a
                      href={somUrl}
                      class="stretched-link"
                    >
                      हिंदी + Eng
                    </a>
                  </h4>
                  <p>Language</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="service-item position-relative">
                  <div class="landing-icon">
                    <img src={icons33} alt="" />
                  </div>
                  <h4>
                    <a
                      href={somUrl}
                      class="stretched-link"
                    >
                      25000+
                    </a>
                  </h4>
                  <p>Happy Students</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="service-item position-relative">
                  <div class="landing-icon">
                    <img src={icons44} alt="" />
                  </div>
                  <h4>
                    <a
                      href={somUrl}
                      class="stretched-link"
                    >
                      Expert
                    </a>
                  </h4>
                  <p>Guidance & Coaching</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Now I want you to Imagine a life</h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-10 mx-auto content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ul>
                  <li>
                    <XCircle size={20} />
                    <span>
                      People who want to be wealthy without putting any effort
                      or following any financial discipline.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      People who are looking for a course that promises
                      overnight success or guarantees a fixed return on
                      investment.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      People who are happy and fully satisfied with their
                      balance sheet that has only cash-producing assets.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      People who do not have a learning attitude and are not
                      coachable or ready to make a change in their lives.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      People who think wanting money is greedy and we should not
                      discuss finances
                    </span>
                  </li>
                </ul>
                <div class="d-flex justify-content-center">
                  <a
                    class="btn btn-theme align-middle access-form"
                    id="access-form"
                    href={somUrl}
                  >
                    Access Now @Just ₹1,999
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Here is everything you will learn inside the program</h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-6 content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3 class="">Module 1</h3>
                <h4 class="mb-4 fw-bold">UNTOLD SECRETS OF MONEY</h4>
                <p>In this module we will:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>Uncover the Truth behind Money Myths</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Reveal the Root Cause of these Myths</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Learn the Real Role of Money in Your Life</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Calculate the Exact Amount You Need to Live Comfortably
                      (FIRE Amount)
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Affirmations that open the Flood Gates to Wealth
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Master the 3 Simple Steps to Wealth Creation</span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="">Module 2</h3>
                <h4 class="mb-4 fw-bold">SECRETS OF EARNING</h4>
                <p>In this module we will learn:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>The Secret Sauce of Printing Money </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Exposing people’s desire to ”Impatient and Instant want
                      for Wealth”
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Mistakes that cap your Money Earning Potential Forever
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      The Framework to Skyrocket your Earning Capacity
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      The Entrepreneurial Mindset to Increase Your Earnings
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="">Module 3</h3>
                <h4 class="mb-4 fw-bold">THE REAL WORLD SITUATION</h4>
                <p>In this module we will learn:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span> The Real Relation between saving and investing</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      The Little Known Finite Savings Potential Principal
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>The Trap behind “Quick Ways to 💵”</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Are Luxury Brands keeping you POOR?</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Self Interest Matrix the only answer to “Should you lend
                      money to friends/family
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>The CORRECT Time to buy a Car &amp; Home</span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="">Module 4</h3>
                <h4 class="mb-4 fw-bold">INVESTMENT MASTERY</h4>
                <p class="">In this module we will learn:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>Real Goal of “Investing”</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Wealth Destroying mistakes while Investing.</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Is Gold Worth Investing in 2023?</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Is Real Estate as an investment dead?</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Is Stock Market the only investment option left?
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section light-background">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-5 d-flex align-items-center">
                <img src={about} class="img-fluid" alt="" />
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="mb-0">MEET YOUR MENTOR</p>
                <h3>CA Rahul Malodia</h3>
                <p class="fst-italic">
                  CEO - Malodia Business Coaching Pvt Ltd
                </p>
                <p>
                  As a business catalyst, I am on a mission to ignite a
                  revolution in India that helps businesses grow, expand and
                  stand out in global markets.
                </p>
                <p>
                  I obtained my CA degree in 2012 after completing my Commerce
                  graduation from the esteemed University of Rajasthan.
                </p>
                <p>
                  With a decade of experience as a Business Consultant and
                  working with reputable firms such as Mahajan & Aibara, I have
                  gained exposure to the professional environment and corporate
                  culture.
                </p>
                <p>
                  My work is characterized by exceptional standards, unwavering
                  accountability, and a results-driven approach.
                </p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>15 Years+ Experience As A Chartered Accountant.</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Worked As Management Consultant For Top Corporates In
                      Mumbai.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Currently Consulting 500+ Businesses With Turnover
                      Exceeding 50Cr+
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials" class="testimonials section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Testimonials</h2>
          </div>
          <div className="container">
            <div className="init-swiper">
              <div className="">
                <Slider {...testimonialsettings}>
                  <div className="testimonial-item">
                    <img src={testimonials1} class="testimonial-img" alt="" />
                    <h3>Er. Harpreet singh</h3>
                    <h4>Director - Paul Hyundai Amritsar</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        BUSINESS FREEDOM COACHING (BFC) is a wonderful practical
                        course - both personally and professionally. I will take
                        away lots of things that I can quickly and easily apply.
                        Thank you Mr. Rahul Malodia for a great course. Great
                        presentation style with lots of opportunities to ask
                        questions and talk about real life examples which all
                        made for a really enjoyable and informative course.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials2} class="testimonial-img" alt="" />
                    <h3>Vivek Tripathi</h3>
                    <h4>Director: Satpura Infracon Pvt Ltd</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        What Lord Krishna was to Arjun so has been Rahul malodia
                        for me. He has made my vision crystal clear which I
                        would have never been able to do without his constant
                        help. He has the potential to understand any business
                        structure in a comprehensive way and his analysis plan
                        for my business has already started giving me fruitful
                        results. Under his able guidance I'm pretty sure to take
                        my business to new heights because now I have a complete
                        blueprint of my business growth plan with all minute
                        details.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials3} class="testimonial-img" alt="" />
                    <h3>Ashutosh Agrawal</h3>
                    <h4>CEO - Tesca Technologies</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        My sincere gratitude to Mr. Rahul Malodiya whom I met
                        first at BNI Conference in Jaipur & after that it has
                        been a wonderful ride for myself & Tesca Technologies
                        Pvt. Ltd. Right from setting up my Affirmations &
                        Gratitude, Mission, Vision, SOP's, JD, KRA, KPI,
                        Checklists my whole processes are system driven and with
                        this I have become more organized, humble and empathetic
                        with my employees & customers. From the bottom of my
                        heart I am really obliged to Rahul ji for his
                        extraordinary efforts for my business.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials4} class="testimonial-img" alt="" />
                    <h3>Sameer Zapke</h3>
                    <h4>I.F. Engineering, Pune</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        The affirmations taught in Rahul Sir’s class gave me
                        hope and my vision became more clear concerning my
                        business. He not only changed my present but also my
                        future. My business is running extremely well and I am
                        not worried about the future. The detailing class on
                        vision boards and affirmations has been very helpful in
                        growing my business. Thank you Rahul sir.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials5} class="testimonial-img" alt="" />
                    <h3>Mukesh Aggarwal</h3>
                    <h4>Owner: New Anand Sagar</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        I have attended Rahul Malodia (CA, Business Coach)
                        masterclass, and then joined his Advanced Class and
                        lastly I have joined and continued his Bootcamp (Jab tak
                        todenge, success tak pahuchenge nahi tab tak chodenge
                        nahi). He has provided and still provides valuable
                        advice, contents, and support to help our business and
                        our employees make a major transition. I highly
                        recommend him.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          class="call-to-action section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Book Your Spot Now</h3>
                <p>
                  If you are any of them then you cannot afford to lose this
                  opportunity because you need to know the secrets of business
                  success to get out of the day to day hustle.
                </p>
                {/* <!-- <p> Time: 10:00 AM – 01:00 PM | Sunday, 08<sup>th</sup> December</p> --> */}
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={somUrl}
                >
                  Access Now @Just ₹1,999
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section options-section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Now you have 2 Options From here</h2>
          </div>
          <div class="container">
            <div class="row gy-4 justify-content-center gap-4">
              <div
                class="col-lg-5 content content-new"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3 class="">Go Solo and</h3>
                <ul>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Continue to stay where you are, struggling and wandering
                      how to build wealth.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Continue consuming FREE content in hope of it gets you
                      where you want to be.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Listen to random GURUS who have no idea what they are
                      teaching & only do it for the views & sponsorships.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Wander around in FREE groups and communities and fall for
                      the “21 din me paisa double” schemes and lose your
                      hard-earned money.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Believe in the lie that society has taught you that you
                      can not be wealthy.
                    </span>
                  </li>
                </ul>
              </div>
              <div
                class="col-lg-5 content-new"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h3 class="">Invest in a Mentor</h3>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Learn what Schools, Colleges and Society FAILED to teach
                      you.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Cut your learning curve by more than half.</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Finally have a game plan to escape the rat race.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Learn from my mistakes and achieve your goals faster.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Get proper guidance and support from a mentor who has
                      already achieved the goal.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Be the first millionaire in your family and change the
                      entire lives of the upcoming generations.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Be a step closer to Achieving the status you and your
                      family deserve.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-12 mt-5  text-center">
              <a
                class="btn btn-theme align-middle mx-0 access-form"
                id="access-form"
                href={somUrl}
              >
                Access Now @Just ₹1,999
              </a>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section light-background">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="mb-0">Now here is my promise...</p>
                <h3>After Taking this Course</h3>
                <p>
                  Believe me when I say this - if you implement each and every
                  aspect that you learn in this course, you will be unstoppable
                  in achieving your dream life.
                </p>
                <p>
                  This course will equip you with a rock-solid understanding of
                  money, transforming it from a liability to a valuable tool at
                  your disposal.
                </p>
                <p>
                  You will witness a complete transformation of your psychology
                  towards money, and your confidence will soar.
                </p>
                <p>
                  This course will empower you to earn, manage and grow your
                  money like never before.
                </p>
              </div>
              <div class="col-lg-5 d-flex align-items-center">
                <img src={som2} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          class="call-to-action section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Book Your Spot Now</h3>
                <p>
                  If you are any of them then you cannot afford to lose this
                  opportunity because you need to know the secrets of business
                  success to get out of the day to day hustle.
                </p>
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={somUrl}
                >
                  Access Now @Just ₹1,999
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="faq-2" class="faq-2 section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="faq-container m-0">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <QuestionCircle />
                          Who is this exactly for?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          The course is designed to help anyone who wants to
                          improve their relationship with money and build wealth
                          in their lifetime. It doesn't matter if you have no
                          knowledge about finances or if you have enough
                          knowledge but are still stuck in the rat race, this
                          course is for EVERYONE.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                        >
                          <QuestionCircle />
                          What is the language of the course?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          The entire course is in Hinglish language and the
                          concepts have been broken down and explained in a very
                          simple manner. Anyone who understands basic English
                          can benefit from the course with ease.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                        >
                          <QuestionCircle />
                          How will I get access to the course?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Once you sign up, you will be sent access to a portal
                          where the course has been hosted.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingfour">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          <QuestionCircle />
                          Once you sign up, you will be sent access to a portal
                          where the course has been hosted.
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingfour"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          No, this is a pre-recorded course and the videos are
                          cut into mini-lectures so that you can consume the
                          course at your own pace. You do not need to watch it
                          all in one go.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingfive">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="true"
                          aria-controls="collapseFive"
                        >
                          <QuestionCircle />
                          How is this different?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingfive"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          This course is different because it is not based on
                          bookish or surface-level knowledge. Rahul Malodia has
                          a unique teaching style and the course is structured
                          purely on the basis of experience and practical
                          scenarios in the country.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSix">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="true"
                          aria-controls="collapseSix"
                        >
                          <QuestionCircle />
                          Can I watch this on my phone?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Yes, you can watch this course on any device including
                          your Desktop, Mobile, Tablet, or any other device once
                          you log in inside the course portal. It doesn't matter
                          if you have Windows, Android, or Apple.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" class="services section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Are you ready to transform your financial journey?</h2>
          </div>
          <div class="container">
            <p class="fs-3 text-center">
              Here is the truth, the best time the start investing in yourself
              was maybe years ago, but the next best time is today. You have
              just one life.
            </p>
          </div>
          {/* </div> */}
        </section>
        <div class=" section light-background py-5">
          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-lg-6 mb-4">
                <h4 class="mb-0 fw-bold">
                  GO ALL IN! and Start designing your dream life!
                </h4>
              </div>
              <div>
                <a
                  class="btn btn-theme align-middle mx-0 access-form"
                  id="access-form"
                  href={somUrl}
                >
                  Access Now @Just ₹1,999
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer id="footer" class="footer">
        <div class="footer-newsletter bg-dark">
          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-lg-12">
                <p class="text-white">
                  **We cannot guarantee any specific earnings or income from our
                  product or service. Any results discussed or illustrated are
                  not typical and should not be considered a guarantee of future
                  income or success. The success or failure of each individual
                  may be influenced by a variety of factors including but not
                  limited to skills, experience, dedication and effort. We
                  provide knowledge and guidance based on our experience and
                  expertise. Your success ultimately depends on your own
                  personal effort and dedication ​
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container footer-top ">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-6 footer-about">
              <a
                href={somUrl}
                class="d-flex align-items-center"
              >
                <img src={Logo} alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-3 footer-links">
              <h4>Contact Us</h4>
              <div class="footer-contact">
                <p>
                  Jharkhand Mode, Sankalp Tower, 202 & 203, Queens Rd, Vaishali
                  Nagar, Jaipur, Rajasthan 302012
                </p>
                <p class="mt-3">
                  <strong>Phone:</strong> <span>+91 76500 67000</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>support@rahulmalodia.com</span>
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <h4>Follow Us</h4>
              <div class="social-links d-flex">
                <a
                  target="_blank"
                  href="https://www.youtube.com/@rahulmalodiaofficial"
                >
                  <Youtube />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/RahulMalodiaOfficial/"
                >
                  <Facebook />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/rahulmalodiaofficial/?hl=en"
                >
                  <Instagram />
                </a>
                <a target="_blank" href="https://x.com/carahulmalodia">
                  <TwitterX />
                </a>
                <a target="_blank" href="https://t.me/CArahulmalodiaofficial">
                  <Telegram />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/rahul-malodia-5ab2138a/"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright text-center mt-4">
          <p>
            <span>Copyright © 2024</span>{" "}
            <a href="rahulmalodia.com">
              <strong class="px-1 sitename">rahulmalodia.com</strong>
            </a>{" "}
            <span>All Rights Reserved</span>
          </p>
        </div>
      </footer>
    </div>
  );
};
export default SecretsOfMoney;
