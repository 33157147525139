import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";
import { Helmet } from "react-helmet";
const RefundPolicyPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Refund Policy</title>
      </Helmet>
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      <PageTitle pageTitle={"Refund Policy"} pagesub={"Refund Policy"} />
      <section className="wpo-terms-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="wpo-terms-wrap">
                <div className="wpo-terms-img">
                </div>
                <div className="wpo-terms-text">
                  <h2>Malodia Business Coaching Refund Policy</h2>
                  <h4 class="mb-3 mt-5">Introduction</h4>
                  <p>
                    At Malodia Business Coaching, we strive to provide
                    exceptional service and support to our valued customers. We
                    understand that sometimes circumstances change, and you may
                    need to request a refund. This policy outlines our refund
                    procedures in compliance with applicable Indian laws and
                    regulations.
                  </p>
                  <h4 class="mb-3 mt-5">Contact Information</h4>
                  <p>
                    For any inquiries regarding this refund policy or to
                    initiate a refund request, please contact us:
                  </p>
                  <p>Email: support@rahulmalodia.com</p>
                  <p>Phone: +91 76500 67000</p>
                  <p>Refund Eligibility Criteria</p>
                  <h4 class="mb-3 mt-5">Satisfaction with the First Session</h4>
                  <p>
                    If you are not satisfied with the first session of any of
                    our classes or courses, you may request a full refund. Once
                    approved, the refund will be processed within 7 working
                    days.
                  </p>
                  <h4 class="mb-3 mt-5">Refunds for Course Purchases</h4>
                  <p>
                    If you purchase a course and choose to discontinue after
                    watching only one video, you are eligible for a full refund.
                  </p>
                  <p>
                    If you have viewed more than one video, we will consider
                    that you have engaged with the course content, and
                    therefore, a refund will not be issued.
                  </p>
                  <p>
                    Refund requests for course purchases must be made within 7
                    days from the payment date or 2 days from the session date.
                  </p>
                  <p>
                    All refund requests will be reviewed, and once approved,
                    refunds will be processed within 7 working days.
                  </p>
                  <p>
                    Course Repeats: You can repeat the course only until the
                    next two sessions; after that, you will not be eligible to
                    repeat.
                  </p>
                  <h4 class="mb-3 mt-5">Access Upon Refund Approval</h4>
                  <p>
                    Upon approval of your refund request, all your accesses to
                    course materials and sessions will be blocked immediately.
                  </p>
                  <h4 class="mb-3 mt-5">Additional Support and Resources</h4>
                  <p>
                    While we are committed to ensuring your satisfaction, we
                    also provide extensive support to enhance your learning
                    experience.
                  </p>
                  <h4 class="mb-3 mt-5">Handholding Support</h4>
                  <p>
                    You can always seek personalized assistance to help you
                    navigate the course material.
                  </p>
                  <h4 class="mb-3 mt-5">Conclusion</h4>
                  <p>
                    We appreciate your trust in Malodia Business Coaching and
                    are dedicated to helping you achieve your business goals. If
                    you have any questions or require further clarification
                    regarding our refund policy, please do not hesitate to reach
                    out to us.
                  </p>
                  <p>
                    Thank you for choosing Malodia Business Coaching. We look
                    forward to supporting your journey!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default RefundPolicyPage;
