import React, { useEffect, useState } from "react";
import "../_css/style.css";
import Logo from "../../../images/logo.png";
import DainikBhaskar from "../_img/featuredin/DainikBhaskar.png";
import TimesofIndia from "../_img/featuredin/TimesofIndia.png";
import CNBC from "../_img/featuredin/CNBC.png";
import DNA from "../_img/featuredin/DNA.png";
import EconimocTimes from "../_img/featuredin/EconimocTimes.png";
import HindustanTimes from "../_img/featuredin/HindustanTimes.png";
import IndiaToday from "../_img/featuredin/IndiaToday.png";
import Outlook from "../_img/featuredin/Outlook.png";
import Patrika01 from "../_img/featuredin/Patrika01.png";
import A1TV from "../_img/featuredin/A1TV.png";
import AsiaOne from "../_img/featuredin/AsiaOne.png";
import DainikNavjyoiti from "../_img/featuredin/DainikNavjyoiti.png";
import JanTV from "../_img/featuredin/JanTV.png";
import SamacharJagat from "../_img/featuredin/SamacharJagat.png";
import Patrika from "../_img/featuredin/Patrika.png";
import ZeeRajasthan from "../_img/featuredin/ZeeRajasthan.png";
import WillfredCollegsGroup from "../_img/featuredin/St.WillfredCollegsGroup.png";
import som1 from "../_img/som-1.png";
import som2 from "../_img/som-2.png";
import som3 from "../_img/som-3.png";
import icon1 from "../_img/icons/01.png";
import icon2 from "../_img/icons/02.png";
import icon3 from "../_img/icons/03.png";
import icons11 from "../_img/icons/11.png";
import icons22 from "../_img/icons/22.png";
import icons33 from "../_img/icons/33.png";
import icons44 from "../_img/icons/44.png";
import cta from "../_img/cta-bg.jpg";
import about from "../_img/about.png";
import testimonials1 from "../_img/testimonials/testimonials-1.jpeg";
import testimonials2 from "../_img/testimonials/testimonials-2.jpeg";
import testimonials3 from "../_img/testimonials/testimonials-3.jpg";
import testimonials4 from "../_img/testimonials/testimonials-4.png";
import testimonials5 from "../_img/testimonials/testimonials-5.jpg";
import Slider from "react-slick";
import {
  Check2Circle,
  Exclamation,
  XCircle,
  StarFill,
  Linkedin,
  Telegram,
  TwitterX,
  Instagram,
  Facebook,
  Youtube,
  QuestionCircle,
} from "react-bootstrap-icons";
import {
  financeManagementUrl,
  startCountdown,
} from "../../../components/common";
const settings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024, // Screen width of 1024px or smaller
      settings: {
        slidesToShow: 5, // Show 5 slides
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // Screen width of 768px or smaller
      settings: {
        slidesToShow: 4, // Show 4 slides
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480, // Screen width of 480px or smaller
      settings: {
        slidesToShow: 3, // Show 3 slides
        slidesToScroll: 1,
      },
    },
  ],
};
const testimonialsettings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

const FinanceManagement = () => {
  const [countDown, setCountDown] = useState("");

  useEffect(() => {
    const interval = startCountdown(120, setCountDown);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="index-page">
      <header id="header" class="header d-flex align-items-center fixed-top">
        <div class="container-fluid container-xl position-relative d-flex align-items-center header-menus">
          <a
            href="https://rahulmalodia.com"
            class="logo d-flex align-items-center me-md-auto"
          >
            <img src={Logo} alt="Logo" />
          </a>
          <div
            class="hero p-0 d-block"
            style={{
              minHeight: "fit-content",
              minWidth: "fit-content",
              width: "fit-content",
            }}
          >
            <a
              href={financeManagementUrl}
              class="btn btn-get-started access-form"
              id="access-form"
            >
              Access Now
            </a>
          </div>
          <nav id="navmenu" class="navmenu">
            <i class="mobile-nav-toggle d-none bi bi-list"></i>
          </nav>
        </div>
      </header>
      <main class="main">
        <section id="hero" class="hero section dark-background">
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-12 order-2 order-lg-1 d-flex flex-column justify-content-center text-center"
                data-aos="zoom-out"
              >
                <h5 class="mb-3 ">
                  <em>
                    Attention: People who are stuck balancing their balance
                    sheets and adjusting losses.
                  </em>
                </h5>
                <h1 class="mb-3 fw-bold">Master the Art of Finance control</h1>
                <h3 class="mb-3 fw-bold">
                  How to Streamline Your Business Finances Without Overwhelm in
                  as little as 8 Weeks, So You Can Achieve Long-term Wealth.
                </h3>
                <p>
                  Warning: Your Competitor & bank wants this information to be
                  kept Private
                </p>
                <div class="d-flex  justify-content-center">
                  <a
                    href={financeManagementUrl}
                    id="access-form"
                    class="btn-get-started access-form"
                  >
                    Access Now
                  </a>
                </div>
              </div>
              <div
                class="col-lg-12  hero-img "
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <iframe
                  src="https://player.vimeo.com/video/956024770?autoplay=1&color&autopause=0&dnt=true&loop=1&muted=0&title=1&portrait=1&byline=1#t="
                  frameborder="0"
                  width="100%"
                  height="359px"
                  style={{ borderRadius: "10px" }}
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section id="clients" class="clients section light-background">
          <div class="container" data-aos="zoom-in">
            <div class="swiper init-swiper">
              <Slider {...settings}>
                <div className="swiper-slide">
                  <img
                    src={DainikBhaskar}
                    className="img-fluid"
                    alt="Dainik Bhaskar"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={TimesofIndia}
                    className="img-fluid"
                    alt="Times of India"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={CNBC} className="img-fluid" alt="CNBC" />
                </div>
                <div className="swiper-slide">
                  <img src={DNA} className="img-fluid" alt="DNA" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={EconimocTimes}
                    className="img-fluid"
                    alt="Economic Times"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={HindustanTimes}
                    className="img-fluid"
                    alt="Hindustan Times"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={IndiaToday}
                    className="img-fluid"
                    alt="India Today"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={Outlook} className="img-fluid" alt="Outlook" />
                </div>
                <div className="swiper-slide">
                  <img src={Patrika01} className="img-fluid" alt="Patrika 01" />
                </div>
                <div className="swiper-slide">
                  <img src={A1TV} className="img-fluid" alt="A1 TV" />
                </div>
                <div className="swiper-slide">
                  <img src={AsiaOne} className="img-fluid" alt="Asia One" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={DainikNavjyoiti}
                    className="img-fluid"
                    alt="Dainik Navjyoiti"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={JanTV} className="img-fluid" alt="Jan TV" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={SamacharJagat}
                    className="img-fluid"
                    alt="Samachar Jagat"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={Patrika} className="img-fluid" alt="Patrika" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={ZeeRajasthan}
                    className="img-fluid"
                    alt="Zee Rajasthan"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={WillfredCollegsGroup}
                    className="img-fluid"
                    alt="Wilfred College Group"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        <section id="about" class="about section ">
          <div class="container section-title" data-aos="fade-up">
            <h2>Just like these people, does any of this sound familiar?</h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-8 mx-auto content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ul>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      Does the unpredictability of cash flow keep you awake at
                      night, despite decent sales figures?
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      Are complex financial documents making you nervous and not
                      letting you focus on your business?
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      Do you fear that wrong financial decisions could trap your
                      business in bad and massive debt?
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      Does planning your business finances feel like navigating
                      a destination without a google maps?
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      Are you concerned about the uncertainty of the financial
                      legacy you’re building for future generations?
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section light-background">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="fst-italic">AND NOT TO MENTION,</p>
                <h3>I WANT YOU TO DO SOMETHING…</h3>
                <p class="fw-bold fs-5">
                  Imagine, month after month, your business barely surviving,
                  not because you aren’t making sales, but because your
                  financial strategy is a shot in the dark.
                </p>
                <p class="">
                  Each financial misstep not only eats into your profits but
                  risks the entire future of your business.
                </p>
                <p>
                  Without mastering your finances, every decision is a gamble
                  with high stakes, potentially leading your business toward an
                  avoidable downfall.
                </p>
                <p>
                  Now is the time to secure the financial pillar before
                  everything you have ever worked for starts to break down.
                </p>
                <h4>
                  A smart business has massive sales, and a great businessman
                  has strong financials
                </h4>

                <p>
                  It is your report card in the real world, whether you are
                  raising a loan, or extending credit or making investments,
                  banks and every financial institution will first look your
                  statements, then take a meeting with you.
                </p>
                <p>
                  <i>
                    And if your financials are poor, how do you expect to build
                    a business that creates generational wealth?
                  </i>
                </p>
                <div class="d-flex">
                  <a
                    class="btn btn-theme align-middle access-form"
                    href={financeManagementUrl}
                    id="access-form"
                  >
                    Access Now
                  </a>
                </div>
              </div>
              <div class="col-lg-5 d-flex align-items-center">
                <img src={som1} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section id="services" class="services section">
          <div class="container section-title" data-aos="fade-up">
            <h2>What if there was a proven way to</h2>
          </div>
          <div class="container">
            <div class="row gy-4 justify-content-center">
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icon1} alt="" />
                  </div>
                  <h4>
                    <a href={financeManagementUrl} class="stretched-link">
                      Master the Cycle of Wealth
                    </a>
                  </h4>
                  <p>to continuously build and protect your assets</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icon2} alt="" />
                  </div>
                  <h4>
                    <a href={financeManagementUrl} class="stretched-link">
                      Do Insightful Analysis
                    </a>
                  </h4>
                  <p>To transform your balance sheet</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icon3} alt="" />
                  </div>
                  <h4>
                    <a href={financeManagementUrl} class="stretched-link">
                      Master the Cashflow
                    </a>
                  </h4>
                  <p>
                    And design a cash flow system that ensures business thrives
                    in any economic climate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Now I want you to</h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-8 mx-auto content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Where you can navigate business challenges with financial
                      confidence and expertise.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Where your financial decisions not only grow but also
                      protect your business wealth.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Where you have established a legacy of a well-oiled,
                      financially sound enterprise.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Where you have finally achieved peace of mind, knowing
                      your financials are under control.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>knowing</span>
                  </li>
                </ul>
                <div class="d-flex justify-content-center">
                  <a
                    class="btn btn-theme align-middle access-form"
                    id="access-form"
                    href={financeManagementUrl}
                  >
                    Access Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-5 d-flex align-items-center">
                <img src={som3} class="img-fluid" alt="" />
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="fst-italic">
                  Now after a lot of research, trial & experience, I have made
                  it possible for you...
                </p>
                <h3>Introducing: Finance Management mastery</h3>
                <p class="">
                  The Most Practical Course on how to take control of your
                  finances, increase profitability, and eliminate financial
                  confusion.
                </p>
                <p class="">We will learn about:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      The exact steps to interpret balance sheets and P&L
                      statements.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      A powerful system for mastering cash flow and managing
                      working capital.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      How to strategically handle debtors and creditors for
                      seamless transactions.
                    </span>
                  </li>
                </ul>
                <div class="d-flex">
                  <a
                    class="btn btn-theme align-middle access-form"
                    href={financeManagementUrl}
                    id="access-form"
                  >
                    Access Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          class="call-to-action  section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Offer Expires In</h3>
                <ul className="offer-time">
                  <li>
                    <span id="hours">{countDown.hours}</span>Hours
                  </li>
                  <li>
                    <span id="minutes">{countDown.minutes}</span>Minutes
                  </li>
                  <li>
                    <span id="seconds">{countDown.seconds}</span>Seconds
                  </li>
                </ul>
                {/* <!-- <p> Time: 10:00 AM – 01:00 PM | Sunday, 08<sup>th</sup> December</p> -->
            <!-- <p>Limited Seats, Filling Fast...</p> --> */}
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={financeManagementUrl}
                >
                  Access Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="services" class="services section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Why Should You Choose this Course?</h2>
          </div>
          <div class="container">
            <div class="row gy-4 justify-content-center">
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icons11} alt="" />
                  </div>
                  <h4>
                    <a href={financeManagementUrl} class="stretched-link">
                      2+ Hours
                    </a>
                  </h4>
                  <p>Premium Content</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icons22} alt="" />
                  </div>
                  <h4>
                    <a href={financeManagementUrl} class="stretched-link">
                      हिंदी + Eng
                    </a>
                  </h4>
                  <p>Language</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icons33} alt="" />
                  </div>
                  <h4>
                    <a href={financeManagementUrl} class="stretched-link">
                      25000+
                    </a>
                  </h4>
                  <p>Happy Students</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icons44} alt="" />
                  </div>
                  <h4>
                    <a href={financeManagementUrl} class="stretched-link">
                      Expert
                    </a>
                  </h4>
                  <p>Guidance & Coaching</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Who is this not for?</h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-10 mx-auto content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ul>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Business owners who ignore financial data or avoid tough
                      decisions.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Anyone who dismisses the importance of mastering business
                      finance.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      People who are happy and fully satisfied with their
                      business finances and financial growth and management.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      People who think their CA or Accountant will fix their
                      finances overnight.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      People who do not have a learning attitude and are not
                      coachable or ready to make a change in their business.
                    </span>
                  </li>
                </ul>
                <div class="d-flex justify-content-center">
                  <a
                    class="btn btn-theme align-middle access-form"
                    id="access-form"
                    href={financeManagementUrl}
                  >
                    Access Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Here is everything you will learn inside the program</h2>
          </div>
          <div class="container">
            <div
              class="col-lg-12 content mb-5 pb-3"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h3 class="mb-4 fw-bold">
                Here is everything I will teach you in Finance Management
                Mastery
              </h3>
              <ul>
                <li>
                  <Check2Circle size={20} />
                  <span>How Wealth Cycle Functions Works.</span>
                </li>
                <li>
                  <Check2Circle size={20} />
                  <span>What Balance Sheet and P&L Represents.</span>
                </li>
                <li>
                  <Check2Circle size={20} />
                  <span>Cash Flow Statement - Liqudity Management.</span>
                </li>
                <li>
                  <Check2Circle size={20} />
                  <span>Ratios - The Pulse of any Business.</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section light-background">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-5 d-flex align-items-center">
                <img src={about} class="img-fluid" alt="" />
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="mb-0">MEET YOUR MENTOR</p>
                <h3>CA Rahul Malodia</h3>
                <p class="fst-italic">
                  CEO - Malodia Business Coaching Pvt Ltd
                </p>
                <p>
                  As a business catalyst, I am on a mission to ignite a
                  revolution in India that helps businesses grow, expand and
                  stand out in global markets.
                </p>
                <p>
                  I obtained my CA degree in 2012 after completing my Commerce
                  graduation from the esteemed University of Rajasthan.
                </p>
                <p>
                  With a decade of experience as a Business Consultant and
                  working with reputable firms such as Mahajan & Aibara, I have
                  gained exposure to the professional environment and corporate
                  culture.
                </p>
                <p>
                  My work is characterized by exceptional standards, unwavering
                  accountability, and a results-driven approach.
                </p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>15 Years+ Experience As A Chartered Accountant.</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Worked As Management Consultant For Top Corporates In
                      Mumbai.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Currently Consulting 500+ Businesses With Turnover
                      Exceeding 50Cr+
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials" class="testimonials section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Testimonials</h2>
          </div>
          <div className="container">
            <div className="init-swiper">
              <div className="">
                <Slider {...testimonialsettings}>
                  <div className="testimonial-item">
                    <img src={testimonials1} class="testimonial-img" alt="" />
                    <h3>Er. Harpreet singh</h3>
                    <h4>Director - Paul Hyundai Amritsar</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        BUSINESS FREEDOM COACHING (BFC) is a wonderful practical
                        course - both personally and professionally. I will take
                        away lots of things that I can quickly and easily apply.
                        Thank you Mr. Rahul Malodia for a great course. Great
                        presentation style with lots of opportunities to ask
                        questions and talk about real life examples which all
                        made for a really enjoyable and informative course.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials2} class="testimonial-img" alt="" />
                    <h3>Vivek Tripathi</h3>
                    <h4>Director: Satpura Infracon Pvt Ltd</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        What Lord Krishna was to Arjun so has been Rahul malodia
                        for me. He has made my vision crystal clear which I
                        would have never been able to do without his constant
                        help. He has the potential to understand any business
                        structure in a comprehensive way and his analysis plan
                        for my business has already started giving me fruitful
                        results. Under his able guidance I'm pretty sure to take
                        my business to new heights because now I have a complete
                        blueprint of my business growth plan with all minute
                        details.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials3} class="testimonial-img" alt="" />
                    <h3>Ashutosh Agrawal</h3>
                    <h4>CEO - Tesca Technologies</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        My sincere gratitude to Mr. Rahul Malodiya whom I met
                        first at BNI Conference in Jaipur & after that it has
                        been a wonderful ride for myself & Tesca Technologies
                        Pvt. Ltd. Right from setting up my Affirmations &
                        Gratitude, Mission, Vision, SOP's, JD, KRA, KPI,
                        Checklists my whole processes are system driven and with
                        this I have become more organized, humble and empathetic
                        with my employees & customers. From the bottom of my
                        heart I am really obliged to Rahul ji for his
                        extraordinary efforts for my business.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials4} class="testimonial-img" alt="" />
                    <h3>Sameer Zapke</h3>
                    <h4>I.F. Engineering, Pune</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        The affirmations taught in Rahul Sir’s class gave me
                        hope and my vision became more clear concerning my
                        business. He not only changed my present but also my
                        future. My business is running extremely well and I am
                        not worried about the future. The detailing class on
                        vision boards and affirmations has been very helpful in
                        growing my business. Thank you Rahul sir.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials5} class="testimonial-img" alt="" />
                    <h3>Mukesh Aggarwal</h3>
                    <h4>Owner: New Anand Sagar</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        I have attended Rahul Malodia (CA, Business Coach)
                        masterclass, and then joined his Advanced Class and
                        lastly I have joined and continued his Bootcamp (Jab tak
                        todenge, success tak pahuchenge nahi tab tak chodenge
                        nahi). He has provided and still provides valuable
                        advice, contents, and support to help our business and
                        our employees make a major transition. I highly
                        recommend him.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          class="call-to-action section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Book Your Spot Now</h3>
                <p>
                  If you are any of them then you cannot afford to lose this
                  opportunity because you need to know the secrets of business
                  success to get out of the day to day hustle.
                </p>
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={financeManagementUrl}
                >
                  Access Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section options-section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Now you have 2 Options From here</h2>
          </div>
          <div class="container">
            <div class="row gy-4 justify-content-center gap-4">
              <div
                class="col-lg-5 content content-new"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3 class="">Close this page and…</h3>
                <ul>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Continue to stay where you are, struggling and wandering
                      how to fix your business finances.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Continue consuming FREE content in hope of it gets you
                      where you want to be.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Listen to random GURUS who have no idea what they are
                      teaching & only do it for the views & sponsorships.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Wander around in FREE groups and communities and fall for
                      the Quick Fix Financial Management Sheets and lose your
                      hard-earned money and peace.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Try everything on your own to figure out what works and
                      spend years finding the right way to manage and grow
                      finances in your business.
                    </span>
                  </li>
                </ul>
              </div>
              <div
                class="col-lg-5 content-new"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h3 class="">Invest in your growth and…</h3>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>Learn the art of financial management.</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Cut your learning process by more than half.</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Finally have a game plan to double your Financial IQ.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Learn from my mistakes and achieve your goals faster.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Get proper guidance and support from a mentor who has
                      already achieved the goal.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Be the first person in your circle to master the game of
                      business by becoming an expert in business finances.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Be a step closer to Achieving the status you and your
                      family deserve by building your dream business
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-12 mt-5  text-center">
              <a
                class="btn btn-theme align-middle mx-0 access-form"
                id="access-form"
                href={financeManagementUrl}
              >
                Access Now
              </a>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section light-background">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="mb-0">Now here is my promise...</p>
                <h3>After Taking this Course</h3>
                <p>
                  Imagine stepping into a future where you're no longer dealing
                  with financial uncertainty.
                </p>
                <p>
                  In just a few months, you've transformed your business with
                  the skills to manage finances effortlessly.
                </p>
                <p>
                  Picture your cash flows streamlined, your profits climbing,
                  and every financial decision made with pinpoint accuracy.
                </p>
                <p>
                  You've not only secured your business's future but also paved
                  the way for lasting wealth and a business that thrives on its
                  own.
                </p>
                <p>
                  It's a future where financial worries are a distant memory,
                  and you're celebrated for your expert financial leadership.
                </p>
              </div>
              <div class="col-lg-5 d-flex align-items-center">
                <img src={som2} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          class="call-to-action section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Book Your Spot Now</h3>
                <p>
                  If you are any of them then you cannot afford to lose this
                  opportunity because you need to know the secrets of business
                  success to get out of the day to day hustle.
                </p>
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={financeManagementUrl}
                >
                  Access Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="faq-2" class="faq-2 section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="faq-container m-0">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <QuestionCircle />
                          Who is this exactly for?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          This course is created for businessmen, traders,
                          entrepreneurs, managers to help them improve their
                          sales and achieve business goals effectively.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                        >
                          <QuestionCircle />
                          What is the language of the course?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          The entire course is in Hinglish language and the
                          concepts have been broken down and explained in a very
                          simple manner. Anyone who understands basic English
                          and Hindi can easily benefit from the course.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                        >
                          <QuestionCircle />
                          How will I get access to the course?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Once you sign up,you will get access to email where
                          you can set up your password and access the course
                          instantly.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingfour">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          <QuestionCircle />
                          Is this a live course? I don’t have time to attend
                          long lectures.
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingfour"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          No, this is a pre-recorded course and the videos are
                          cut into mini-lectures so that you can consume the
                          course at your own pace. You do not need to watch it
                          all in one go.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingfive">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="true"
                          aria-controls="collapseFive"
                        >
                          <QuestionCircle />
                          How is this different?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingfive"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          This course is designed after years of consulting and
                          coaching thousands of businesses in India to help
                          businesses with hiring frameworks and strategies and
                          increase their business profits.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSix">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="true"
                          aria-controls="collapseSix"
                        >
                          <QuestionCircle />
                          Can I watch this on my phone?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Yes, you can watch this course on any device including
                          your Desktop, Mobile, Tablet, or any other device once
                          you log in inside the course portal. It doesn't matter
                          if you have Windows, Android, or Apple.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSeven">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="true"
                          aria-controls="collapseSeven"
                        >
                          <QuestionCircle />
                          How to get support & ask questions?
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          If you have any doubts or questions, you can reach out
                          on our support email address.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" class="services section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Are you ready to Master Employee & Talent Hiring & ?</h2>
          </div>
          <div class="container">
            <p class="fs-3 text-center">
              Ps, if you read this page till here, you do understand what is
              missing in your business.
            </p>
            <h4 class="fw-bold text-center">
              The Employee Hiring Mastery Course could be the missing piece of
              your puzzle to completely transform your life & business.
            </h4>
            <p class="fs-3 text-center">So here is the question....</p>
            <p class="fs-3 text-center">Are you ready?</p>
            <div className="text-center">
              <a
                class="btn btn-theme align-middle mx-0 access-form"
                id="access-form"
                href={financeManagementUrl}
              >
                Access Now
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer" class="footer">
        <div class="footer-newsletter bg-dark">
          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-lg-10">
                <p class="mb-0">
                  **We cannot guarantee any specific earnings or income from our
                  product or service. Any results discussed or illustrated are
                  not typical and should not be considered a guarantee of future
                  income or success. The success or failure of each individual
                  may be influenced by a variety of factors including but not
                  limited to skills, experience, dedication and effort. We
                  provide knowledge and guidance based on our experience and
                  expertise. Your success ultimately depends on your own
                  personal effort and dedication ​
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container footer-top ">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-6 footer-about">
              <a href={financeManagementUrl} class="d-flex align-items-center">
                <img src={Logo} alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-3 footer-links">
              <h4>Contact Us</h4>
              <div class="footer-contact">
                <p>
                  Jharkhand Mode, Sankalp Tower, 202 & 203, Queens Rd, Vaishali
                  Nagar, Jaipur, Rajasthan 302012
                </p>
                <p class="mt-3">
                  <strong>Phone:</strong> <span>+91 76500 67000</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>support@rahulmalodia.com</span>
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <h4>Follow Us</h4>
              <div class="social-links d-flex">
                <a
                  target="_blank"
                  href="https://www.youtube.com/@rahulmalodiaofficial"
                >
                  <Youtube />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/RahulMalodiaOfficial/"
                >
                  <Facebook />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/rahulmalodiaofficial/?hl=en"
                >
                  <Instagram />
                </a>
                <a target="_blank" href="https://x.com/carahulmalodia">
                  <TwitterX />
                </a>
                <a target="_blank" href="https://t.me/CArahulmalodiaofficial">
                  <Telegram />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/rahul-malodia-5ab2138a/"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright text-center mt-4">
          <p>
            <span>Copyright © 2024</span>{" "}
            <a href="rahulmalodia.com">
              <strong class="px-1 sitename">rahulmalodia.com</strong>
            </a>{" "}
            <span>All Rights Reserved</span>
          </p>
        </div>
      </footer>
    </div>
  );
};
export default FinanceManagement;
