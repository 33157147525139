import React, { Fragment } from 'react'
import AdvanceCourses from './AdvanceCourses'
const ACPage = () => {
  return (
    <Fragment>
        <AdvanceCourses/>
    </Fragment>
  )
}
export default ACPage
