import React, { Fragment } from "react";
import FinaceFundamental from "./FinaceFundamental";

const FinaceFundamentalPage = () => {
  return (
    <Fragment>
      <FinaceFundamental />
    </Fragment>
  );
};
export default FinaceFundamentalPage;
