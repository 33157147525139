import blogImg1 from "../images/blog/img-1.png";
import blogImg2 from "../images/blog/img-2.png";
import blogImg3 from "../images/blog/img-3.png";
import blogSingleImg1 from "../images/blog/img-4.jpg";
import blogSingleImg3 from "../images/blog/img-6.jpg";
import blogSingleImg2 from "../images/blog/img-5.jpg";



const blogs = [ 
    {
        id: '1',
        title: 'Residential CEO World Retreat 5.0 will Completely change your Life, Mindset & Business',
        slug: 'Consulting-Success-is-the-most-comprehensive-learning',
        screens: blogImg1,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Marilou Kelleher',
        authorTitle:'Sineor Consultant',
        create_at: 'Feb 16, 2024',
        create_day: '12',
        create_month: 'Dec',
        create_year: '2024',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'Meet Rahul Malodia live for the first time at Hotel Sahara Star, Vile Parle, Mumbai',
        slug: 'Meet Rahul Malodia live for the first time at Hotel Sahara Star, Vile Parle, Mumbai',
        screens: blogImg2,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Konal Biry',
        authorTitle:'Creative Director',
        create_at: 'March 17, 2024',
        create_day: '17',
        create_month: 'November',
        create_year: '2024',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        blClass:'format-standard-image',
    },
    {
        id: '3',
        title: 'Meet Rahul Malodia live for the first time at Taj Vivanta Dwarka, Delhi',
        slug: 'Proven-advice-on-how-to-grow-your-consulting-practice.',
        screens: blogImg3,
        description: 'Consectetur adipiscing elit. Purusout phasellus malesuada lectus.',
        author: 'Aliza Anny',
        authorTitle:'Art Director',
        create_at: 'Jan 18, 2024',
        create_day: '15',
        create_month: 'September',
        create_year: '2024',
        blogSingleImg:blogSingleImg3,
        comment:'95',
        blClass:'format-video',
    },
];
export default blogs;