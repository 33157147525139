import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/featuredin/DainikBhaskar.png'
import pimg2 from '../../images/featuredin/TimesofIndia.png'
import pimg3 from '../../images/featuredin/CNBC.png'
import pimg4 from '../../images/featuredin/HindustanTimes.png'
import pimg5 from '../../images/featuredin/DNA.png'
import pimg6 from '../../images/featuredin/EconimocTimes.png'
import pimg7 from '../../images/featuredin/IndiaToday.png'
import pimg8 from '../../images/featuredin/Outlook.png'
import pimg9 from '../../images/featuredin/Patrika01.png'
import pimg13 from '../../images/featuredin/DainikNavjyoiti.png'
import pimg10 from '../../images/featuredin/AsiaOne.png'
import pimg11 from '../../images/featuredin/A1TV.png'
import pimg12 from '../../images/featuredin/JanTV.png'
import pimg14 from '../../images/featuredin/SamacharJagat.png'
import pimg15 from '../../images/featuredin/ZeeRajasthan.png'
import pimg16 from '../../images/featuredin/St.WillfredCollegsGroup.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
    dots: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    
};
const PartnerSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <section className="cta-with-partners">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <div className="section-title-s2">
                            <span>Featured in</span>
                            <h2>As Featured In: <br/>Trusted Platforms</h2>
                        </div>
                        <div className="cta-text">
                            <p>Recognized by leading business media houses for excellence in innovation, leadership, and transforming entrepreneurial success across industries.</p>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                    <Slider {...settings}>
                        <div className="partner-grids p-2">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg1} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg2} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg3} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg4} alt=""/>
                                </div>
                            </div>
                        </div>
                      <div className="partner-grids p-2">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg5} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg6} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg7} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg8} alt=""/>
                                </div>
                            </div>
                        </div>
                          <div className="partner-grids p-2">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg9} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg10} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg11} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg12} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="partner-grids p-2">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg13} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg14} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg15} alt=""/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={pimg16} alt=""/>
                                </div>
                            </div>
                        </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;
