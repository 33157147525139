import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";
import { Helmet } from "react-helmet";
const PrivacyPolicyPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      <PageTitle pageTitle={"Privacy Policy"} pagesub={"Privacy Policy"} />
      <section className="wpo-terms-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="wpo-terms-wrap">
                <div className="wpo-terms-img">
                </div>
                <div className="wpo-terms-text">
                  <h2>Malodia Business Coaching Privacy Policy</h2>
                  <p class="">Effective Date: [06.11.2024]</p>
                  <p>
                    At Malodia Business Coaching, we are committed to protecting
                    your privacy. This Privacy Policy outlines how we collect,
                    use, disclose, and safeguard your information when you visit
                    our website or engage with our services.
                  </p>
                  <h4 class="mb-3 mt-5">Information We Collect</h4>
                  <p>
                    We may collect personal information that you provide
                    directly to us, including but not limited to:
                  </p>
                  <p>
                    Contact Information: Name, email address, phone number, and
                    mailing address.
                  </p>
                  <p>
                    Payment Information: Payment details and billing address for
                    transaction processing.
                  </p>
                  <p>
                    Account Information: Username and password for creating an
                    account with us.
                  </p>
                  <p>
                    Usage Data: Information about how you use our website,
                    including your IP address, browser type, and access times.
                  </p>
                  <h4 class="mb-3 mt-5">How We Use Your Information</h4>
                  <p>
                    We may use the information we collect for various purposes,
                    including:
                  </p>
                  <p>To provide and maintain our services.</p>{" "}
                  <p>To process transactions and manage your account.</p>{" "}
                  <p>
                    To communicate with you about your account and respond to
                    inquiries.
                  </p>{" "}
                  <p>
                    To send promotional materials and updates about our services
                    (you can opt out at any time).
                  </p>{" "}
                  <p>
                    To improve our website and services based on user feedback
                    and usage data.
                  </p>{" "}
                  <p>
                    To comply with legal obligations and protect our rights.
                  </p>
                  <h4 class="mb-3 mt-5">Refunds for Course Purchases</h4>
                  <p>
                    If you purchase a course and choose to discontinue after
                    watching only one video, you are eligible for a full refund.
                  </p>
                  <p>
                    If you have viewed more than one video, we will consider
                    that you have engaged with the course content, and
                    therefore, a refund will not be issued.
                  </p>
                  <p>
                    Refund requests for course purchases must be made within 7
                    days from the payment date or 2 days from the session date.
                  </p>
                  <p>
                    All refund requests will be reviewed, and once approved,
                    refunds will be processed within 7 working days.
                  </p>
                  <p>
                    Course Repeats: You can repeat the course only until the
                    next two sessions; after that, you will not be eligible to
                    repeat.
                  </p>
                  <h4 class="mb-3 mt-5">Information Sharing and Disclosure</h4>
                  <p>
                    We do not sell or rent your personal information to third
                    parties. We may share your information in the following
                    circumstances:
                  </p>
                  <p>
                    Service Providers: We may employ third-party companies and
                    individuals to facilitate our services, provide support, and
                    perform related services. These parties have access to your
                    personal information only to perform tasks on our behalf and
                    are obligated to protect your information.
                  </p>
                  <p>
                    Legal Requirements: We may disclose your information if
                    required to do so by law or in response to valid requests by
                    public authorities.
                  </p>
                  <h4 class="mb-3 mt-5">Data Security</h4>
                  <p>
                    We implement reasonable security measures to protect your
                    personal information from unauthorized access, use, or
                    disclosure. However, please be aware that no method of
                    transmission over the internet or method of electronic
                    storage is 100% secure.
                  </p>
                  <h4 class="mb-3 mt-5">Your Rights</h4>
                  <p>
                    You have certain rights regarding your personal information,
                    including:
                  </p>
                  <p>
                    The right to access and obtain a copy of your personal
                    information.
                  </p>
                  <p>
                    The right to request correction of inaccurate or incomplete
                    information.
                  </p>
                  <p>
                    The right to request deletion of your personal information.
                  </p>
                  <p>
                    The right to withdraw consent for data processing where we
                    rely on your consent.
                  </p>
                  <h4 class="mb-3 mt-5">Data Protection Commitment</h4>
                  <p>
                    We are committed to ensuring that your data remains secure
                    and confidential. Our protection measures include:
                  </p>
                  <p>
                    Encryption: All sensitive data transmitted and stored is
                    encrypted to prevent unauthorized access.
                  </p>
                  <p>
                    Access Controls: Access to your information is strictly
                    limited to authorized personnel only. We enforce rigorous
                    data protection training for our employees.
                  </p>
                  <p>
                    No Unauthorized Access: Apart performance review under no
                    circumstances will your data be accessible to third parties
                    without your explicit consent. This includes data shared
                    through our website, application, emails, and videos.
                  </p>
                  <h4 class="mb-3 mt-5">Content Usage Policy</h4>
                  <p>
                    All content, materials, and details provided by Malodia
                    Business Coaching are protected under copyright and
                    trademark laws. No individual or entity may use any of our
                    content without explicit approval. Unauthorized use will be
                    treated as an illegal act and a breach of applicable
                    copyright and trademark laws.
                  </p>
                  <h4 class="mb-3 mt-5">Cookies and Tracking Technologies</h4>
                  <p>
                    We use cookies and similar tracking technologies to enhance
                    your experience on our website. You can choose to accept or
                    decline cookies through your browser settings. However,
                    declining cookies may limit your ability to use certain
                    features of our services.
                  </p>
                  <h4 class="mb-3 mt-5">Changes to This Privacy Policy</h4>
                  <p>
                    We may update this Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on our website and updating the effective date at the top of
                    this document. We encourage you to review this Privacy
                    Policy periodically for any updates.
                  </p>
                  <h4 class="mb-3 mt-5">Contact Us</h4>
                  <p>
                    If you have any questions or concerns about this Privacy
                    Policy or our privacy practices, please contact us at
                  </p>
                  <strong>support@rahulmalodia.com.</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PrivacyPolicyPage;
