import React from 'react'
import { Link } from 'react-router-dom'

const BreadCrumb = (props) => {
    return (
        <section className={`BreadCrumb ${props.pageTitle}`}>
            <div className="container">
                <div className="row w-100 mx-0">
                    {/* <div className="col col-xs-12"> */}
                        {/* <h2>{props.pageTitle}</h2> */}
                        <ol className="breadcrumb text-black">
                            <li><Link to="/home">Home</Link></li>
                            <li><Link to="/courses">Courses</Link></li>
                            <li><span>{props.pageTitle}</span></li>
                        </ol>
                    {/* </div> */}
                </div>
            </div>
        </section>
    )
}

export default BreadCrumb;