import React from "react";
const Skill = (props) => {
  return (
    <section className="experience-section">
      <div className="content-area">
        <div className="container">
            <div className="col-12 col-md-10 mx-auto py-5">
        <div class="section-title-s3 mb-0">
            <h2>Who AM I?</h2>
        <p className=""> CA Rahul Malodia is the best business coach in India who has trained over 300000+ businessmen and transformed them from <b>“VYAPARI to CEO”</b>, working on a mission to make India again <b>“Sone ki Chidiya”</b>. </p></div>
          <p className="mb-2 text-center">
            Rahul Malodia is a chartered accountant and management consultant with
            over 15 years of experience transforming businesses and taking them
            to the next level.</p>
            <ul style={{fontSize:'1rem', fontStyle:'normal'}} className="text-center mb-0">
                <li>India's Most Followed CA on Social Media with 1.35 Cr followers.</li>
                <li>As a Business coach, I have trained over 3,00,000 MSMEs, and over 15,000+ MSMEs join our classes monthly.
                </li>
                <li>Awarded nationally and internationally, Recognized as a "40 Under 40 Most Influential Young Leader" by Asia One in Dubai, and honored with the SouthEast Asia Business Icon Award. (Economic Times, Asia One, etc.).</li>
                <li>In 10 years, aim to facilitate 50 SME IPOs, educate 10 million SMEs, and gain 50 million followers.</li>
            </ul>
            </div>
        </div>
        <div>
        </div>
      </div>
    </section>
  );
};
export default Skill;
