import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
import pimg1 from "../../images/social-icon-img/Facebook.png";
import pimg2 from "../../images/social-icon-img/Instagram.png";
import pimg3 from "../../images/social-icon-img/Linkdin.png";
import pimg4 from "../../images/social-icon-img/Telegram.png";
import pimg6 from "../../images/social-icon-img/x.png";
import pimg5 from "../../images/social-icon-img/Youtube.png";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  return (
    <footer className="site-footer">
      <div className="upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="" />
                </div>
                <p>
                  CA Rahul Malodia is one of the best business coaches in India
                  who has transformed the lives of 3,00,000+ business owners.
                </p>
              </div>
            </div>
            <div className="col col-lg col-md-6 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Useful links</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/about">
                      About me
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/courses">
                      Courses
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/career">
                      Career
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/refund-policy">
                      Refund Policy
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/terms-and-conditions">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/advertise-with-us">
                      Advertise With Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg col-md-6 col-12">
              <div className="widget contact-widget service-link-widget">
                <div className="widget-title">
                  <h3>Contact Info</h3>
                </div>
                <ul>
                  <li>Phone: +91 76500 67000 </li>
                  <li>Email: support@rahulmalodia.com</li>
                  <li>
                    Address: Jharkhand Mode, Sankalp Tower, 202 & 203, Queens
                    Rd, Vaishali Nagar, Jaipur, Rajasthan 302012
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-2 col-md-6 col-12">
              <div className="widget newsletter-widget">
                <div className="widget-title">
                  <h3>Subscribe Now</h3>
                </div>

                <div className="social-icons">
                  <ul className="mb-3">
                    <li>
                      <a
                        onClick={ClickHandler}
                        target="_blank"
                        href="https://www.youtube.com/@rahulmalodiaofficial"
                      >
                        <img src={pimg5} />
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={ClickHandler}
                        target="_blank"
                        href="https://www.facebook.com/RahulMalodiaOfficial/"
                      >
                        <img src={pimg1} />
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={ClickHandler}
                        target="_blank"
                        href="https://www.instagram.com/rahulmalodiaofficial"
                      >
                        <img src={pimg2} />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="social-icons">
                  <ul>
                    <li>
                      <a
                        onClick={ClickHandler}
                        target="_blank"
                        href="https://x.com/carahulmalodia"
                      >
                        <img src={pimg6} />
                      </a>
                    </li>

                    <li>
                      <a
                        onClick={ClickHandler}
                        target="_blank"
                        href="https://t.me/CArahulmalodiaofficial"
                      >
                        <img src={pimg4} />
                      </a>
                    </li>

                    <li>
                      <a
                        onClick={ClickHandler}
                        target="_blank"
                        href="https://www.linkedin.com/in/rahul-malodia-5ab2138a/"
                      >
                        <img src={pimg3} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lower-footer">
        <div className="container">
          <div className="row">
            <div className="separator"></div>
            <div className="col col-xs-12">
              <p className="copyright">
                Copyright &copy; 2024 Rahul Malodia. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
