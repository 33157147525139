import React, { Fragment } from 'react'
import EmployeeManagement from './EmployeeManagement'
const EmployeeManagementPage = () => {
  return (
    <Fragment>
        <EmployeeManagement/>
    </Fragment>
  )
}
export default EmployeeManagementPage
