import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import blogs from "../../api/blogs-main.js";
import BlogSingle from "../../components/BlogDetails/BlogSingle.js";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";
import { Helmet } from "react-helmet";
import BreadCrumb from "../../components/pagetitle/BreadCrumb";
const BlogDetails = () => {
  const { slug } = useParams();
  const BlogDetails = blogs.find((item) => item.slug === slug);
  return (
    <Fragment>
      <Helmet>
        <title>{BlogDetails.title}</title>
      </Helmet> 
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      <BreadCrumb pageTitle={BlogDetails.title} pagesub={"Project"} />
      <BlogSingle />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetails;
