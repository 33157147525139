import React, { Fragment } from "react";
const SubmitHandler = (e) => {
  e.preventDefault();
};
const AdvertiseForm = () => {
  return (
    <section className="service-single-section pt-4">
    <div className="request-service advertise-form mt-0">
      <h3>Request this service</h3>
      <form method="post" onSubmit={SubmitHandler}>
        <div>
          <input
            type="text"
            className="form-control"
            name="name"
            id="name"
            placeholder="Your Name*"
          />
        </div>
        <div>
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="Your Email*"
          />
        </div>
        <div>
          <input
            type="text"
            className="form-control"
            name="phone"
            id="phone"
            placeholder="Your Phone*"
          />
        </div>
        <div className="submit-area">
          <button type="submit">Send Request</button>
        </div>
      </form>
    </div>
    </section>
  );
};
export default AdvertiseForm;
