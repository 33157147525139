import timg1 from '../images/team/Adani.png'
import timg2 from '../images/team/VodafoneIdea.png'
import timg3 from '../images/team/AiciciPrudential.png'
import timg4 from '../images/team/CodingInvitors.png'
import timg5 from '../images/team/Hostinger.png'
import timg6 from '../images/team/InCredMoney.png'
import timg7 from '../images/team/IndMoney.png'
import timg8 from '../images/team/KotakAssetManagement.png'
import timg10 from '../images/team/KukuFM.png'
import timg11 from '../images/team/MotilalOswal.png'
import timg12 from '../images/team/ODOO.png'
import timg13 from '../images/team/PaisaBazaar.png'
import timg14 from '../images/team/Polaros.png'
import timg15 from '../images/team/ClearTax.png'
import timg16 from '../images/team/PolicyBazaar.png'
import timg17 from '../images/team/StableMoney.png'
import timg18 from '../images/team/BharatNXT.png'
import timg19 from '../images/team/Bigin.png'
import timg20 from '../images/team/Cars24.png'
import timg9 from '../images/team/ChaiSuttaBar.png'
const Teams = [
    {
        Id: '1',
        tImg:timg1,
    },
    {
        Id: '2',
        tImg:timg2,    },
    {
        Id: '3',
        tImg:timg3,
    },
    {
        Id: '4',
        tImg:timg4,
    },
    {
        Id: '5',
        tImg:timg5,
    },
    {
        Id: '6',
        tImg:timg6,
    },
    {
        Id: '7',
        tImg:timg7,
    },
    {
        Id: '8',
        tImg:timg8,
    },
    {
        Id: '9',
        tImg:timg9,
    },
    {
        Id: '10',
        tImg:timg10,
    },
    {
        Id: '11',
        tImg:timg11,
    },
    {
        Id: '12',
        tImg:timg12,
    },
    {
        Id: '13',
        tImg:timg13,
    },
    {
        Id: '14',
        tImg:timg14,
    },
    {
        Id: '15',
        tImg:timg15,
    },
    {
        Id: '16',
        tImg:timg16,
    },
    {
        Id: '17',
        tImg:timg17,
    },
    {
        Id: '18',
        tImg:timg18,
    },
    {
        Id: '19',
        tImg:timg19,
    },
    {
        Id: '20',
        tImg:timg20,
    }
]
export default Teams;