import React from "react";
import { Check2Circle, XCircle } from "react-bootstrap-icons";

const Basic = () => {
  return (
    <div className=" col-md-4">
      <div className="plan-cont">
        <h2 class="fw-bold text-center">₹1499</h2>
        <h5 className="text-center">Basic</h5>

        <ul class="mt-4">
          <li>
            <Check2Circle />
            <span>PDF Notes</span>
          </li>
          <li>
            <Check2Circle />
            <span>Certificate of completion</span>
          </li>
          <li>
            <Check2Circle />
            <span>V2C Masterclass Access</span>
          </li>
          <li>
            <Check2Circle />
            <span>Commmunity Access</span>
          </li>
          <li>
            <XCircle /> <span>Lifetime Access</span>
          </li>
          <li>
            <XCircle />
            <span>One Year/Life Time Technical Support</span>
          </li>
          <li>
            <XCircle />
            <span>One Year/Life Time Content Update</span>
          </li>
          <li>
            <XCircle />
            <span>Coupon Code ₹499 (Use above ₹2000)</span>
          </li>
        </ul>
        <div className="text-center button-ass">
          <a className="plan-enroll-btn" id="access-form">
            ENROLL NOW
          </a>
        </div>
      </div>
    </div>
  );
};

export default Basic;
