import React from 'react';
import Career from '../CareerForm/CareerForm'

const Careerpage = () => {

    return (
        <section className="contact-pg-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-8">
                        <div className="section-title-s3">
                            <span>Join us</span>
                            <h2>Excited to Join Us!</h2>
                            <p>Fill out the form today & your dream job might be just a click away.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="contact-form">
                            <Career />
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Careerpage;
