import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';


const ServiceTab = ({ServiceDetails}) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="service-single-tab">
            <ul className="nav">
                <Nav tabs>
                    <NavItem style={{cursor:"pointer"}}>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            {/* precautions */}
                            Benefits
                        </NavLink>
                    </NavItem>
                    <NavItem style={{cursor:"pointer"}}>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >

                            {/* Intelligence */}
                            Who Can Join?
                        </NavLink>
                    </NavItem>
                    <NavItem style={{cursor:"pointer"}}>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >

                            {/* Specializations */}
                            Why Join Us?
                        </NavLink>
                    </NavItem>
                </Nav>
            </ul>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            {/* <p>Collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather. Drops</p> */}
                            <ul>
                                <li><i className="ti-check-box"></i>{ServiceDetails.be1}</li>
                                <li><i className="ti-check-box"></i>{ServiceDetails.be2}</li>
                                <li><i className="ti-check-box"></i>{ServiceDetails.be3}</li>
                                <li><i className="ti-check-box"></i>{ServiceDetails.be4}</li>
                                <li><i className="ti-check-box"></i>{ServiceDetails.be5}</li>
                            </ul>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    {/* <p>Collection of textile samples lay spread out on the tablehung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather. Drops</p> */}
                    <ul>
                        <li><i className="ti-check-box"></i>{ServiceDetails.whojoin1}</li>
                        <li><i className="ti-check-box"></i>{ServiceDetails.whojoin2}</li>
                        <li><i className="ti-check-box"></i>{ServiceDetails.whojoin3}</li>
                        <li><i className="ti-check-box"></i>{ServiceDetails.whojoin4}</li>
                        <li><i className="ti-check-box"></i>{ServiceDetails.whojoin5}</li>
                    </ul>
                </TabPane>
                <TabPane tabId="3">
                    {/* <p>Collection of textile samples lay spread salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather. Drops</p> */}
                    <ul>
                        <li><i className="ti-check-box"></i>{ServiceDetails.whyjoin1}</li>
                        <li><i className="ti-check-box"></i>{ServiceDetails.whyjoin2}</li>
                        <li><i className="ti-check-box"></i>{ServiceDetails.whyjoin3}</li>
                        <li><i className="ti-check-box"></i>{ServiceDetails.whyjoin4}</li>
                        <li><i className="ti-check-box"></i>{ServiceDetails.whyjoin5}</li>
                    </ul>
                </TabPane>
            </TabContent>
        </div>
    );
}

export default ServiceTab;