import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Homepage from "../HomePage/HomePage";
import AboutPage from "../AboutPage/AboutPage";
import ServicePage from "../ServicePage/ServicePage";
import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import CheckoutPage from "../CheckoutPage";
import OrderRecived from "../OrderRecived";
import BlogPage from "../BlogPage/BlogPage";
import BlogDetails from "../BlogDetails/BlogDetails";
import ContactPage from "../ContactPage/ContactPage";
import ErrorPage from "../ErrorPage/ErrorPage";
import TermsAndConditionsPage from "../terms&conditions/TermsAndConditionsPage";
import RefundPolicyPage from "../RefundPolicy/RefundPolicyPage";
import PrivacyPolicyPage from "../PrivacyPolicy/PrivacyPolicyPage";
import CareerPage from "../CareerPage/CareerPage";
import Event from "../Event/Event";
import Advertise from "../AdvertiseWithUs/AdvertiseWith";
import Thankyou from "../ThankyouPage/ThankyouPage";
import SOMPage from "../LendingPages/SecretsOfMoney/SOMPage";
import V2CPage from "../LendingPages/V2C/V2CPage";
import EmployeeHiringPage from "../LendingPages/EmployeeHiring/EmployeeHiringPage";
import FinanceManagementPage from "../LendingPages/FinanceManagement/FinanceManagementPage";
import EmployeeManagementPage from "../LendingPages/EmployeeManagement/EmployeeManagementPage";
import ACPage from "../LendingPages/AdvanceCourses/ACPage";
import FinaceFundamentalPage from "../LendingPages/FinaceFundamental/FinaceFundamentalPage";

const RemoveTrailingSlash = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname.endsWith("/")) {
      const newPath = location.pathname.slice(0, -1) + location.search;
      window.history.replaceState({}, "", newPath);
    }
  }, [location]);

  return null;
};

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <RemoveTrailingSlash />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="courses" element={<ServicePage />} />
          <Route path="services" element={<ServicePage />} />
          <Route path="courses/:slug" element={<ServiceSinglePage />} />
          {/* ----------Landing pages start----------- */}
          <Route path="landing-page/Secrets-of-Money" element={<SOMPage/>} />
          <Route path="landing-page/Vyapari-to-CEO" element={<V2CPage/>} />
          <Route path="landing-page/Employee-hiring-mastery" element={<EmployeeHiringPage/>} />
          <Route path="landing-page/Finance-management-mastery" element={<FinanceManagementPage/>} />
          <Route path="landing-page/finance-fundamentals" element={<FinaceFundamentalPage/>} />
          <Route path="landing-page/Employee-management-mastery" element={<EmployeeManagementPage/>} />
          <Route path="landing-page/Advance-Courses" element={<ACPage/>} />
          
          {/* ----------Landing pages end----------- */}
          <Route path="event" element={<Event />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="order_received" element={<OrderRecived />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:slug" element={<BlogDetails />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="404" element={<ErrorPage />} />
          <Route path="404" element={<ErrorPage />} />
          <Route
            path="terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
          <Route path="refund-policy" element={<RefundPolicyPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="Career" element={<CareerPage />} />
          <Route path="advertise-with-us" element={<Advertise />} />
          <Route path="thankyou" element={<Thankyou />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
