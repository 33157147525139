import React from "react";
import Gold from "./Gold";
import Premium from "./Premium";
import Basic from "./Basic";

const CoursePlan = () => {
  return (
    <div className="container">
      <div class="container section-title" data-aos="fade-up">
        <h2>Course Palns</h2>
      </div>
      <div className="row">
        <Basic />
        <Gold />
        <Premium />
      </div>
    </div>
  );
};

export default CoursePlan;
