import React from 'react';
import { Link } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar/BlogSidebar.js'

import blogsMain from '../../api/blogs-main.js'


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const BlogList = (props) => {
    return (
        <section className="blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="blog-content">
                            {blogsMain.map((blog, bitem) => (
                                <div className={`post  ${blog.blClass}`} key={bitem}>
                                    <div className="entry-media">
                                        <img src={blog.blogSingleImg} alt="" />
                                    </div>
                                    <ul className="entry-meta">
                                        <li><Link onClick={ClickHandler} to={`/blog/${blog.slug}`}>{blog.author}</Link></li>
                                        <li><Link onClick={ClickHandler} to={`/blog/${blog.slug}`}>{blog.create_at}</Link></li>
                                    </ul>
                                    <h3><Link onClick={ClickHandler} to={`/blog/${blog.slug}`}>{blog.title}.</Link></h3>
                                    <p>{blog.description}</p>
                                    <Link onClick={ClickHandler} to={`/blog/${blog.slug}`} className="more">Read More</Link>
                                </div>
                            ))}

                            <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                        <Link to="/blog" aria-label="Previous">
                                            <i className="fi ti-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="active"><Link to="/blog">1</Link></li>
                                    <li><Link to="/blog">2</Link></li>
                                    <li><Link to="/blog">3</Link></li>
                                    <li>
                                        <Link to="/blog" aria-label="Next">
                                            <i className="fi ti-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft} />
                </div>
            </div>
        </section>

    )

}

export default BlogList;
