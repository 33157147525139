import React, { useEffect, useState } from "react";
import "../_css/style.css";
import Logo from "../../../images/logo.png";
import DainikBhaskar from "../_img/featuredin/DainikBhaskar.png";
import TimesofIndia from "../_img/featuredin/TimesofIndia.png";
import CNBC from "../_img/featuredin/CNBC.png";
import DNA from "../_img/featuredin/DNA.png";
import EconimocTimes from "../_img/featuredin/EconimocTimes.png";
import HindustanTimes from "../_img/featuredin/HindustanTimes.png";
import IndiaToday from "../_img/featuredin/IndiaToday.png";
import Outlook from "../_img/featuredin/Outlook.png";
import Patrika01 from "../_img/featuredin/Patrika01.png";
import A1TV from "../_img/featuredin/A1TV.png";
import AsiaOne from "../_img/featuredin/AsiaOne.png";
import DainikNavjyoiti from "../_img/featuredin/DainikNavjyoiti.png";
import JanTV from "../_img/featuredin/JanTV.png";
import SamacharJagat from "../_img/featuredin/SamacharJagat.png";
import Patrika from "../_img/featuredin/Patrika.png";
import ZeeRajasthan from "../_img/featuredin/ZeeRajasthan.png";
import WillfredCollegsGroup from "../_img/featuredin/St.WillfredCollegsGroup.png";
import som1 from "../_img/som-1.png";
import som2 from "../_img/som-2.png";
import som3 from "../_img/som-3.png";
import icon1 from "../_img/icons/01.png";
import icon2 from "../_img/icons/02.png";
import icon3 from "../_img/icons/03.png";
import icons11 from "../_img/icons/11.png";
import icons22 from "../_img/icons/22.png";
import icons33 from "../_img/icons/33.png";
import icons44 from "../_img/icons/44.png";
import cta from "../_img/cta-bg.jpg";
import about from "../_img/about.png";
import testimonials1 from "../_img/testimonials/testimonials-1.jpeg";
import testimonials2 from "../_img/testimonials/testimonials-2.jpeg";
import testimonials3 from "../_img/testimonials/testimonials-3.jpg";
import testimonials4 from "../_img/testimonials/testimonials-4.png";
import testimonials5 from "../_img/testimonials/testimonials-5.jpg";
import Slider from "react-slick";
import {
  Check2Circle,
  Exclamation,
  XCircle,
  StarFill,
  Linkedin,
  Telegram,
  TwitterX,
  Instagram,
  Facebook,
  Youtube,
  QuestionCircle,
} from "react-bootstrap-icons";
import { employeeHiringUrl, startCountdown } from "../../../components/common";
// import CoursePlan from "../../../components/CoursePlan/CoursePlan";

const settings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024, // Screen width of 1024px or smaller
      settings: {
        slidesToShow: 5, // Show 5 slides
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // Screen width of 768px or smaller
      settings: {
        slidesToShow: 4, // Show 4 slides
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480, // Screen width of 480px or smaller
      settings: {
        slidesToShow: 3, // Show 3 slides
        slidesToScroll: 1,
      },
    },
  ],
};
const testimonialsettings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};
const EmployeeHiring = () => {
  const [countDown, setCountDown] = useState("");

  useEffect(() => {
    const interval = startCountdown(120, setCountDown);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="index-page">
      <header id="header" class="header d-flex align-items-center fixed-top">
        <div class="container-fluid container-xl position-relative d-flex align-items-center header-menus">
          <a
            href="https://rahulmalodia.com"
            class="logo d-flex align-items-center me-md-auto"
          >
            <img src={Logo} alt="Logo" />
          </a>
          <div
            class="hero p-0 d-block"
            style={{
              minHeight: "fit-content",
              minWidth: "fit-content",
              width: "fit-content",
            }}
          >
            <a
              href={employeeHiringUrl}
              class="btn btn-get-started access-form"
              id="access-form"
            >
              Access Now
            </a>
          </div>
          <nav id="navmenu" class="navmenu">
            <i class="mobile-nav-toggle d-none bi bi-list"></i>
          </nav>
        </div>
      </header>
      <main class="main">
        <section id="hero" class="hero section dark-background">
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-12 order-2 order-lg-1 d-flex flex-column justify-content-center text-center"
                data-aos="zoom-out"
              >
                <h5 class="mb-3 ">
                  <em>
                    Attention: People who are tired of losing the star
                    performers to employees and struggle with team building.
                  </em>
                </h5>
                <h1 class="mb-3 fw-bold">Master the Art of Perfect Hiring</h1>
                <h3 class="mb-3 fw-bold">
                  Discover How to Find and Hire Top Talent Without fearing them
                  leaving or working poorly, So You Can Scale Your Business to
                  New Heights. Effortlessly
                </h3>
                <p>
                  Warning: This contains the Top Secrets of Fortune 500 Company
                  Systems.
                </p>
                <div class="d-flex  justify-content-center">
                  <a
                    href={employeeHiringUrl}
                    id="access-form"
                    class="btn-get-started access-form"
                  >
                    Access Now
                  </a>
                </div>
              </div>
              <div
                class="col-lg-12  hero-img "
                data-aos="zoom-out"
                data-aos-delay="200"
              >
                <iframe
                  src="https://player.vimeo.com/video/956024770?autoplay=1&color&autopause=0&dnt=true&loop=1&muted=0&title=1&portrait=1&byline=1#t=0"
                  frameborder="0"
                  width="100%"
                  height="359px"
                  style={{ borderRadius: "10px" }}
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section id="clients" class="clients section light-background">
          <div class="container" data-aos="zoom-in">
            <div class="swiper init-swiper">
              <Slider {...settings}>
                <div className="swiper-slide">
                  <img
                    src={DainikBhaskar}
                    className="img-fluid"
                    alt="Dainik Bhaskar"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={TimesofIndia}
                    className="img-fluid"
                    alt="Times of India"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={CNBC} className="img-fluid" alt="CNBC" />
                </div>
                <div className="swiper-slide">
                  <img src={DNA} className="img-fluid" alt="DNA" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={EconimocTimes}
                    className="img-fluid"
                    alt="Economic Times"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={HindustanTimes}
                    className="img-fluid"
                    alt="Hindustan Times"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={IndiaToday}
                    className="img-fluid"
                    alt="India Today"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={Outlook} className="img-fluid" alt="Outlook" />
                </div>
                <div className="swiper-slide">
                  <img src={Patrika01} className="img-fluid" alt="Patrika 01" />
                </div>
                <div className="swiper-slide">
                  <img src={A1TV} className="img-fluid" alt="A1 TV" />
                </div>
                <div className="swiper-slide">
                  <img src={AsiaOne} className="img-fluid" alt="Asia One" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={DainikNavjyoiti}
                    className="img-fluid"
                    alt="Dainik Navjyoiti"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={JanTV} className="img-fluid" alt="Jan TV" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={SamacharJagat}
                    className="img-fluid"
                    alt="Samachar Jagat"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={Patrika} className="img-fluid" alt="Patrika" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={ZeeRajasthan}
                    className="img-fluid"
                    alt="Zee Rajasthan"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={WillfredCollegsGroup}
                    className="img-fluid"
                    alt="Wilfred College Group"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        <section id="about" class="about section ">
          <div class="container section-title" data-aos="fade-up">
            <h2>Just like these people, does any of this sound familiar?</h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-8 mx-auto content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ul>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      Do you find yourself constantly battling high employee
                      turnover, draining resources and energy?
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      Are you facing challenges in matching the right talent to
                      the right roles , impacting operational efficiency?
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      Are you tried of hiring employees after careful evaluation
                      only to fire them after 1 month of poor quality work.
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      Do you struggle with finding team members who are
                      motivated, focussed and produce high quality work?
                    </span>
                  </li>
                  <li>
                    <Exclamation size={20} />
                    <span>
                      Are you losing to your competitors because they have
                      better hiring systems and better employees in their team?
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section light-background">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="fst-italic">AND NOT TO MENTION,</p>
                <h3>The stakes are higher than you might realise.</h3>
                <p class="fw-bold fs-5">
                  Every ineffective hire and every step in employee management
                  not only destroys financial but also lowers the morale of your
                  entire team , undermining the stability of your business
                  operations.
                </p>
                <p class="">
                  Every ineffective hire and every step in employee management
                  not only destroys financial but also lowers the morale of your
                  entire team , undermining the stability of your business
                  operations.
                </p>
                <p>
                  Without adopting a new approach , your company's potential for
                  growth and innovation could remain just out of reach and
                  permanently unattainable.
                </p>
                <h4>
                  And that is how your hard work and sacrifice to build your
                  business goes down the drain…
                </h4>

                <p>
                  <i>Right hiring makes all the difference in the world and…</i>
                </p>
                <div class="d-flex">
                  <a
                    class="btn btn-theme align-middle access-form"
                    href={employeeHiringUrl}
                    id="access-form"
                  >
                    Access Now
                  </a>
                </div>
              </div>
              <div class="col-lg-5 d-flex align-items-center">
                <img src={som1} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section id="services" class="services section">
          <div class="container section-title" data-aos="fade-up">
            <h2>What if there was a proven way to</h2>
          </div>
          <div class="container">
            <div class="row gy-4 justify-content-center">
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icon1} alt="" />
                  </div>
                  <h4>
                    <a href="" class="stretched-link">
                      Strategic Hiring
                    </a>
                  </h4>
                  <p>Using 5Star Gold Standard Hiring Process</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icon2} alt="" />
                  </div>
                  <h4>
                    <a href="" class="stretched-link">
                      Finding Right Person
                    </a>
                  </h4>
                  <p>For the right role to get maximum productivity</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="service-item position-relative text-center">
                  <div class="landing-icon">
                    <img src={icon3} alt="" />
                  </div>
                  <h4>
                    <a href="" class="stretched-link">
                      Interviewing System
                    </a>
                  </h4>
                  <p>
                    To find and hire people who think and act like managers from
                    day 1
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Now I want you to</h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-8 mx-auto content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Visualize your business growing with a stable, committed
                      team that not only meets but exceeds business goals.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Feel immense pride in a workplace where motivation and
                      engagement are present, and every employee is aligned with
                      your vision.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Witness firsthand how smoothly new hires become integral,
                      high-performing members of your team.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Enjoy the peace of mind that comes with having a loyal,
                      competent workforce that drives your bottom line and
                      business success.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Celebrate as your company culture evolves, naturally
                      creating new leaders who are eager to push the business
                      forward.
                    </span>
                  </li>
                </ul>
                <div class="d-flex justify-content-center">
                  <a
                    class="btn btn-theme align-middle access-form"
                    id="access-form"
                    href={employeeHiringUrl}
                  >
                    Access Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-5 d-flex align-items-center">
                <img src={som3} class="img-fluid" alt="" />
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="fst-italic">
                  Now after a lot of research, trial & experience, I have made
                  it possible for you...
                </p>
                <h3>Introducing: Employee Hiring</h3>
                <p class="fw-bold fs-5">
                  A Comprehensive Guide to Transforming Your Workforce: Learn to
                  master the crucial arts of screening and hiring
                </p>
                <p class="">
                  This helps you to eliminate the headaches of workforce hiring
                  and setting your business up for long-term success.
                </p>
                <p class="">We will learn about:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Advanced techniques to identify, attract, and secure top
                      talent that fits your unique business needs.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Save lakhs of rupees by eliminating chances of bad hires
                      and risks of client poaching.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Strategic methods to make sure every hire is a potential
                      future manager to replace you effortlessly.
                    </span>
                  </li>
                </ul>
                <div class="d-flex">
                  <a
                    class="btn btn-theme align-middle access-form"
                    href={employeeHiringUrl}
                    id="access-form"
                  >
                    Access Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          class="call-to-action  section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Offer Expires In</h3>
                <ul className="offer-time">
                  <li>
                    <span id="hours">{countDown.hours}</span>Hours
                  </li>
                  <li>
                    <span id="minutes">{countDown.minutes}</span>Minutes
                  </li>
                  <li>
                    <span id="seconds">{countDown.seconds}</span>Seconds
                  </li>
                </ul>
                {/* <!-- <p> Time: 10:00 AM – 01:00 PM | Sunday, 08<sup>th</sup> December</p> -->
            <!-- <p>Limited Seats, Filling Fast...</p> --> */}
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={employeeHiringUrl}
                >
                  Access Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="services" class="services section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Why Should You Choose this Course?</h2>
          </div>
          <div class="container">
            <div class="row gy-4 justify-content-center">
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="service-item position-relative">
                  <div class="landing-icon">
                    <img src={icons11} alt="" />
                  </div>
                  <h4>
                    <a href="" class="stretched-link">
                      2+ Hours
                    </a>
                  </h4>
                  <p>Premium Content</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="service-item position-relative">
                  <div class="landing-icon">
                    <img src={icons22} alt="" />
                  </div>
                  <h4>
                    <a href="" class="stretched-link">
                      हिंदी + Eng
                    </a>
                  </h4>
                  <p>Language</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="service-item position-relative">
                  <div class="landing-icon">
                    <img src={icons33} alt="" />
                  </div>
                  <h4>
                    <a href="" class="stretched-link">
                      25000+
                    </a>
                  </h4>
                  <p>Happy Students</p>
                </div>
              </div>
              <div
                class="col-xl-3 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="service-item position-relative">
                  <div class="landing-icon">
                    <img src={icons44} alt="" />
                  </div>
                  <h4>
                    <a href="" class="stretched-link">
                      Expert
                    </a>
                  </h4>
                  <p>Guidance & Coaching</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Who is this not for?</h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-10 mx-auto content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <ul>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Business owners who are happy with a high number of
                      employees leaving frequently and joining competition and
                      love the headache of constant hiring.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Leaders who believe informal, ad-hoc HR practices are
                      sufficient for business success.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Managers who are unwilling to adopt structured, tested
                      methods for employee development.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Managers who are unwilling to adopt structured, tested
                      methods for employee development.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Those who prefer to overlook the strategic value of
                      empowered, well-managed teams.
                    </span>
                  </li>
                </ul>
                <div class="d-flex justify-content-center">
                  <a
                    class="btn btn-theme align-middle access-form"
                    id="access-form"
                    href={employeeHiringUrl}
                  >
                    Access Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Here is everything you will learn inside the program</h2>
          </div>
          <div class="container">
            <div
              class="col-lg-12 content mb-5 pb-3"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h3 class="mb-4 fw-bold">
                Here is everything I will teach you in Employment Hiring Mastery
              </h3>
              <ul>
                <li>
                  <Check2Circle size={20} />
                  <span>
                    The Number one Reason why Hiring Good Employees is Super
                    Important for your Business.
                  </span>
                </li>
                <li>
                  <Check2Circle size={20} />
                  <span>
                    The 3 Level Structure of Employees used by Top Corporates &
                    Multinational Companies to manage their star performing
                    employees.
                  </span>
                </li>
                <li>
                  <Check2Circle size={20} />
                  <span>
                    How to Identify the personality of your employees & deploy
                    them in the right position to guarantee high performance &
                    business success.
                  </span>
                </li>
                <li>
                  <Check2Circle size={20} />
                  <span>
                    How to Identify the Business Murdering Problems in Your
                    Current Teams.
                  </span>
                </li>
                <li>
                  <Check2Circle size={20} />
                  <span>
                    My 5 Star Gold Standard - Employee Hiring Process in detail.
                  </span>
                </li>
                <li>
                  <Check2Circle size={20} />
                  <span>
                    The Golden Rules & the Ultimate Process Guide + Tips to take
                    Employee Interviews.
                  </span>
                </li>
                <li>
                  <Check2Circle size={20} />
                  <span>
                    The Hidden Goldmines to Find top Talent & Employees for your
                    Business & Eliminate the chances of a Bad Hire.
                  </span>
                </li>
              </ul>
            </div>
            <div class="row gy-4">
              <div
                class="col-lg-6 content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3 class="">Module 1</h3>
                <h4 class="mb-4 fw-bold">
                  Why Hiring a Good Employee is Super Important?
                </h4>
                <p>In this module, we will learn:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      The critical impact of good hiring on business success.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      How the right employee can drive productivity and growth.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      The hidden costs of bad hires and how to avoid them.
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="">Module 2</h3>
                <h4 class="mb-4 fw-bold">
                  What are the 3 Levels of Employees Every Business Must Have?
                </h4>
                <p>In this module we will learn:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Understanding the three essential employee levels for any
                      business.{" "}
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      The roles and responsibilities of each employee level.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      How to effectively integrate these levels into your
                      organization.
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="">Module 3</h3>
                <h4 class="mb-4 fw-bold">
                  4 Types of Personality & Where to Position Them in Your
                  Business
                </h4>
                <p>In this module we will learn:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      {" "}
                      Identifying the four key personality types in the
                      workplace.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      The ideal departments and positions for each personality
                      type.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      How to leverage personality strengths for optimal
                      performance.
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="">Module 4</h3>
                <h4 class="mb-4 fw-bold">Problems with the Current Team</h4>
                <p class="">In this module we will learn:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>Common issues that hinder team performance.</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      How to diagnose and address team-related problems.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Strategies for improving team dynamics and productivity.
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="">Module 5</h3>
                <h4 class="mb-4 fw-bold">Complete Hiring Process</h4>
                <p class="">In this module we will learn:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      A step-by-step guide to a successful hiring process.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Key elements of effective job postings and candidate
                      screening.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Best practices for onboarding and integrating new hires.
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="">Module 6</h3>
                <h4 class="mb-4 fw-bold">
                  How to Conduct Interviews (Process and Tips)
                </h4>
                <p class="">In this module we will learn:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Proven techniques for conducting successful interviews.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Tips for asking the right questions and assessing
                      candidates.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      How to create a positive interview experience for both
                      parties.
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="">Module 7</h3>
                <h4 class="mb-4 fw-bold">Where to Find Good Employees</h4>
                <p class="">In this module we will learn:</p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      The best sources and platforms for finding top talent.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      How to leverage networking and referrals in your hiring
                      strategy.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Creative ways to attract high-quality candidates to your
                      business.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section
          id="call-to-action"
          class="call-to-action section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Book Your Spot Now</h3>
                <p>
                  If you are any of them then you cannot afford to lose this
                  opportunity because you need to know the secrets of business
                  success to get out of the day to day hustle.
                </p>
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={employeeHiringUrl}
                >
                  Access Now
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="CoursePlan">
        <CoursePlan/>
        </section> */}
        <section id="skills" class="skills section light-background">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-5 d-flex align-items-center">
                <img src={about} class="img-fluid" alt="" />
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="mb-0">MEET YOUR MENTOR</p>
                <h3>CA Rahul Malodia</h3>
                <p class="fst-italic">
                  CEO - Malodia Business Coaching Pvt Ltd
                </p>
                <p>
                  As a business catalyst, I am on a mission to ignite a
                  revolution in India that helps businesses grow, expand and
                  stand out in global markets.
                </p>
                <p>
                  I obtained my CA degree in 2012 after completing my Commerce
                  graduation from the esteemed University of Rajasthan.
                </p>
                <p>
                  With a decade of experience as a Business Consultant and
                  working with reputable firms such as Mahajan & Aibara, I have
                  gained exposure to the professional environment and corporate
                  culture.
                </p>
                <p>
                  My work is characterized by exceptional standards, unwavering
                  accountability, and a results-driven approach.
                </p>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>15 Years+ Experience As A Chartered Accountant.</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Worked As Management Consultant For Top Corporates In
                      Mumbai.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Currently Consulting 500+ Businesses With Turnover
                      Exceeding 50Cr+
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials" class="testimonials section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Testimonials</h2>
          </div>
          <div className="container">
            <div className="init-swiper">
              <div className="">
                <Slider {...testimonialsettings}>
                  <div className="testimonial-item">
                    <img src={testimonials1} class="testimonial-img" alt="" />
                    <h3>Er. Harpreet singh</h3>
                    <h4>Director - Paul Hyundai Amritsar</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        BUSINESS FREEDOM COACHING (BFC) is a wonderful practical
                        course - both personally and professionally. I will take
                        away lots of things that I can quickly and easily apply.
                        Thank you Mr. Rahul Malodia for a great course. Great
                        presentation style with lots of opportunities to ask
                        questions and talk about real life examples which all
                        made for a really enjoyable and informative course.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials2} class="testimonial-img" alt="" />
                    <h3>Vivek Tripathi</h3>
                    <h4>Director: Satpura Infracon Pvt Ltd</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        What Lord Krishna was to Arjun so has been Rahul malodia
                        for me. He has made my vision crystal clear which I
                        would have never been able to do without his constant
                        help. He has the potential to understand any business
                        structure in a comprehensive way and his analysis plan
                        for my business has already started giving me fruitful
                        results. Under his able guidance I'm pretty sure to take
                        my business to new heights because now I have a complete
                        blueprint of my business growth plan with all minute
                        details.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials3} class="testimonial-img" alt="" />
                    <h3>Ashutosh Agrawal</h3>
                    <h4>CEO - Tesca Technologies</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        My sincere gratitude to Mr. Rahul Malodiya whom I met
                        first at BNI Conference in Jaipur & after that it has
                        been a wonderful ride for myself & Tesca Technologies
                        Pvt. Ltd. Right from setting up my Affirmations &
                        Gratitude, Mission, Vision, SOP's, JD, KRA, KPI,
                        Checklists my whole processes are system driven and with
                        this I have become more organized, humble and empathetic
                        with my employees & customers. From the bottom of my
                        heart I am really obliged to Rahul ji for his
                        extraordinary efforts for my business.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials4} class="testimonial-img" alt="" />
                    <h3>Sameer Zapke</h3>
                    <h4>I.F. Engineering, Pune</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        The affirmations taught in Rahul Sir’s class gave me
                        hope and my vision became more clear concerning my
                        business. He not only changed my present but also my
                        future. My business is running extremely well and I am
                        not worried about the future. The detailing class on
                        vision boards and affirmations has been very helpful in
                        growing my business. Thank you Rahul sir.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img src={testimonials5} class="testimonial-img" alt="" />
                    <h3>Mukesh Aggarwal</h3>
                    <h4>Owner: New Anand Sagar</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        I have attended Rahul Malodia (CA, Business Coach)
                        masterclass, and then joined his Advanced Class and
                        lastly I have joined and continued his Bootcamp (Jab tak
                        todenge, success tak pahuchenge nahi tab tak chodenge
                        nahi). He has provided and still provides valuable
                        advice, contents, and support to help our business and
                        our employees make a major transition. I highly
                        recommend him.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          class="call-to-action section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Book Your Spot Now</h3>
                <p>
                  If you are any of them then you cannot afford to lose this
                  opportunity because you need to know the secrets of business
                  success to get out of the day to day hustle.
                </p>
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={employeeHiringUrl}
                >
                  Access Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="about" class="about section options-section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Now you have 2 Options From here</h2>
          </div>
          <div class="container">
            <div class="row gy-4 justify-content-center gap-4">
              <div
                class="col-lg-5 content content-new"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3 class="">Close this page and…</h3>
                <ul>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Continue to stay where you are, struggling and wandering
                      how to improve your team and employees' performance..
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Continue consuming FREE content in hope of it gets you
                      where you want to be.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Listen to random GURUS who have no idea what they are
                      teaching & only do it for the views & sponsorships.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Wander around in FREE groups and communities and fall for
                      the wrong techniques and methods of team building and lose
                      your hard-earned money and peace.
                    </span>
                  </li>
                  <li>
                    <XCircle size={20} />
                    <span>
                      Try everything on your own to figure out what works and
                      spend years finding the right way to hire manage and train
                      employees and the team.
                    </span>
                  </li>
                </ul>
              </div>
              <div
                class="col-lg-5 content-new"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h3 class="">Invest in your growth and…</h3>
                <ul>
                  <li>
                    <Check2Circle size={20} />
                    <span>Learn the art of Employee Hiring.</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>Cut your learning process by more than half.</span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Finally have a game plan to double your team’s output.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Learn from my mistakes and achieve your goals faster.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Get proper guidance and support from a mentor who has
                      already achieved the goal.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Be the first person in your circle to build A+ Teams in
                      the game of business by becoming anexpert in hiring and
                      training team members.
                    </span>
                  </li>
                  <li>
                    <Check2Circle size={20} />
                    <span>
                      Be a step closer to Achieving the status you and your
                      family deserve by building your dream business.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-12 mt-5  text-center">
              <a
                class="btn btn-theme align-middle mx-0 access-form"
                id="access-form"
                href={employeeHiringUrl}
              >
                Access Now
              </a>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section light-background">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <p class="mb-0">Now here is my promise...</p>
                <h3>After Taking this Course</h3>
                <p>
                  Believe me when I say this - if you implement each and every
                  aspect that you learn in this course, you will be unstoppable
                  in achieving your dream team.
                </p>
                <p>
                  This course will equip you with a rock-solid understanding of
                  employees and their mindset, completely transforming your
                  business and making your entire become high performers and
                  shatter all records every single month.
                </p>
                <p>
                  You will witness a complete transformation of your psychology
                  towards employee hiring & management and your confidence will
                  be on top.
                </p>
                <p>
                  Your business now doesn’t just function; it flourishes,
                  propelled by the A-Team that is robust, motivated, and very
                  loyal.
                </p>
                <p></p>
              </div>
              <div class="col-lg-5 d-flex align-items-center">
                <img src={som2} class="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          class="call-to-action section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Book Your Spot Now</h3>
                <p>
                  If you are any of them then you cannot afford to lose this
                  opportunity because you need to know the secrets of business
                  success to get out of the day to day hustle.
                </p>
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={employeeHiringUrl}
                >
                  Access Now
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="faq-2" class="faq-2 section light-background">
          <div class="container section-title" data-aos="fade-up">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="faq-container m-0">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <QuestionCircle />
                          Who is this exactly for?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          This course is created for businessmen, traders,
                          entrepreneurs, managers to help them improve their
                          sales and achieve business goals effectively.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                        >
                          <QuestionCircle />
                          What is the language of the course?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          The entire course is in Hinglish language and the
                          concepts have been broken down and explained in a very
                          simple manner. Anyone who understands basic English
                          and Hindi can easily benefit from the course.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                        >
                          <QuestionCircle />
                          How will I get access to the course?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Once you sign up,you will get access to email where
                          you can set up your password and access the course
                          instantly.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingfour">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          <QuestionCircle />
                          Is this a live course? I don’t have time to attend
                          long lectures.
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingfour"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          No, this is a pre-recorded course and the videos are
                          cut into mini-lectures so that you can consume the
                          course at your own pace. You do not need to watch it
                          all in one go.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingfive">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="true"
                          aria-controls="collapseFive"
                        >
                          <QuestionCircle />
                          How is this different?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingfive"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          This course is designed after years of consulting and
                          coaching thousands of businesses in India to help
                          businesses with hiring frameworks and strategies and
                          increase their business profits.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSix">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="true"
                          aria-controls="collapseSix"
                        >
                          <QuestionCircle />
                          Can I watch this on my phone?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          Yes, you can watch this course on any device including
                          your Desktop, Mobile, Tablet, or any other device once
                          you log in inside the course portal. It doesn't matter
                          if you have Windows, Android, or Apple.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSeven">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="true"
                          aria-controls="collapseSeven"
                        >
                          <QuestionCircle />
                          How to get support & ask questions?
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        class="accordion-collapse collapse show"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          If you have any doubts or questions, you can reach out
                          on our support email address.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" class="services section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Are you ready to Master Employee & Talent Hiring?</h2>
          </div>
          <div class="container">
            <p class="fs-3 text-center">
              Ps, if you read this page till here, you do understand what is
              missing in your business.
            </p>
            <h4 class="fw-bold text-center">
              The Employee Hiring Mastery Course could be the missing piece of
              your puzzle to completely transform your life & business.
            </h4>
            <p class="fs-3 text-center">So here is the question....</p>
            <p class="fs-3 text-center">Are you ready?</p>
            <div class="text-center">
              <a
                class="btn btn-theme align-middle mx-0 access-form"
                id="access-form"
                href={employeeHiringUrl}
              >
                Access Now
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer" class="footer">
        <div class="footer-newsletter bg-dark">
          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-lg-10">
                <p class="mb-0">
                  **We cannot guarantee any specific earnings or income from our
                  product or service. Any results discussed or illustrated are
                  not typical and should not be considered a guarantee of future
                  income or success. The success or failure of each individual
                  may be influenced by a variety of factors including but not
                  limited to skills, experience, dedication and effort. We
                  provide knowledge and guidance based on our experience and
                  expertise. Your success ultimately depends on your own
                  personal effort and dedication ​
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container footer-top ">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-6 footer-about">
              <a href="" class="d-flex align-items-center">
                <img src={Logo} alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-3 footer-links">
              <h4>Contact Us</h4>
              <div class="footer-contact">
                <p>
                  Jharkhand Mode, Sankalp Tower, 202 & 203, Queens Rd, Vaishali
                  Nagar, Jaipur, Rajasthan 302012
                </p>
                <p class="mt-3">
                  <strong>Phone:</strong> <span>+91 76500 67000</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>support@rahulmalodia.com</span>
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <h4>Follow Us</h4>
              <div class="social-links d-flex">
                <a
                  target="_blank"
                  href="https://www.youtube.com/@rahulmalodiaofficial"
                >
                  <Youtube />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/RahulMalodiaOfficial/"
                >
                  <Facebook />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/rahulmalodiaofficial/?hl=en"
                >
                  <Instagram />
                </a>
                <a target="_blank" href="https://x.com/carahulmalodia">
                  <TwitterX />
                </a>
                <a target="_blank" href="https://t.me/CArahulmalodiaofficial">
                  <Telegram />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/rahul-malodia-5ab2138a/"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright text-center mt-4">
          <p>
            <span>Copyright © 2024</span>{" "}
            <a href="rahulmalodia.com">
              <strong class="px-1 sitename">rahulmalodia.com</strong>
            </a>{" "}
            <span>All Rights Reserved</span>
          </p>
        </div>
      </footer>
    </div>
  );
};
export default EmployeeHiring;
