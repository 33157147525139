import React from "react";
const Skill2 = (props) => {
  return (
    <>
      <section className="experience-section">
        <div className="content-area">
          <div className="left-col"></div>
          <div className="right-col">
            <div className="">
              <div class="section-title-s2 mb-0">
                <span className="mb-3">My Personal Jouney</span>
                <h2>Something from the Heart</h2>
                <p className="">
                  I am a business catalyst & aims to start a revolutionary
                  movement in India helping businesses grow, expand & find their
                  identity in competition to global markets. I became CA in 2012
                  after completing my Graduation in Commerce from the University
                  of Rajasthan.
                </p>
                <p className="mb-0">
                  I have been working as a Business Consultant for 10 years now,
                  and I have been associated with some great firms including
                  Mahajan & Aibara, exposing me to the professional environment
                  and corporate culture, full of responsibilities and
                  high-quality work standards.
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </section>
      <section className="experience-section">
        <div className="">
          <div className="container">
            <div className="col-12 col-md-10 mx-auto py-5">
              <div class="section-title-s3 mb-0">
                <h2>The Desk Of Rahul Malodia​</h2>
                <p className="">
                  To Future Freedompreneur Coming from a Rajasthani family I
                  always observed people setting up their own business no matter
                  small or big. But one thing that always concerned me that no
                  matter how amazing the services or the product might be the{" "}
                  <b>
                    {" "}
                    business owner was completely unable to take even a single
                    hour out of it.
                  </b>
                </p>
                <p className="">
                  Unlike many people from my family I didn’t opt for starting a
                  business rather, I took a job.How bizarre it may sound I knew
                  my decision was perfect.
                </p>
                <p className="">
                  Working as a CA and consultant in a big firm in Mumbai on a
                  stipend basis was a life-changing decision for me. Not because
                  I had a job… but because that job revealed those secrets and
                  techniques in front of me that only the top 1% of businesses
                  in India implement and are benefiting from them.
                </p>
                <p className="">
                Sitting in those closed board rooms of that company opened my way to look at businesses with a whole new angle. I got to not just learn new techniques but also practically implement them in real business and develop a smooth and painless business automation system for them.
                </p>
                <p className="">
                The best part about this was the smile of happiness and freedom that the business owner had on his face ….it was very fulfilling.😊
                </p>
                <p className="">
                After 2 years when my stipend phase came to an end, I was a bhandaar (storehouse) of every skill that will not just make a business manageable but also automated to a whole new level. I was offered a great placement offer which could pay me huge money but I rejected it, started my own consultation firm, and started applying all the techniques and secrets that I learned all these years!
                </p>
                <p className="">
                I know it might sound absurd and foolish….. and you might also be thinking why on the earth I did this?
                </p>
                <p className="">
                Many people close to me also questioned the same thing. But this time also I knew that my decision was perfect. 💯
                </p>
                <p className="">
                And, only in a few months after I started with my own consultation firm, I closed over 23 clients and completely automated my business which helped me to achieve the freedom I always wanted to have.
                </p>
                <p className="">
                And again, I experienced a fulfilling smile and this time, it was mine! 😃
                </p>
                <p className="">
                I wanted the same smile of freedom and happiness on the face of the business owners of my hometown – JAIPUR where  consultancy and business automation was the unheard and impossible concept.
                </p>
                <p className="">
                Fast forward to 2020, I am a Josh Talks Speaker and not only I have automated my own business but also automated more than 500+ SMEs all over India.
                </p>
                <p className="mb-0">
                And I am on a mission to automate more than 50000+ SMEs of India and therefore I invite you to join me in my mission by automating your business under my guidance and experience that smile of freedom.🤝
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Skill2;
