
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import BreadCrumb from "../../components/pagetitle/BreadCrumb";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams, useLocation } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import Services from "../../api/service";
import ServiceTab from "./ServiceTab";
import Logo from "../../images/logo.png";
import { GoogleAdCourseThank } from "../../components/common";
const ClickHandler = () => {
  window.scrollTo(10, 0);
};

// const SubmitHandler = (e) => {
//   e.preventDefault();
// };

const ServiceSinglePage = (props) => {
  const { slug } = useParams();

  const ServiceDetails = Services.find((item) => item.slug === slug);
  const location = useLocation();
  const [courseName, setCourseName] = useState('');

  useEffect(() => {
    let name = location.pathname.split('/');
        name = name[name.length-1];
        console.log('name: ', name);
        
        if(name){
          setCourseName(name);
        }
  }, [location])
  
  // const isActive = location.pathname === `/courses/${ServiceDetails.slug}`;

  return (
    <Fragment>
       <Helmet>
        <title>Course - {ServiceDetails.sTitle}</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10857044230"></script>
        <script>
          {GoogleAdCourseThank}
        </script>
      </Helmet>
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      {/* <PageTitle pageTitle={ServiceDetails.sTitle} pagesub={'Project'} /> */}
      <BreadCrumb pageTitle={ServiceDetails.sTitle} pagesub={"Project"} />

      <section className="service-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-9 order-lg-2 order-1 col-12">
              <div className="service-single-content">
                <div className="service-pic">
                  <img src={ServiceDetails.sImg} alt="" />
                </div>
                <h2>{ServiceDetails.sTitle}</h2>
                <p>{ServiceDetails.des2}</p>
                <p>{ServiceDetails.des3}</p>
                <p>{ServiceDetails.des4}</p>
                <h3>{ServiceDetails.hnew}</h3>
                <p>{ServiceDetails.des5}</p>
                <div className="">
                  <ul>
                    <li>
                      <i className="ti-check-box"></i>
                      {ServiceDetails.fe1}
                    </li>
                    <li>
                      <i className="ti-check-box"></i>
                      {ServiceDetails.fe2}
                    </li>
                    <li>
                      <i className="ti-check-box"></i>
                      {ServiceDetails.fe3}
                    </li>
                    <li>
                      <i className="ti-check-box"></i>
                      {ServiceDetails.fe4}
                    </li>
                    <li>
                      <i className="ti-check-box"></i>
                      {ServiceDetails.fe5}
                    </li>
                    <li>
                      <i className="ti-check-box"></i>
                      {ServiceDetails.fe6}
                    </li>
                  </ul>
                </div>

                <ServiceTab ServiceDetails={ServiceDetails} />
                <p className="fs-4 mb-3">
                  The course is priced at{" "}
                  <strong className="price">{ServiceDetails.price}</strong>{" "}
                  only. Enroll today and open doors to achieve success.
                </p>
                <div className="">
                  <a
                    className="btn theme-btn"
                    // href="https://payments.malodiabusinesscoaching.org/fundaments-of-secrets"
                    // href="https://payments.malodiabusinesscoaching.org/Money"
                    href={`https://payments.malodiabusinesscoaching.org/${courseName}`}
                  >
                    Access Now @ {ServiceDetails.price}
                  </a>
                </div>
              </div>
            </div>
            <div className="col col-lg-3 order-lg-1 order-2 col-12">
              <div className="service-sidebar">
                <div className="widget service-list-widget">
                  <ul>
                    {/* {Services.map((service, srv) => (
                                            <li key={srv}  ><Link onClick={ClickHandler} to={`/courses/${service.slug}`} className={isActive ? '' : 'current'}>{service.sTitle}</Link></li>
                                        ))} */}

                    <li className="">
                      <Link to="/courses">All Courses</Link>
                    </li>
                    {Services.map((service, srv) => {
                      const isActive =
                        location.pathname === `/courses/${service.slug}`;
                      return (
                        <li key={srv} className={isActive ? "current" : ""}>
                          <Link
                            onClick={ClickHandler}
                            to={`/courses/${service.slug}`}
                          >
                            {service.sTitle}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="widget download-widget">
                  <a
                    className="btn theme-btn w-100"
                    //  href="https://payments.malodiabusinesscoaching.org/fundaments-of-secrets"
                    //  href="https://payments.malodiabusinesscoaching.org/Money"
                    href={`https://payments.malodiabusinesscoaching.org/${courseName}`}
                  >
                    Access Now @ {ServiceDetails.price}
                  </a>
                </div>
                <div className="widget service-features-widget">
                  <h3>Our Service Features</h3>
                  <ol>
                    <li>Quality service guarantee</li>
                    <li>100% safe work</li>
                    <li>Money back guarantee</li>
                    <li>100% satisfied client</li>
                  </ol>
                </div>
                {/* <div className="widget download-widget">
                                    <ul style={{textAlign:"center"}}>
                                        <li><Link onClick={ClickHandler} to='/contact'>ACCESS NOW</Link></li>
                                    </ul>
                                </div> */}
                <div className="widget contact-widget">
                  <div>
                    <h4>Have Questions?</h4>
                    <p>
                      Don't worry! We're here to help! Call us, and we'll give
                      you all the details about the course to help you achieve
                      all your goals.
                    </p>
                    <p>
                      Phone:{" "}
                      <a
                        href="tel:+917650067000"
                        className="bg-transparent text-white p-0"
                      >
                        {" "}
                        +91 76500 67000
                      </a>
                    </p>
                    <Link onClick={ClickHandler} to="/contact">
                      Contact With Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServiceSinglePage;
