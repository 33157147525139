import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";
import { Helmet } from "react-helmet";
const TermsAndConditionsPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      <PageTitle
        pageTitle={"Terms & Conditions"}
        pagesub={"Terms & Conditions"}
      />
      <section className="wpo-terms-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="wpo-terms-wrap">
                <div className="wpo-terms-img">
                  {/* <img src={tImg1} alt=""/> */}
                </div>
                <div className="wpo-terms-text">
                  <p>
                    Rahul Malodia is the owner of copyrights for the entire
                    content on this website. No material from this site may be
                    copied, modified, reproduced, republished, uploaded,
                    transmitted, posted and distributed in any form without
                    prior written permission from Rahul Malodia. Any use of the
                    content of this website for a purpose that is not consistent
                    with fair use provisions of copyright law is prohibited and
                    may amount to infringement of copyright. Trademarks, logos,
                    labels displayed on this website are trademarks or
                    registered trademarks of Rahul Malodia or its group or its
                    affiliates unless otherwise specified. These trademarks may
                    not be used in any manner without prior written consent from
                    Rahul Malodia. Any unauthorized use may amount to
                    infringement and violation of trademark and other applicable
                    laws and could result in criminal and or civil penalties.
                  </p>
                  <h4 class="mb-3 mt-5">Privacy Policy</h4>
                  <p>
                    Personal information, if any submitted by users of this
                    website is primarily used to provide the requested service
                    to the user and Rahul Malodia does not sell, rent or share
                    such personal information to any third party or
                    non-affiliated company.
                  </p>
                  <h4 class="mb-3 mt-5">Disclaimer</h4>
                  <p>
                    This website contains certain forward-looking statements
                    concerning future business prospects and profitability of
                    Rahul Malodia, which are subject to a number of risks and
                    uncertainties and actual results could materially differ
                    from those in such forward-looking statements. Important
                    developments that could affect the company’s operations
                    include a downtrend in the infrastructure sector,
                    significant changes in the political and economic
                    environment in India, tax laws, labour relations, litigation
                    etc. The company does not undertake to make any announcement
                    in case any of these forward-looking statements become
                    materially incorrect in future or update any forward-looking
                    statements made from time to time by or on behalf of the
                    company.
                  </p>
                  <h4 class="mb-3 mt-5">Other Terms & Conditions of use:</h4>
                  <ul class="tc-list">
                    <li>
                      The information on this site has been displayed in good
                      faith and is for general purposes. It should not be relied
                      upon for any specific purpose and no representation or
                      warranty is given with regards to its accuracy or
                      completeness. No information on this website shall
                      constitute an invitation to invest in Rahul Malodia or its
                      group or its affiliates.
                    </li>
                    <li>
                      A user is granted a limited license to use the content of
                      this website for his personal, non-commercial use and no
                      other license, right, title and interest of any kind is
                      granted to him by implication or otherwise. Rahul Malodia
                      is not liable to users or anyone for any loss, damage or
                      expense caused by the use of this website in whatever
                      manner and of whatever nature.
                    </li>
                    <li>
                      This site provides for external links, access to contents
                      controlled & operated by third parties, which are made
                      available to users as a matter of convenience only. Rahul
                      Malodia does not represent nor endorse these third parties
                      nor is liable to users/anyone for any damages, losses,
                      expenses arising by use or access of these links/contents.
                    </li>
                    <li>
                      Rahul Malodia retains the sole right to change, revise,
                      modify, add or delete contents of this website, in whole
                      or in part at any time without notice.
                    </li>
                  </ul>

                  <div className="row t-sub">
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        {/* <img src={tImg2} alt=""/> */}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="wpo-p-details-img">
                        {/* <img src={tImg3} alt=""/> */}
                      </div>
                    </div>
                  </div>
                  <p>
                    Post Construction Cleaning (Builders Cleaning), Event
                    Cleaning or badly neglected homes may take up to three times
                    longer than a well maintained home requiring general
                    cleaning. Therefore the Company advises the Client to ask f
                    or our specialist cleaning services: Builders Cleaning or
                    Event Cleaning.
                  </p>
                </div>
                {/* <FAQ /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default TermsAndConditionsPage;
