/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import "../_css/style.css";
import Logo from "../../../images/logo.png";
import DainikBhaskar from "../_img/featuredin/DainikBhaskar.png";
import TimesofIndia from "../_img/featuredin/TimesofIndia.png";
import CNBC from "../_img/featuredin/CNBC.png";
import DNA from "../_img/featuredin/DNA.png";
import EconimocTimes from "../_img/featuredin/EconimocTimes.png";
import HindustanTimes from "../_img/featuredin/HindustanTimes.png";
import IndiaToday from "../_img/featuredin/IndiaToday.png";
import Outlook from "../_img/featuredin/Outlook.png";
import Patrika01 from "../_img/featuredin/Patrika01.png";
import A1TV from "../_img/featuredin/A1TV.png";
import AsiaOne from "../_img/featuredin/AsiaOne.png";
import DainikNavjyoiti from "../_img/featuredin/DainikNavjyoiti.png";
import JanTV from "../_img/featuredin/JanTV.png";
import SamacharJagat from "../_img/featuredin/SamacharJagat.png";
import Patrika from "../_img/featuredin/Patrika.png";
import ZeeRajasthan from "../_img/featuredin/ZeeRajasthan.png";
import cta from "../_img/cta-bg.jpg";
import about from "../_img/about.png";
import testimonials1 from "../_img/testimonials/testimonials-1.jpeg";
import testimonials2 from "../_img/testimonials/testimonials-2.jpeg";
import testimonials3 from "../_img/testimonials/testimonials-3.jpg";
import testimonials4 from "../_img/testimonials/testimonials-4.png";
import testimonials5 from "../_img/testimonials/testimonials-5.jpg";
import Guarantee from "../_img/100-Satisfaction-Guarantee-What-does-that-mean-removebg-preview.png";
import WillfredCollegsGroup from "../_img/featuredin/St.WillfredCollegsGroup.png";
import Slider from "react-slick";
import {
  Check2Circle,
  StarFill,
  Linkedin,
  Telegram,
  TwitterX,
  Instagram,
  Facebook,
  Youtube,
  Hourglass,
  Key,
  People,
  Book,
  CheckAll,
  QuestionCircle
} from "react-bootstrap-icons";
import { startCountdown, v2cUrl } from "../../../components/common";

const settings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024, // Screen width of 1024px or smaller
      settings: {
        slidesToShow: 5, // Show 5 slides
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // Screen width of 768px or smaller
      settings: {
        slidesToShow: 4, // Show 4 slides
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480, // Screen width of 480px or smaller
      settings: {
        slidesToShow: 3, // Show 3 slides
        slidesToScroll: 1,
      },
    },
  ],
};
const testimonialsettings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};
const V2C = () => {
  const [countDown, setCountDown] = useState('');

  useEffect(() => {
    const interval = startCountdown(120, setCountDown);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="index-page">
      <header
        id="header"
        className="header d-flex align-items-center fixed-top"
      >
        <div className="container-fluid container-xl position-relative d-flex align-items-center header-menus">
          <a
            href="index.html"
            className="logo d-flex align-items-center me-md-auto"
          >
            <img src={Logo} alt="" />
          </a>
          <div
            className="hero p-0 d-block"
            style={{
              minHeight: "fit-content",
              minWidth: "fit-content",
              width: "fit-content",
            }}
          >
            <a
              className="btn btn-get-started access-form"
              id="access-form"
              href={v2cUrl}
            >
              Access Now @Just ₹99
            </a>
          </div>
          <nav id="navmenu" className="navmenu">
            <i className="mobile-nav-toggle d-none bi bi-list"></i>
          </nav>
        </div>
      </header>
      <main className="main">
        <section id="hero" className="hero section dark-background">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                <h5>Learn To Manage Your</h5>
                <h1 className="mb-3">
                  Employees, finances, sales & mindset in 3 hrs
                </h1>
                <p>
                  Time: 10:00 AM – 01:00 PM | Sunday, 08<sup>th</sup> December
                </p>
                <div className="d-flex">
                  <a
                    href={v2cUrl}
                    id="access-form"
                    className="access-form btn-get-started"
                  >
                    Access Now @Just ₹99
                  </a>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 hero-img">
                <iframe
                  src="https://player.vimeo.com/video/601595033"
                  frameborder="0"
                  width="100%"
                  height="359px"
                  style={{ borderRadius: "10px" }}
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section id="clients" class="clients section light-background">
          <div class="container" data-aos="zoom-in">
            <div class="swiper init-swiper">
              <Slider {...settings}>
                <div className="swiper-slide">
                  <img
                    src={DainikBhaskar}
                    className="img-fluid"
                    alt="Dainik Bhaskar"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={TimesofIndia}
                    className="img-fluid"
                    alt="Times of India"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={CNBC} className="img-fluid" alt="CNBC" />
                </div>
                <div className="swiper-slide">
                  <img src={DNA} className="img-fluid" alt="DNA" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={EconimocTimes}
                    className="img-fluid"
                    alt="Economic Times"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={HindustanTimes}
                    className="img-fluid"
                    alt="Hindustan Times"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={IndiaToday}
                    className="img-fluid"
                    alt="India Today"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={Outlook} className="img-fluid" alt="Outlook" />
                </div>
                <div className="swiper-slide">
                  <img src={Patrika01} className="img-fluid" alt="Patrika 01" />
                </div>
                <div className="swiper-slide">
                  <img src={A1TV} className="img-fluid" alt="A1 TV" />
                </div>
                <div className="swiper-slide">
                  <img src={AsiaOne} className="img-fluid" alt="Asia One" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={DainikNavjyoiti}
                    className="img-fluid"
                    alt="Dainik Navjyoiti"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={JanTV} className="img-fluid" alt="Jan TV" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={SamacharJagat}
                    className="img-fluid"
                    alt="Samachar Jagat"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={Patrika} className="img-fluid" alt="Patrika" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={ZeeRajasthan}
                    className="img-fluid"
                    alt="Zee Rajasthan"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={WillfredCollegsGroup}
                    className="img-fluid"
                    alt="Wilfred College Group"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        <section id="services" className="services section">
          <div className="container section-title">
            <h2>Transform Yourself From Vyapari To Ceo</h2>
          </div>
          <div className="container">
            <div className="row gy-4">
              <div className="col-xl-3 col-md-6 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <Hourglass />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      3 hours powerful classes
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <Key />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Access to Future Sessions
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <Book />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      4 EBooks on business for free
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <People />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Lifetime access to vip community
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about section">
          <div className="container section-title">
            <h2>Here is What I Wanna Give You</h2>
          </div>
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 content">
                <h3 className="mb-4">Entrepreneurship Freedom Blueprint</h3>
                <ul>
                  <li>
                    <Check2Circle />
                    <span>How Your Business Can Run Without You.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>Importance Of Business Automation.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>System To Run Your Business On Autopilot.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>Tools & Techniques For Automation</span>
                  </li>
                  <li>
                    <Check2Circle /> <span>How To Set Business Goals.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>How to Supercharge Your Productivity.</span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <h3 className="mb-4">Team Building Blueprint</h3>
                <ul>
                  <li>
                    <Check2Circle /> <span>How to Get The Right Team.</span>
                  </li>
                  <li>
                    <Check2Circle /> <span>How To Set KRA's & KPI's.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>How To Build A High-Performance Culture.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>Importance of Employee On-Boarding System.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>How To Measure Performance & Give Reward.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>How to Breed Innovation At Workplace.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>
                      How To Harness Skill,, Navigate Conflicts & Encourage
                      Collaboration In The Team.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <h3 className="mb-4">Sales Growth Blueprint</h3>
                <ul>
                  <li>
                    <Check2Circle />
                    <span>How To Sell More, Faster & Confidently.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>Lead Generation & Conversion System.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>How To Make Your Prospect Decide Quickly.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>Negotiation Technique That Brings a "YES".</span>
                  </li>
                  <li>
                    <Check2Circle /> <span>How To Create A Sales Funnel.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>Marketing, Sales & Branding All Explained.</span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <h3 className="mb-4">Finance Mastery Blueprint</h3>
                <ul>
                  <li>
                    <Check2Circle />
                    <span>How To Read Financial Statements.</span>
                  </li>
                  <li>
                    <Check2Circle /> <span>How To Manage Cashflow.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>How To Release Working Capital Easily.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>5 Crucial Ratios That You Must Review.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>Finance Security From Financial Institutions.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>How To Assess The Profitability of A Deal.</span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <h3 className="mb-4">QnA Session</h3>
                <ul>
                  <li>
                    <Check2Circle /> <span>Ask Your Questions.</span>
                  </li>
                  <li>
                    <Check2Circle /> <span>Clear All Your Doubts.</span>
                  </li>
                  <li>
                    <Check2Circle /> <span>Get Networking Opportunity.</span>
                  </li>
                  <li>
                    <Check2Circle /> <span>Get Community Acces.</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          className="call-to-action  section dark-background"
        >
          <img src={cta} alt="" />
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 text-center text-xl-start">
                <h3>Offer Expires In</h3>
                <ul className="offer-time">
                  <li>
                    <span id="hours">{countDown.hours}</span>Hours
                  </li>
                  <li>
                    <span id="minutes">{countDown.minutes}</span>Minutes
                  </li>
                  <li>
                    <span id="seconds">{countDown.seconds}</span>Seconds
                  </li>
                </ul>
                <p>
                  Time: 10:00 AM – 01:00 PM | Sunday, 08<sup>th</sup> December
                </p>
                <p>Limited Seats, Filling Fast...</p>
              </div>
              <div className="col-xl-6 cta-btn-container text-center">
                <a
                  className="cta-btn align-middle mx-0 access-form"
                  id="javascript:void(0)"
                  href={v2cUrl}
                >
                  Access Now @Just ₹99
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="skills" className="skills section">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-5 d-flex align-items-center">
                <img src={about} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 content">
                <h3>Who Is Rahul Malodia?</h3>
                <p className="fst-italic">
                  CEO - Malodia Business Coaching Pvt Ltd
                </p>
                <ul>
                  <li>
                    <Check2Circle />
                    <span>12 Years+ Experience As A Chartered Accountant.</span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>
                      Worked As Management Consultant For Top Corporates In
                      Mumbai.
                    </span>
                  </li>
                  <li>
                    <Check2Circle />
                    <span>
                      Currently Consulting 500+ Businesses With Turnover
                      Exceeding 50Cr+
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="faq-2" className="faq-2 section light-background">
          <div className="container section-title">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="faq-container m-0">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <QuestionCircle />
                          Where This Session Is Going To Take Place?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          This session will be online and is going to be hosted
                          on zoom.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="true"
                          aria-controls="collapseTwo"
                        >
                          <QuestionCircle />
                          What Are The Timings Of The Session?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          The Session is scheduled for Sunday, 08 December(
                          10:00 am – 01:00 pm ).
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                        >
                          <QuestionCircle />
                          If In Case I Could Not Attend, Will I Get The
                          Recording Of The Sessions?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          No Recordings will be shared.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingfour">
                        <button
                           className="accordion-button"
                           type="button"
                           data-bs-toggle="collapse"
                           data-bs-target="#collapseFour"
                           aria-expanded="true"
                           aria-controls="collapseFour"
                        >
                          <QuestionCircle />
                          When I Will Get All The Bonuses?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingfour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          You will get your Bonuses after attending the webinar.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services section">
          <div className="container section-title">
            <h2>Who Join Us</h2>
          </div>
          <div className="container">
            <div className="row gy-4">
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Entrepreneur
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Business Owner
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Self-Employed
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Professional
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Manufacturer
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Trader
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          className="call-to-action section dark-background"
        >
          <img src={cta} alt="" />
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 text-center text-xl-start">
                <h3>Book Your Spot Now</h3>
                <p>
                  If you are any of them then you cannot afford to lose this
                  opportunity because you need to know the secrets of business
                  success to get out of the day to day hustle.
                </p>
                <p>
                  Time: 10:00 AM – 01:00 PM | Sunday, 08<sup>th</sup> December
                </p>
              </div>
              <div className="col-xl-6 cta-btn-container text-center">
                <a
                  className="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href={v2cUrl}
                >
                  Access Now @Just ₹99
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials" className="testimonials section">
          <div className="container section-title">
            <h2>Testimonials</h2>
          </div>
          <div className="container">
            <div className="init-swiper">
              <div className="">
                <Slider {...testimonialsettings}>
                  <div className="testimonial-item">
                    <img
                      src={testimonials1}
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Er. Harpreet singh</h3>
                    <h4>Director - Paul Hyundai Amritsar</h4>
                    <div className="stars">
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        BUSINESS FREEDOM COACHING (BFC) is a wonderful practical
                        course - both personally and professionally. I will take
                        away lots of things that I can quickly and easily apply.
                        Thank you Mr. Rahul Malodia for a great course. Great
                        presentation style with lots of opportunities to ask
                        questions and talk about real life examples which all
                        made for a really enjoyable and informative course.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img
                      src={testimonials2}
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Vivek Tripathi</h3>
                    <h4>Director: Satpura Infracon Pvt Ltd</h4>
                    <div className="stars">
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        What Lord Krishna was to Arjun so has been Rahul malodia
                        for me. He has made my vision crystal clear which I
                        would have never been able to do without his constant
                        help. He has the potential to understand any business
                        structure in a comprehensive way and his analysis plan
                        for my business has already started giving me fruitful
                        results. Under his able guidance I'm pretty sure to take
                        my business to new heights because now I have a complete
                        blueprint of my business growth plan with all minute
                        details.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img
                      src={testimonials3}
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Ashutosh Agrawal</h3>
                    <h4>CEO - Tesca Technologies</h4>
                    <div className="stars">
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        My sincere gratitude to Mr. Rahul Malodiya whom I met
                        first at BNI Conference in Jaipur & after that it has
                        been a wonderful ride for myself & Tesca Technologies
                        Pvt. Ltd. Right from setting up my Affirmations &
                        Gratitude, Mission, Vision, SOP's, JD, KRA, KPI,
                        Checklists my whole processes are system driven and with
                        this I have become more organized, humble and empathetic
                        with my employees & customers. From the bottom of my
                        heart I am really obliged to Rahul ji for his
                        extraordinary efforts for my business.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img
                      src={testimonials4}
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sameer Zapke</h3>
                    <h4>I.F. Engineering, Pune</h4>
                    <div className="stars">
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        The affirmations taught in Rahul Sir’s class gave me
                        hope and my vision became more clear concerning my
                        business. He not only changed my present but also my
                        future. My business is running extremely well and I am
                        not worried about the future. The detailing class on
                        vision boards and affirmations has been very helpful in
                        growing my business. Thank you Rahul sir.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img
                      src={testimonials5}
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Mukesh Aggarwal</h3>
                    <h4>Owner: New Anand Sagar</h4>
                    <div className="stars">
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                     <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        I have attended Rahul Malodia (CA, Business Coach)
                        masterclass, and then joined his Advanced Class and
                        lastly I have joined and continued his Bootcamp (Jab tak
                        todenge, success tak pahuchenge nahi tab tak chodenge
                        nahi). He has provided and still provides valuable
                        advice, contents, and support to help our business and
                        our employees make a major transition. I highly
                        recommend him.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        <section
          id="contact"
          className="contact guarantee section light-background"
        >
          <div className="container section-title pb-0">
            <h2>100% Money Back Guarantee</h2>
            <h5 className="w-75 mx-auto mb-4 pb-0">
              It's a completely risk-free opportunity... because this powerful
              program comes with a 100% Money Back Guarantee.
            </h5>
            <div>
              <img src={Guarantee} alt="" />
            </div>
            <p>
              If you didn’t find this masterclass The “Vyapari to CEO” Value
              Packed and Helpful you can ask for your money back (on our support
              email: support@rahulmalodia.com) and your money will be refunded
              with no questions asked.
            </p>
            <p className="mb-4">
              Time: 10:00 am – 01:00 pm | Sunday, 08<sup>th</sup> December
            </p>
            <a
              className="btn btn-get-started"
              href={v2cUrl}
            >
              Book Your Spot Now @Just ₹99
            </a>
          </div>
        </section>
      </main>
      <footer id="footer" className="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-6">
                <h4 className="mb-0">
                  Don't Think Too Much... Take Action Now...
                </h4>
              </div>
            </div>
          </div>
        </div>

        

        <div className="container footer-top">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 footer-about">
              <a href="" className="d-flex align-items-center">
                <img src={Logo} alt="" />
              </a>
            </div>
            <div className="col-lg-4 col-md-3 footer-links">
              <h4>Contact Us</h4>
              <div className="footer-contact">
                <p>
                  Jharkhand Mode, Sankalp Tower, 202 & 203, Queens Rd, Vaishali
                  Nagar, Jaipur, Rajasthan 302012
                </p>
                <p className="mt-3">
                  <strong>Phone:</strong> <span>+91 76500 67000</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>support@rahulmalodia.com</span>
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <h4>Follow Us</h4>
              <div class="social-links d-flex">
                <a
                  target="_blank"
                  href="https://www.youtube.com/@rahulmalodiaofficial"
                >
                  <Youtube />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/RahulMalodiaOfficial/"
                >
                  <Facebook />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/rahulmalodiaofficial/?hl=en"
                >
                  <Instagram />
                </a>
                <a target="_blank" href="https://x.com/carahulmalodia">
                  <TwitterX />
                </a>
                <a target="_blank" href="https://t.me/CArahulmalodiaofficial">
                  <Telegram />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/rahul-malodia-5ab2138a/"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container copyright text-center mt-4">
          <p>
            <span>Copyright © 2024</span>
            <a href="rahulmalodia.com">
              <strong className="px-1 sitename">rahulmalodia.com</strong>
            </a>
            <span>All Rights Reserved</span>
          </p>
        </div>
      </footer>
    </div>
  );
};
export default V2C;
