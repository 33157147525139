import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./style.css";
const Scrollbar = () => {
  return (
    <>
      <div className="col-lg-12">
        <div className="header-menu">
          <ul className="smothscroll">
            <li>
              <AnchorLink href="#scrool">
                <i className="ti-arrow-up"></i>
              </AnchorLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="header-menu">
          <ul className="whatsapp">
            <li>
              <a href="https://wa.me/+918209490260">
                <i className="fa fa-whatsapp"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Scrollbar;
