import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/hero/hero";
import Features from "../../components/Features/Features";
import WorkProcess from "../../components/WorkProcess/WorkProcess";
import About from "../../components/about/about";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import Testimonial from "../../components/Testimonial/Testimonial";
import FunFact from "../../components/FunFact/FunFact";
import TeamSection from "../../components/TeamSection/TeamSection";
import PartnerSection from "../../components/PartnerSection/PartnerSection";
import ContactSection from "../../components/ContactSection/ContactSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/logo.png";
import EventPage from "../../components/eventPage/EventPage";
import { Helmet } from "react-helmet";
const HomePage = () => {
  return (
    <Fragment>
      <Navbar hclass={"header-style-3"} Logo={Logo} />
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Hero />
      <Features />
      <WorkProcess />
      <About />
      <ServiceSection />
      <Testimonial />
      <FunFact />
      <TeamSection />
      <PartnerSection />
      <ContactSection />
      <EventPage />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
