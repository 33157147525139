import React, { Fragment } from 'react'
import FinanceManagement from './FinanceManagement'

const FinanceManagementPage = () => {
  return (
    <Fragment>
        <FinanceManagement/>
    </Fragment>
  )
}
export default FinanceManagementPage
