/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "../_css/style.css";
import Logo from "../../../images/logo.png";
import DainikBhaskar from "../_img/featuredin/DainikBhaskar.png";
import TimesofIndia from "../_img/featuredin/TimesofIndia.png";
import CNBC from "../_img/featuredin/CNBC.png";
import DNA from "../_img/featuredin/DNA.png";
import EconimocTimes from "../_img/featuredin/EconimocTimes.png";
import HindustanTimes from "../_img/featuredin/HindustanTimes.png";
import IndiaToday from "../_img/featuredin/IndiaToday.png";
import Outlook from "../_img/featuredin/Outlook.png";
import Patrika01 from "../_img/featuredin/Patrika01.png";
import A1TV from "../_img/featuredin/A1TV.png";
import AsiaOne from "../_img/featuredin/AsiaOne.png";
import DainikNavjyoiti from "../_img/featuredin/DainikNavjyoiti.png";
import JanTV from "../_img/featuredin/JanTV.png";
import SamacharJagat from "../_img/featuredin/SamacharJagat.png";
import Patrika from "../_img/featuredin/Patrika.png";
import ZeeRajasthan from "../_img/featuredin/ZeeRajasthan.png";
import WillfredCollegsGroup from "../_img/featuredin/St.WillfredCollegsGroup.png";
import cta from "../_img/cta-bg.jpg";
import about from "../_img/about.png";
import testimonials1 from "../_img/testimonials/testimonials-1.jpeg";
import testimonials2 from "../_img/testimonials/testimonials-2.jpeg";
import testimonials3 from "../_img/testimonials/testimonials-3.jpg";
import testimonials4 from "../_img/testimonials/testimonials-4.png";
import testimonials5 from "../_img/testimonials/testimonials-5.jpg";
import Slider from "react-slick";
import {
  Check2Circle,
  StarFill,
  Linkedin,
  Telegram,
  TwitterX,
  Instagram,
  Facebook,
  Youtube,
  Hourglass,
  Key,
  People,
  Book,
  CheckAll,
  QuestionCircle,
} from "react-bootstrap-icons";
import PartnerSectionS2 from "../../../components/PartnerSection2/PartnerSectionS2";

const settings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024, // Screen width of 1024px or smaller
      settings: {
        slidesToShow: 5, // Show 5 slides
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // Screen width of 768px or smaller
      settings: {
        slidesToShow: 4, // Show 4 slides
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480, // Screen width of 480px or smaller
      settings: {
        slidesToShow: 3, // Show 3 slides
        slidesToScroll: 1,
      },
    },
  ],
};
const testimonialsettings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};
const AdvanceCourses = () => {
  return (
    <div className="index-page AC">
      <header id="header" class="header d-flex align-items-center fixed-top">
        <div class="container-fluid container-xl position-relative d-flex align-items-center header-menus">
          <a
            href="https://rahulmalodia.com"
            class="logo d-flex align-items-center me-md-auto"
          >
            <img src={Logo} alt="Logo" />
          </a>
          <div
            class="hero p-0 d-block"
            style={{
              minHeight: "fit-content",
              minWidth: "fit-content",
              width: "fit-content",
              background: "unset",
            }}
          >
            <a
              href="#"
              class="btn btn-get-started access-form"
              id="access-form"
            >
              Access Now @Just ₹20,000
            </a>
          </div>
          <nav id="navmenu" class="navmenu">
            <i class="mobile-nav-toggle d-none bi bi-list"></i>
          </nav>
        </div>
      </header>
      <main class="main">
        <section id="hero" class="hero section dark-background">
          <div class="container">
            <div class="row  gy-4 align-items-center justify-content-center">
              <div
                class="col-lg-10 order-2 order-lg-1 d-flex flex-column justify-content-center text-center"
                data-aos="zoom-out"
              >
                <h5>
                  Attention Entrepreneurs, Businessmen, Self-Employed,
                  Manufacturers & Traders
                </h5>
                <h1 class="mb-3">
                  Build your Dream Business & Creating Wealth without Stress &
                  Anxiety
                </h1>
                <p>
                  just like the top 0.1% CEOs & Upgrade Yourself From A Vyapari
                  To CEO
                </p>
                <div class="d-flex justify-content-center">
                  <a
                    href="javascript:void(0)"
                    id="access-form"
                    class="btn-get-started access-form"
                  >
                    Access Now @Just ₹20,000
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="clients" class="clients section light-background">
          <div class="container" data-aos="zoom-in">
            <div class="swiper init-swiper">
              <Slider {...settings}>
                <div className="swiper-slide">
                  <img
                    src={DainikBhaskar}
                    className="img-fluid"
                    alt="Dainik Bhaskar"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={TimesofIndia}
                    className="img-fluid"
                    alt="Times of India"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={CNBC} className="img-fluid" alt="CNBC" />
                </div>
                <div className="swiper-slide">
                  <img src={DNA} className="img-fluid" alt="DNA" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={EconimocTimes}
                    className="img-fluid"
                    alt="Economic Times"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={HindustanTimes}
                    className="img-fluid"
                    alt="Hindustan Times"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={IndiaToday}
                    className="img-fluid"
                    alt="India Today"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={Outlook} className="img-fluid" alt="Outlook" />
                </div>
                <div className="swiper-slide">
                  <img src={Patrika01} className="img-fluid" alt="Patrika 01" />
                </div>
                <div className="swiper-slide">
                  <img src={A1TV} className="img-fluid" alt="A1 TV" />
                </div>
                <div className="swiper-slide">
                  <img src={AsiaOne} className="img-fluid" alt="Asia One" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={DainikNavjyoiti}
                    className="img-fluid"
                    alt="Dainik Navjyoiti"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={JanTV} className="img-fluid" alt="Jan TV" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={SamacharJagat}
                    className="img-fluid"
                    alt="Samachar Jagat"
                  />
                </div>
                <div className="swiper-slide">
                  <img src={Patrika} className="img-fluid" alt="Patrika" />
                </div>
                <div className="swiper-slide">
                  <img
                    src={ZeeRajasthan}
                    className="img-fluid"
                    alt="Zee Rajasthan"
                  />
                </div>
                <div className="swiper-slide">
                  <img
                    src={WillfredCollegsGroup}
                    className="img-fluid"
                    alt="Wilfred College Group"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        <section id="about" class="about section">
          <div class="container section-title" data-aos="fade-up">
            <h2>Here’s what you’re going to learn in this course</h2>
          </div>
          <div class="container">
            <div class="row gy-4">
              <div
                class="col-lg-6 content"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3 class="mb-4">1. Employee Hiring Mastery</h3>
                <ul>
                  <li>
                     <Check2Circle />
                    <span>Why Hiring a Good Employee is Super Important?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>
                      What are the 3 Levels of Employee Must in Every Business?
                    </span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>
                      4 Type of Personality & In which department & position put
                      them?
                    </span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Complete Hiring Process</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Take Interview (Process, Tips)</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>From Where to hire Good Employee?</span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="mb-4">2. Employee Induction Mastery</h3>
                <ul>
                  <li>
                     <Check2Circle />
                    <span>Importance of Employee Induction</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>What Documents formality Needs to do on Day 1?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>
                      What Basic Knowledge should be given to Employee?
                    </span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>
                      How to Define Work & Measure Performance (KRA/KPI)
                    </span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Get the work done in your Absence (SOP)</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Attraction & Retention Tools of Corporates?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>
                      What if Employee Leaves & Start own or Join Competition?
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="mb-4">3. Employee Management Mastery</h3>
                <ul>
                  <li>
                     <Check2Circle />
                    <span>4 Myths About Employee Management</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Deal with High Skill, Low will Employee</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Mindset/Tips on Firing Employee</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>What is Job of Leader?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Build a Great Team?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Delegate Work?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Make Employee Responsible?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>3 Type of Meetings & How to Take Meetings?</span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="mb-4">4. Branding Mastery</h3>
                <ul>
                  <li>
                     <Check2Circle />
                    <span>Difference between Sales, Marketing</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>What is Brand? & What is Not Brand?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>4 Steps of Building Brand?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Why Branding is Important?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>2 Type of Customer you Should Avoid?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>2 Type of Customer you should attract?</span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="mb-4">5. Marketing Mastery</h3>
                <ul>
                  <li>
                     <Check2Circle />
                    <span>What is Marketing? & Commonly Used Routes</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Create Marketing Plan?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Implement Marketing Plan?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How To Create marketing Budget?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>8 Popular Marketing Channels</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Marketing Fundamentals/Tips</span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="mb-4">6. Sales Mastery</h3>
                <ul>
                  <li>
                     <Check2Circle />
                    <span>Why Sales Process is Important?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Salesman Mindset of Sales.</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>
                      How to Build Connection with Customers & Win Trust
                    </span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Pitch Products/Services?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Create Urgency?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>How to Handle Objections?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Follow up Techniques’</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Negotiation Technique.</span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="mb-4">7. Finance Freedom Mastery</h3>
                <ul>
                  <li>
                     <Check2Circle />
                    <span>What is Wealth Cycle ? (Business Machine)</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>
                      What is Balance Sheet? &amp; What to check in It
                    </span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>
                      What is Profit &amp; Loss Account? What to Check in it?
                    </span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>What is Cash Flow?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Role of Turnover Ratios?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Basics of Working Capital?</span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="mb-4">8. Finance Management Mastery</h3>
                <ul>
                  <li>
                     <Check2Circle />
                    <span>What we need Working Capital??</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>3 Tips for Stock Management </span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>3 Tips for Creditors Management (Purchase)</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>3 Tips for Debtors Management (Recovery)</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>3 Steps to create Wealth?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>3 Mistake that Destroys Wealth</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>5 Type of Investors.</span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 class="mb-4">9. Finance Fundamentals</h3>
                <ul>
                  <li>
                     <Check2Circle />
                    <span>Importance of Finance In Business</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>4 Partners of Sales</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>2 Financial Golden Rules</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>What is Credibility Trap?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>Should you Take Loans or Not?</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span> How to take Loans?</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services section">
          <div className="container section-title">
            <h2>Who Join Us</h2>
          </div>
          <div className="container">
            <div className="row gy-4">
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Entrepreneur
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Business Owner
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Self-Employed
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Professional
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Manufacturer
                    </a>
                  </h4>
                </div>
              </div>
              <div className="col-6 col-xl-2 col-md-4 d-flex">
                <div className="service-item position-relative">
                  <div className="icon">
                    <CheckAll />
                  </div>
                  <h4>
                    <a href="" className="stretched-link">
                      Trader
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="call-to-action"
          class="call-to-action  section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Offer Expires In</h3>
                <ul class="offer-time">
                  <li>
                    <span id="hours"></span>Hours
                  </li>
                  <li>
                    <span id="minutes"></span>Minutes
                  </li>
                  <li>
                    <span id="seconds"></span>Seconds
                  </li>
                </ul>
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href="javascript:void(0)"
                >
                  Access Now @Just ₹20,000
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="skills" class="skills section">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-5 d-flex align-items-center">
                <img src={about} class="img-fluid" alt="" />
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0 content">
                <h3>Who Is Rahul Malodia?</h3>
                <p class="fst-italic">
                  CEO - Malodia Business Coaching Pvt Ltd
                </p>
                <p>
                  As a business catalyst, I am on a mission to ignite a
                  revolution in India that helps businesses grow, expand and
                  stand out in global markets. I obtained my CA degree in 2012
                  after completing my Commerce graduation from the esteemed
                  University of Rajasthan.
                </p>
                <p>
                  With a decade of experience as a Business Consultant and
                  working with reputable firms such as Mahajan & Aibara, I have
                  gained exposure to the professional environment and corporate
                  culture. My work is characterized by exceptional standards,
                  unwavering accountability, and a results-driven approach.
                </p>
                <ul>
                  <li>
                     <Check2Circle />
                    <span>15 Years+ Experience As A Chartered Accountant.</span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>
                      Worked As Management Consultant For Top Corporates In
                      Mumbai.
                    </span>
                  </li>
                  <li>
                     <Check2Circle />
                    <span>
                      Currently Consulting 500+ Businesses With Turnover
                      Exceeding 50Cr+
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="py-3 light-background"> <PartnerSectionS2 /></section> */}
        <section
          id="call-to-action"
          class="call-to-action section dark-background"
        >
          <img src={cta} alt="" />
          <div class="container">
            <div
              class="row align-items-center"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="col-xl-6 text-center text-xl-start">
                <h3>Book Your Spot Now</h3>
                <p>
                  If you are any of them then you cannot afford to lose this
                  opportunity because you need to know the secrets of business
                  success to get out of the day to day hustle.
                </p>
              </div>
              <div class="col-xl-6 cta-btn-container text-center">
                <a
                  class="cta-btn align-middle mx-0 access-form"
                  id="access-form"
                  href="javascript:void(0)"
                >
                  Access Now @Just ₹20,000
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="testimonials" className="testimonials section">
          <div className="container section-title">
            <h2>Testimonials</h2>
          </div>
          <div className="container">
            <div className="init-swiper">
              <div className="">
                <Slider {...testimonialsettings}>
                  <div className="testimonial-item">
                    <img
                      src={testimonials1}
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Er. Harpreet singh</h3>
                    <h4>Director - Paul Hyundai Amritsar</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        BUSINESS FREEDOM COACHING (BFC) is a wonderful practical
                        course - both personally and professionally. I will take
                        away lots of things that I can quickly and easily apply.
                        Thank you Mr. Rahul Malodia for a great course. Great
                        presentation style with lots of opportunities to ask
                        questions and talk about real life examples which all
                        made for a really enjoyable and informative course.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img
                      src={testimonials2}
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Vivek Tripathi</h3>
                    <h4>Director: Satpura Infracon Pvt Ltd</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        What Lord Krishna was to Arjun so has been Rahul malodia
                        for me. He has made my vision crystal clear which I
                        would have never been able to do without his constant
                        help. He has the potential to understand any business
                        structure in a comprehensive way and his analysis plan
                        for my business has already started giving me fruitful
                        results. Under his able guidance I'm pretty sure to take
                        my business to new heights because now I have a complete
                        blueprint of my business growth plan with all minute
                        details.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img
                      src={testimonials3}
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Ashutosh Agrawal</h3>
                    <h4>CEO - Tesca Technologies</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        My sincere gratitude to Mr. Rahul Malodiya whom I met
                        first at BNI Conference in Jaipur & after that it has
                        been a wonderful ride for myself & Tesca Technologies
                        Pvt. Ltd. Right from setting up my Affirmations &
                        Gratitude, Mission, Vision, SOP's, JD, KRA, KPI,
                        Checklists my whole processes are system driven and with
                        this I have become more organized, humble and empathetic
                        with my employees & customers. From the bottom of my
                        heart I am really obliged to Rahul ji for his
                        extraordinary efforts for my business.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img
                      src={testimonials4}
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sameer Zapke</h3>
                    <h4>I.F. Engineering, Pune</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        The affirmations taught in Rahul Sir’s class gave me
                        hope and my vision became more clear concerning my
                        business. He not only changed my present but also my
                        future. My business is running extremely well and I am
                        not worried about the future. The detailing class on
                        vision boards and affirmations has been very helpful in
                        growing my business. Thank you Rahul sir.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                  <div className="testimonial-item">
                    <img
                      src={testimonials5}
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Mukesh Aggarwal</h3>
                    <h4>Owner: New Anand Sagar</h4>
                    <div className="stars">
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                      <StarFill size={20} />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left"></i>
                      <span>
                        I have attended Rahul Malodia (CA, Business Coach)
                        masterclass, and then joined his Advanced Class and
                        lastly I have joined and continued his Bootcamp (Jab tak
                        todenge, success tak pahuchenge nahi tab tak chodenge
                        nahi). He has provided and still provides valuable
                        advice, contents, and support to help our business and
                        our employees make a major transition. I highly
                        recommend him.
                      </span>
                      <i className="bi bi-quote quote-icon-right"></i>
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer" class="footer">
        <div class="footer-newsletter">
          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-lg-6">
                <h4 class="mb-0">Don't Think Too Much... Take Action Now...</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="container footer-top ">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-6 footer-about">
              <a
                href="#"
                class="d-flex align-items-center"
              >
                <img src={Logo} alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-3 footer-links">
              <h4>Contact Us</h4>
              <div class="footer-contact">
                <p>
                  Jharkhand Mode, Sankalp Tower, 202 & 203, Queens Rd, Vaishali
                  Nagar, Jaipur, Rajasthan 302012
                </p>
                <p class="mt-3">
                  <strong>Phone:</strong> <span>+91 76500 67000</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>support@rahulmalodia.com</span>
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <h4>Follow Us</h4>
              <div class="social-links d-flex">
                <a
                  target="_blank"
                  href="https://www.youtube.com/@rahulmalodiaofficial"
                >
                  <Youtube />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/RahulMalodiaOfficial/"
                >
                  <Facebook />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/rahulmalodiaofficial/?hl=en"
                >
                  <Instagram />
                </a>
                <a target="_blank" href="https://x.com/carahulmalodia">
                  <TwitterX />
                </a>
                <a target="_blank" href="https://t.me/CArahulmalodiaofficial">
                  <Telegram />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/rahul-malodia-5ab2138a/"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="container copyright text-center mt-4">
          <p>
            <span>Copyright © 2024</span>{" "}
            <a href="rahulmalodia.com">
              <strong class="px-1 sitename">rahulmalodia.com</strong>
            </a>{" "}
            <span>All Rights Reserved</span>
          </p>
        </div>
      </footer>
    </div>
  );
};
export default AdvanceCourses;
