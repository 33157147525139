/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Added useLocation for route-based active tab
import MobileMenu from '../MobileMenu/MobileMenu';
import { connect } from "react-redux";
import classnames from 'classnames';
import Logo from '../../images/logo.png';

const Header = (props) => {
    // const [menuActive, setMenuState] = useState(false);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('1'); // Default active tab

    // Update the activeTab state based on the location (route change)
    useEffect(() => {
        const path = location.pathname; // Get the current path from the location
        if (path.includes('home')) {
            setActiveTab('1');
        } else if (path.includes('about')) {
            setActiveTab('2');
        } else if (path.includes('courses')) {
            setActiveTab('3');
        } else if (path.includes('event')) {
            setActiveTab('4');
        } else if (path.includes('contact')) {
            setActiveTab('5');
        } else if (path.includes('blog')) {
            setActiveTab('6');
        }
    }, [location]);

    // const { carts } = props;

    return (
        <header id="header" className={`site-header ${props.hclass}`}>
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <div className="navbar-header">
                        <MobileMenu />
                        <Link className="navbar-brand" to="/home"><img src={Logo} alt="Logo" /></Link>
                    </div>
                    <div id="navbar" className="collapse navbar-collapse navigation-holder">
                        <button className="close-navbar"><i className="ti-close"></i></button>
                        <ul className="nav navbar-nav mb-2 mb-lg-0">
                            <li>
                                <Link 
                                    to="/home" 
                                    className={classnames({ active: activeTab === '1' })}
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="/about" 
                                    className={classnames({ active: activeTab === '2' })}
                                >
                                    About Me
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="/courses" 
                                    className={classnames({ active: activeTab === '3' })}
                                >
                                    Courses
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="/event" 
                                    className={classnames({ active: activeTab === '4' })}
                                >
                                    Event
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="/blog" 
                                    className={classnames({ active: activeTab === '6' })}
                                >
                                    Blogs
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="/contact" 
                                    className={classnames({ active: activeTab === '5' })}
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};

export default connect(mapStateToProps)(Header);
