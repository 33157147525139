import React from 'react'
const Skill3 = (props) => {
    return (
        <section className="work-process-section section-padding free-help">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div className="section-title">
                            <span>Looking For Free Help? - Here you go!</span>
                            <h3 className="mb-3">READ. LISTEN. WATCH</h3>
                            <p>And get lots of insights, case studies, and business strategies< br />absolutely for free.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col col-lg-10 offset-lg-1">
                        <div className="work-process-grids clearfix">
                            <div className="grid">
                                <div className="icon">
                                    <i class="fa fa-book"></i>
                                </div>
                                <h3>Read</h3>
                                <p>Love reading? I got you! 🙂 Start reading my latest blog articles to learn more about employee, sales, accounts mastery, etc.</p>
                                <a href="/blog" className='btn theme-btn'>Read Now</a>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fa fa-headphones"></i>
                                </div>
                                <h3>Listen</h3>
                                <p>No time to watch videos? Start listening to the ‘Mission Business Freedom’ podcast while working to get almost everything in audio.</p>
                                <a href="https://open.spotify.com/show/3uxZugt5th5lO7tM3XYLL1" className='btn theme-btn'>Listen Now</a>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fa fa-desktop"></i>
                                </div>
                                <h3>Watch</h3>
                                <p>Binge-watch my YouTube videos<br/> to get case studies, business lessons, techniques, and a lot more.</p>
                                <a href="https://www.youtube.com/c/RahulMalodiaOfficial" className='btn theme-btn'>Watch Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Skill3;