import React from "react";
import { Check2Circle, XCircle } from "react-bootstrap-icons";

const Gold = () => {
  return (
    <div className="col-md-4">
      <div className="plan-cont gold">
        <span class="label"></span>
        <h2 class="fw-bold text-center">₹1999</h2>
        <h5 className="text-center">Gold</h5>

        <ul class="mt-4">
          <li>
            <Check2Circle />
            <span>One Year Access</span>
          </li>
          <li>
            <Check2Circle />
            <span>Certificate of completion</span>
          </li>
          <li>
            <Check2Circle />
            <span>V2C Masterclass Access</span>
          </li>
          <li>
            <Check2Circle />
            <span>Commmunity Access</span>
          </li>
          <li>
            <Check2Circle /> <span>PDF Notes</span>
          </li>
          <li>
            <Check2Circle />
            <span>1 Year Technical Support</span>
          </li>
          <li>
            <Check2Circle />
            <span>1 Year Content Update</span>
          </li>
          <li>
            <XCircle />
            <span>Lifetime Access</span>
          </li>
          <li>
            <XCircle />
            <span>Coupon Code ₹499 (Use above ₹2000)</span>
          </li>
        </ul>
        <div className="text-center button-ass">
          <a className="plan-enroll-btn" id="access-form">
            ENROLL NOW
          </a>
        </div>
      </div>
    </div>
  );
};

export default Gold;
