import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'
import cimg1 from '../../images/icon/Call.png'
import cimg2 from '../../images/icon/Email.png'
import cimg3 from '../../images/icon/Location.png'

const Contactpage = () => {

    return (
        <section className="contact-pg-section section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-8">
                        <div className="section-title-s3">
                            <span>Contact us</span>
                            <h2>Drop us a line</h2>
                            <p>Got questions or need assistance? Contact us today! We're here to help you grow.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="contact-form">
                            <ContactForm />
                        </div>
                        <div className="contact-info clearfix">
                            <div>
                                <div className="icon">
                                    {/* <i className="ti-email"></i> */}
                                    <img src={cimg1} />
                                </div>
                                <h5>Email</h5>
                                <p> support@rahulmalodia.com</p>
                            </div>
                            <div>
                                <div className="icon">
                                    {/* <i className="ti-mobile"></i> */}
                                    <img src={cimg2} />
                                </div>

                                <h5>Phone</h5>
                                <p>+91 76500 67000</p>
                            </div>
                            <div>
                                <div className="icon">
                                    <img src={cimg3} />
                                    {/* <i className="ti-location-arrow"></i> */}
                                </div>
                                <h5>Address</h5>
                                <p>Jharkhand Mode, Sankalp Tower, 202 & 203, Queens Rd, Vaishali Nagar, Jaipur, Rajasthan 302012</p>
                            </div>
                        </div>

                        <div className="contact-map">
                          
                            <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.44616862327!2d75.7507810744415!3d26.921066559733347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db39e46053f57%3A0x4bb11bfa6cea7efa!2sRahul%20Malodia!5e0!3m2!1sen!2sin!4v1733230113822!5m2!1sen!2sin"  allowfullscreen="" ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Contactpage;
