import React, { useState } from 'react'
import Team from '../../api/team'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    loop: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1, 
                autoplay: true, 
            }
        },
        {
            breakpoint: 768, 
            settings: {
                slidesToShow: 3, 
                slidesToScroll: 1, 
                autoplay: true, 
            }
        },
        {
            breakpoint: 480, 
            settings: {
                slidesToShow: 2, 
                slidesToScroll: 1, 
                autoplay: true, 
            }
        }
    ]
};
const ClickHandler = () => {
    window.scrollTo(10, 0);
}
const TeamSection = (props) => {
    const [isOpen, setOpen] = useState(false)
    return (
        <section className="cta-with-team-section section-padding">
            <div className="bg"></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-xl-7 col-lg-9">
                        <div className="cta-content">
                            
                            <h3>India’s Best Business Coach who has trained over 3,00,000+ Businessmen</h3>
                        </div>
                    </div>
                </div>
                <div className="team-section">
                    <div className="row">
                        <div className="col col-lg-6 col-12">
                            <div className="section-title-s4">
                                <span>Our Clients</span>
                                <h2>Brands that we have<br/> worked with</h2>
                            </div>
                        </div>
                        <div className="col col-lg-6 col-12">
                            <div className="title-text">
                                <p>Our clients include leading brands and ambitious startups across industries. Discover our portfolio featuring successful collaborations with industry giants and innovative startups.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="team-grids">
                            <Slider {...settings}>
                                {Team.map((team, aitem) => (
                                    <div className="grid" key={aitem}>
                                        <div className="img-holder">
                                            <img src={team.tImg} alt="" />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TeamSection;